// eslint-disable-next-line import/no-extraneous-dependencies
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ChangeEvent, useState } from 'react';
import { useGetAvatarUrl } from '@services/queries/AvatarQueries';
import Box from '@mui/material/Box';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AddAvatarButton = ({ entityId }: { entityId: string }) => {
  const [file, setFile] = useState<File | null>(null);
  const { mutate } = useGetAvatarUrl(file);

  return (
    <Box width="100px">
      <Button sx={{ padding: '10px', width: '100px' }} component="label" variant="contained">
        <CloudUploadIcon />
        <VisuallyHiddenInput
          type="file"
          onChange={async (data: ChangeEvent<HTMLInputElement>) => {
            if (data?.target && data?.target?.files) {
              mutate({
                fileName: data?.target?.files[0].name,
                contentLength: BigInt(data?.target?.files[0].size),
                entityId,
              });
              setFile(data?.target?.files[0]);
            }
          }}
        />
      </Button>
    </Box>
  );
};
export default AddAvatarButton;
