import Dialog from '@mui/material/Dialog';
import React from 'react';
import Box from '@mui/material/Box';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMfaState } from '../../../zustand/mfa';

const RecoveryCodeInfoDialog = () => {
  const { recoveryCode, addRecoveryCode, setShowMfaDialog } = useMfaState();
  const dispatch = useAppDispatch();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(recoveryCode as string)
      .then(() => {
        return dispatch(
          showSnackbar({
            id: 'recoveryCode',
            message: 'Recovery code copied to clipboard',
            severity: 'success',
          })
        );
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            id: 'recoveryCode',
            message: 'Failed to copy recovery code',
            severity: 'error',
          })
        );
      });
  };

  return (
    <Dialog
      onClose={() => {
        setShowMfaDialog(false);
        addRecoveryCode(null);
      }}
      open={!!recoveryCode}
    >
      <Box className="flex flex-col items-center justify-center gap-2 p-4">
        <Box className="flex font-bold items-center text-[#ff1744] gap-1">
          <AnnouncementIcon fontSize="large" className="text-[#ff1744]" />
          <Typography fontSize="26px">WARNING</Typography>
        </Box>
        <Box className="flex items-center flex-col max-w-[500px] text-center gap-2">
          Save this recovery code for future use. If you lose access to your authenticator app, you
          will need this
          <Box
            onClick={handleCopy}
            className="cursor-pointer flex text-[#4aedc4] items-center gap-1"
          >
            <ContentCopyIcon />
            <span className=" font-bold">{recoveryCode}</span>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RecoveryCodeInfoDialog;
