import { TOrg } from '@services/api/profiler/biz';
import { Typography } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ROUTES_PREFIXES } from '@common/routes';
import { replaceSpacesWithDashes } from '@utils/parseStrings';
import CustomAvatar from '@components/Avatar/Avatar';

type TPinnedOrgCard = {
  org: TOrg;
};

const MembershipOrgCard = (props: TPinnedOrgCard) => {
  const { org } = props;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Link className="NavLink" to={`${ROUTES_PREFIXES.ORGS}/${replaceSpacesWithDashes(org.name)}`}>
        <Box display="flex" alignItems="center" width="100%" gap={1}>
          <CustomAvatar
            alt={org?.name || 'Org'}
            avatarId={org?.avatar}
            profileId={org?.orgId?.value}
          />
          <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} fontSize="13px">
            {org.name}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default MembershipOrgCard;
