import React, { useState } from 'react';
import { Box, TextareaAutosize, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ChatInput = ({
  onSendMessage,
  initialMessage,
}: {
  onSendMessage: (message: string) => void;
  initialMessage?: string;
}) => {
  const [message, setMessage] = useState(initialMessage || '');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleSendMessage = () => {
    if (message.trim() !== '' || selectedFile) {
      onSendMessage(message);
      setMessage('');
      setSelectedFile(null);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Забороняємо перенесення рядка
      handleSendMessage();
    }
  };

  return (
    <Box className="flex w-full flex-col gap-2 p-4 text-[white] rounded-md">
      <Box onKeyDown={handleKeyDown} className="flex items-center gap-2">
        <TextareaAutosize
          autoComplete="off"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter message"
          className="flex-grow p-2 rounded-md border border-[#b0bec5] focus:outline-none bg-inherit resize-none max-h-[350px]"
        />
        <Box
          onClick={handleSendMessage}
          className={`${
            message?.length ? 'text-gray-300 cursor-pointer hover:text-gray-400' : 'text-black'
          }`}
        >
          <Tooltip placement="top" title="Send message">
            <SendIcon />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatInput;
