import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Fade from '@mui/material/Fade';
import { useOrgs } from '@store/orgs';
import ExploreOrgCard from '@pages/Orgs/ExploreOrgs/ExploreOrgCard';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { findOldestDateInOrgs } from '@utils/findOldestDate';
import { Timestamp } from '@bufbuild/protobuf';
import OrgsSideBarContent from '@pages/Orgs/OrgsSideBarContent';

const ExploreSquadsList = () => {
  const { listExploreOrgs, queryParams, searchOrgs, searchPaginationOrgs } = useOrgs();

  useEffect(() => {
    const filters = queryParams.orgsFilters.length
      ? {
          industry_domain: queryParams.orgsFilters.map((item: string) =>
            Number(item.split(':')[1])
          ),
        }
      : {};
    searchOrgs(filters);
  }, [searchOrgs, queryParams]);

  return (
    <Fade in timeout={500}>
      <Box display="flex" flexDirection="column" gap={2}>
        <OrgsSideBarContent />
        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} alignItems="center">
            <Diversity3Icon />
            <Typography component="h5">Explore organizations</Typography>
          </Box>
        </Stack>

        <Stack gap={2}>
          {listExploreOrgs?.map((org) => (
            <ExploreOrgCard key={org.orgId?.value} org={org} />
          ))}
        </Stack>
        {listExploreOrgs && listExploreOrgs?.length > 10 && (
          <Button
            onClick={() =>
              searchPaginationOrgs(Timestamp.fromDate(findOldestDateInOrgs(listExploreOrgs)))
            }
            variant="outlined"
          >
            Show more
          </Button>
        )}
      </Box>
    </Fade>
  );
};

export default ExploreSquadsList;
