import Joi from 'joi';
import { KanbanBoard } from '@proto/marketplace/dashboard/v1/dashboard_pb';

export type TCreateInterviewForm = {
  dashboardId: string;
  price: number;
  experience: number;
  requirements: string;
};

export enum FieldNames {
  TITLE = 'title',
  ABOUT = 'about',
  DASHBOARD_ID = 'dashboardId',
  DESCRIPTION = 'description',
  CREATE_INTERVIEW = 'createInterview',
  TAGS = 'tags',
  TRAITS = 'traits',
  COUNTRIES = 'countries',
  LANGUAGES = 'languages',
  INDUSTRY_DOMAIN = 'industryDomain',
  ROLES = 'roles',
  PROGRAMMING_LANGUAGES = 'programmingLanguages',
  REQUIREMENTS = 'requirements',
  RATE = 'rate',
  PRICE = 'price',
  EXPERIENCE = 'experience',
  CAPACITY = 'capacity',
  INTERVIEWER = 'interviewer',
  SQUADER = 'squader',
  ENTITY = 'entity',
  RECOMMENDATION = 'recommendation',
  QUALIFICATION = 'qualification',
  COMMUNICATION = 'communication',
  CONTRACT_ID = 'contractId',
}

export const FIELD_LABELS = {
  [FieldNames.TITLE]: 'Kanbanboard title',
  [FieldNames.ABOUT]: 'Kanbanboard about',
  [FieldNames.DESCRIPTION]: 'Description',
  [FieldNames.INTERVIEWER]: 'Interviewer',
  [FieldNames.SQUADER]: 'Squader',
  [FieldNames.TAGS]: 'Tags',
  [FieldNames.TRAITS]: 'Traits',
  [FieldNames.COUNTRIES]: 'Countries',
  [FieldNames.LANGUAGES]: 'Languages',
  [FieldNames.INDUSTRY_DOMAIN]: 'Industry domain',
  [FieldNames.ROLES]: 'Roles',
  [FieldNames.PROGRAMMING_LANGUAGES]: 'Programming languages',
  [FieldNames.RATE]: 'Rate',
  [FieldNames.EXPERIENCE]: 'Experience',
  [FieldNames.CAPACITY]: 'Capacity',
  [FieldNames.ENTITY]: 'Entity',
  [FieldNames.RECOMMENDATION]: 'Recommendation',
  [FieldNames.QUALIFICATION]: 'Qualification',
  [FieldNames.COMMUNICATION]: 'Communication',
};

export const getDefaultValues = (data: KanbanBoard) => ({
  [FieldNames.TITLE]: data?.title || '',
  [FieldNames.DASHBOARD_ID]: data?.dashboardId?.value || '',
});

export const createContractResolverSchema = {
  [FieldNames.DESCRIPTION]: Joi.string().required(),
  [FieldNames.ROLES]: Joi.array().min(1).required().messages({
    'array.min': 'add at least 1 role',
  }),
  [FieldNames.COUNTRIES]: Joi.array().allow(null),
  [FieldNames.LANGUAGES]: Joi.array().required().messages({
    'array.min': 'add at least 1 language',
  }),
  [FieldNames.INDUSTRY_DOMAIN]: Joi.array().required().messages({
    'array.min': 'add at least 1 industry domain',
  }),
  [FieldNames.PROGRAMMING_LANGUAGES]: Joi.array().allow(),
  [FieldNames.DASHBOARD_ID]: Joi.string().allow(null),
  [FieldNames.ENTITY]: Joi.number().allow(null),
  [FieldNames.RATE]: Joi.number().required(),
  [FieldNames.EXPERIENCE]: Joi.number().required(),
  [FieldNames.CAPACITY]: Joi.number().required(),
  [FieldNames.CREATE_INTERVIEW]: Joi.boolean(),
};

export const createInterviewResolverSchema = {
  [FieldNames.ROLES]: Joi.array().min(1).required().messages({
    'array.min': 'add at least 1 role',
  }),
  [FieldNames.COUNTRIES]: Joi.array().allow(null),
  [FieldNames.LANGUAGES]: Joi.array().required().messages({
    'array.min': 'add at least 1 language',
  }),
  [FieldNames.INDUSTRY_DOMAIN]: Joi.array().required().messages({
    'array.min': 'add at least 1 industry domain',
  }),
  [FieldNames.PROGRAMMING_LANGUAGES]: Joi.array().allow(),
  [FieldNames.DASHBOARD_ID]: Joi.string().allow(null),
  [FieldNames.ENTITY]: Joi.number().allow(null),
  [FieldNames.PRICE]: Joi.number().required(),
  [FieldNames.EXPERIENCE]: Joi.number().required(),
  [FieldNames.REQUIREMENTS]: Joi.string().required(),
};

export const createInterviewForContractResolverSchema = {
  [FieldNames.DASHBOARD_ID]: Joi.string().allow(null),
  [FieldNames.CONTRACT_ID]: Joi.string().required(),
  [FieldNames.REQUIREMENTS]: Joi.string().required(),
};
