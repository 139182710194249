import styled from '@mui/material/styles/styled';

import Button from '@mui/material/Button';
import { getGradient } from '@common/gradient';

export const GradientButton = styled(Button)(({ theme }) => ({
  backgroundImage: `linear-gradient(${theme.palette.bgcolor.main}, ${theme.palette.bgcolor.main}),
                    ${getGradient(theme)}`,
  border: 'double 1px transparent',
  padding: '0.8rem 1.75rem',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  transition: '.2s ease-in-out',
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  backgroundPosition: '0% 0%',
  textTransform: 'none',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundPosition: '-20% -30%',
  },
}));
