// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file iam/membership/v1/membership.proto (package iam.membership.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Entities, Entity, Entity_Type } from '../../../grpc/type/v1/entity_pb.js';
import { Profile } from '../../../profiler/user/v1/profile_pb.js';

/**
 * returns all entity types when entity_type is unspecified
 *
 * @generated from message iam.membership.v1.ProfilesMembershipsFilter
 */
export class ProfilesMembershipsFilter extends Message<ProfilesMembershipsFilter> {
  /**
   * @generated from field: grpc.type.v1.UUIDS profile_ids = 1;
   */
  profileIds?: UUIDS;

  /**
   * @generated from field: repeated grpc.type.v1.Entity.Type entity_types = 2;
   */
  entityTypes: Entity_Type[] = [];

  constructor(data?: PartialMessage<ProfilesMembershipsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.ProfilesMembershipsFilter';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_ids', kind: 'message', T: UUIDS },
    {
      no: 2,
      name: 'entity_types',
      kind: 'enum',
      T: proto3.getEnumType(Entity_Type),
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ProfilesMembershipsFilter {
    return new ProfilesMembershipsFilter().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ProfilesMembershipsFilter {
    return new ProfilesMembershipsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ProfilesMembershipsFilter {
    return new ProfilesMembershipsFilter().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProfilesMembershipsFilter | PlainMessage<ProfilesMembershipsFilter> | undefined,
    b: ProfilesMembershipsFilter | PlainMessage<ProfilesMembershipsFilter> | undefined
  ): boolean {
    return proto3.util.equals(ProfilesMembershipsFilter, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.ListProfilesMembershipsRequest
 */
export class ListProfilesMembershipsRequest extends Message<ListProfilesMembershipsRequest> {
  /**
   * @generated from field: iam.membership.v1.ProfilesMembershipsFilter filter = 1;
   */
  filter?: ProfilesMembershipsFilter;

  constructor(data?: PartialMessage<ListProfilesMembershipsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.ListProfilesMembershipsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'filter', kind: 'message', T: ProfilesMembershipsFilter },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfilesMembershipsRequest {
    return new ListProfilesMembershipsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfilesMembershipsRequest {
    return new ListProfilesMembershipsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesMembershipsRequest {
    return new ListProfilesMembershipsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProfilesMembershipsRequest | PlainMessage<ListProfilesMembershipsRequest> | undefined,
    b: ListProfilesMembershipsRequest | PlainMessage<ListProfilesMembershipsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesMembershipsRequest, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.ListProfilesMembershipsResponse
 */
export class ListProfilesMembershipsResponse extends Message<ListProfilesMembershipsResponse> {
  /**
   * @generated from field: repeated iam.membership.v1.ListProfilesMembershipsResponse.Membership memberships = 1;
   */
  memberships: ListProfilesMembershipsResponse_Membership[] = [];

  constructor(data?: PartialMessage<ListProfilesMembershipsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.ListProfilesMembershipsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'memberships',
      kind: 'message',
      T: ListProfilesMembershipsResponse_Membership,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfilesMembershipsResponse {
    return new ListProfilesMembershipsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfilesMembershipsResponse {
    return new ListProfilesMembershipsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesMembershipsResponse {
    return new ListProfilesMembershipsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProfilesMembershipsResponse | PlainMessage<ListProfilesMembershipsResponse> | undefined,
    b: ListProfilesMembershipsResponse | PlainMessage<ListProfilesMembershipsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesMembershipsResponse, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.ListProfilesMembershipsResponse.Membership
 */
export class ListProfilesMembershipsResponse_Membership extends Message<ListProfilesMembershipsResponse_Membership> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entities entities = 2;
   */
  entities?: Entities;

  constructor(data?: PartialMessage<ListProfilesMembershipsResponse_Membership>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.ListProfilesMembershipsResponse.Membership';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'entities', kind: 'message', T: Entities },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfilesMembershipsResponse_Membership {
    return new ListProfilesMembershipsResponse_Membership().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfilesMembershipsResponse_Membership {
    return new ListProfilesMembershipsResponse_Membership().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesMembershipsResponse_Membership {
    return new ListProfilesMembershipsResponse_Membership().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListProfilesMembershipsResponse_Membership
      | PlainMessage<ListProfilesMembershipsResponse_Membership>
      | undefined,
    b:
      | ListProfilesMembershipsResponse_Membership
      | PlainMessage<ListProfilesMembershipsResponse_Membership>
      | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesMembershipsResponse_Membership, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.ListMembersRequest
 */
export class ListMembersRequest extends Message<ListMembersRequest> {
  /**
   * @generated from oneof iam.membership.v1.ListMembersRequest.by
   */
  by:
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS entity_ids = 1;
         */
        value: UUIDS;
        case: 'entityIds';
      }
    | {
        /**
         * @generated from field: iam.membership.v1.ProfilesMembershipsFilter filter = 2;
         */
        value: ProfilesMembershipsFilter;
        case: 'filter';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.ListMembersRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_ids', kind: 'message', T: UUIDS, oneof: 'by' },
    { no: 2, name: 'filter', kind: 'message', T: ProfilesMembershipsFilter, oneof: 'by' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersRequest {
    return new ListMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersRequest | PlainMessage<ListMembersRequest> | undefined,
    b: ListMembersRequest | PlainMessage<ListMembersRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersRequest, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.ListMembersResponse
 */
export class ListMembersResponse extends Message<ListMembersResponse> {
  /**
   * @generated from field: repeated iam.membership.v1.ListMembersResponse.Membership memberships = 1;
   */
  memberships: ListMembersResponse_Membership[] = [];

  constructor(data?: PartialMessage<ListMembersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.ListMembersResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'memberships',
      kind: 'message',
      T: ListMembersResponse_Membership,
      repeated: true,
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse {
    return new ListMembersResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined,
    b: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersResponse, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.ListMembersResponse.Membership
 */
export class ListMembersResponse_Membership extends Message<ListMembersResponse_Membership> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from field: repeated iam.membership.v1.ListMembersResponse.Membership.Member members = 2;
   */
  members: ListMembersResponse_Membership_Member[] = [];

  constructor(data?: PartialMessage<ListMembersResponse_Membership>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.ListMembersResponse.Membership';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    {
      no: 2,
      name: 'members',
      kind: 'message',
      T: ListMembersResponse_Membership_Member,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListMembersResponse_Membership {
    return new ListMembersResponse_Membership().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse_Membership {
    return new ListMembersResponse_Membership().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse_Membership {
    return new ListMembersResponse_Membership().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersResponse_Membership | PlainMessage<ListMembersResponse_Membership> | undefined,
    b: ListMembersResponse_Membership | PlainMessage<ListMembersResponse_Membership> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersResponse_Membership, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.ListMembersResponse.Membership.Member
 */
export class ListMembersResponse_Membership_Member extends Message<ListMembersResponse_Membership_Member> {
  /**
   * @generated from oneof iam.membership.v1.ListMembersResponse.Membership.Member.profile
   */
  profile:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID id = 1;
         */
        value: UUID;
        case: 'id';
      }
    | {
        /**
         * @generated from field: profiler.user.v1.Profile full = 2;
         */
        value: Profile;
        case: 'full';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: google.protobuf.Timestamp joined = 3;
   */
  joined?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp leaved = 4;
   */
  leaved?: Timestamp;

  constructor(data?: PartialMessage<ListMembersResponse_Membership_Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.ListMembersResponse.Membership.Member';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'message', T: UUID, oneof: 'profile' },
    { no: 2, name: 'full', kind: 'message', T: Profile, oneof: 'profile' },
    { no: 3, name: 'joined', kind: 'message', T: Timestamp },
    { no: 4, name: 'leaved', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListMembersResponse_Membership_Member {
    return new ListMembersResponse_Membership_Member().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse_Membership_Member {
    return new ListMembersResponse_Membership_Member().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse_Membership_Member {
    return new ListMembersResponse_Membership_Member().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListMembersResponse_Membership_Member
      | PlainMessage<ListMembersResponse_Membership_Member>
      | undefined,
    b:
      | ListMembersResponse_Membership_Member
      | PlainMessage<ListMembersResponse_Membership_Member>
      | undefined
  ): boolean {
    return proto3.util.equals(ListMembersResponse_Membership_Member, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.AddMemberRequest
 */
export class AddMemberRequest extends Message<AddMemberRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<AddMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.AddMemberRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberRequest {
    return new AddMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberRequest {
    return new AddMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberRequest {
    return new AddMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddMemberRequest | PlainMessage<AddMemberRequest> | undefined,
    b: AddMemberRequest | PlainMessage<AddMemberRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddMemberRequest, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.AddMemberResponse
 */
export class AddMemberResponse extends Message<AddMemberResponse> {
  constructor(data?: PartialMessage<AddMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.AddMemberResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberResponse {
    return new AddMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberResponse {
    return new AddMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberResponse {
    return new AddMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddMemberResponse | PlainMessage<AddMemberResponse> | undefined,
    b: AddMemberResponse | PlainMessage<AddMemberResponse> | undefined
  ): boolean {
    return proto3.util.equals(AddMemberResponse, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.RemoveMemberRequest
 */
export class RemoveMemberRequest extends Message<RemoveMemberRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID entity_id = 1;
   */
  entityId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 2;
   */
  profileId?: UUID;

  constructor(data?: PartialMessage<RemoveMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.RemoveMemberRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'message', T: UUID },
    { no: 2, name: 'profile_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveMemberRequest {
    return new RemoveMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveMemberRequest {
    return new RemoveMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveMemberRequest {
    return new RemoveMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveMemberRequest | PlainMessage<RemoveMemberRequest> | undefined,
    b: RemoveMemberRequest | PlainMessage<RemoveMemberRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveMemberRequest, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.RemoveMemberResponse
 */
export class RemoveMemberResponse extends Message<RemoveMemberResponse> {
  constructor(data?: PartialMessage<RemoveMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.RemoveMemberResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveMemberResponse {
    return new RemoveMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveMemberResponse {
    return new RemoveMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveMemberResponse {
    return new RemoveMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveMemberResponse | PlainMessage<RemoveMemberResponse> | undefined,
    b: RemoveMemberResponse | PlainMessage<RemoveMemberResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveMemberResponse, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.LeaveMembershipRequest
 */
export class LeaveMembershipRequest extends Message<LeaveMembershipRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID entity_id = 1;
   */
  entityId?: UUID;

  constructor(data?: PartialMessage<LeaveMembershipRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.LeaveMembershipRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): LeaveMembershipRequest {
    return new LeaveMembershipRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): LeaveMembershipRequest {
    return new LeaveMembershipRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): LeaveMembershipRequest {
    return new LeaveMembershipRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: LeaveMembershipRequest | PlainMessage<LeaveMembershipRequest> | undefined,
    b: LeaveMembershipRequest | PlainMessage<LeaveMembershipRequest> | undefined
  ): boolean {
    return proto3.util.equals(LeaveMembershipRequest, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.LeaveMembershipResponse
 */
export class LeaveMembershipResponse extends Message<LeaveMembershipResponse> {
  constructor(data?: PartialMessage<LeaveMembershipResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.LeaveMembershipResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): LeaveMembershipResponse {
    return new LeaveMembershipResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): LeaveMembershipResponse {
    return new LeaveMembershipResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): LeaveMembershipResponse {
    return new LeaveMembershipResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: LeaveMembershipResponse | PlainMessage<LeaveMembershipResponse> | undefined,
    b: LeaveMembershipResponse | PlainMessage<LeaveMembershipResponse> | undefined
  ): boolean {
    return proto3.util.equals(LeaveMembershipResponse, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.MembersCountRequest
 */
export class MembersCountRequest extends Message<MembersCountRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID entity_id = 1;
   */
  entityId?: UUID;

  constructor(data?: PartialMessage<MembersCountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.MembersCountRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MembersCountRequest {
    return new MembersCountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MembersCountRequest {
    return new MembersCountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): MembersCountRequest {
    return new MembersCountRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: MembersCountRequest | PlainMessage<MembersCountRequest> | undefined,
    b: MembersCountRequest | PlainMessage<MembersCountRequest> | undefined
  ): boolean {
    return proto3.util.equals(MembersCountRequest, a, b);
  }
}

/**
 * @generated from message iam.membership.v1.MembersCountResponse
 */
export class MembersCountResponse extends Message<MembersCountResponse> {
  /**
   * @generated from field: int32 count = 1;
   */
  count = 0;

  constructor(data?: PartialMessage<MembersCountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.membership.v1.MembersCountResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MembersCountResponse {
    return new MembersCountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MembersCountResponse {
    return new MembersCountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): MembersCountResponse {
    return new MembersCountResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: MembersCountResponse | PlainMessage<MembersCountResponse> | undefined,
    b: MembersCountResponse | PlainMessage<MembersCountResponse> | undefined
  ): boolean {
    return proto3.util.equals(MembersCountResponse, a, b);
  }
}
