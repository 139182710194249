import Dialog from '@mui/material/Dialog';
import QRCode from 'react-qr-code';
import React from 'react';
import { useActivateOtp, useGenerateTotp } from '@services/queries/Mfa';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import OTPInput from 'react-otp-input';
import Typography from '@mui/material/Typography';
import { useMfaState } from '../../../zustand/mfa';

const MfaDialog = () => {
  const { showMfaDialog, setShowMfaDialog, inputError, setMfaInputError } = useMfaState();
  const { data: generateToOtp, refetch } = useGenerateTotp(showMfaDialog);
  const { mutate: activateOtp } = useActivateOtp();
  const [otp, setOtp] = React.useState('');

  const handleSubmit = () => {
    if (otp.length !== 6) {
      setMfaInputError(true);
      return;
    }
    activateOtp({ passCode: otp });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  React.useEffect(() => {
    if (showMfaDialog) {
      refetch();
    }
  }, [showMfaDialog, refetch]);

  return (
    <Dialog onClose={() => setShowMfaDialog(false)} open={showMfaDialog}>
      <Box
        onKeyDown={handleKeyDown}
        className="flex flex-col items-center justify-center gap-2 p-4 "
      >
        <Typography className="font-bold">Open auth app and scan QR code</Typography>
        {generateToOtp?.url && <QRCode value={generateToOtp?.url} />}
        <Typography className="font-bold">Enter OTP code from auth app</Typography>
        <OTPInput
          value={otp}
          onChange={(data) => {
            setMfaInputError(false);
            setOtp(data);
          }}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          inputStyle={`inputStyle ${inputError && 'inputStyleError'}`}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Send OTP
        </Button>
      </Box>
    </Dialog>
  );
};

export default MfaDialog;
