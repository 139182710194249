// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/interviewer.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUID } from '../../../grpc/type/v1/uuid_pb.js';
import { Evaluation } from './evaluation_pb.js';

/**
 * @generated from message marketplace.demand.v1.ApplyAsInterviewerRequest
 */
export class ApplyAsInterviewerRequest extends Message<ApplyAsInterviewerRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 1;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<ApplyAsInterviewerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApplyAsInterviewerRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApplyAsInterviewerRequest {
    return new ApplyAsInterviewerRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApplyAsInterviewerRequest {
    return new ApplyAsInterviewerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApplyAsInterviewerRequest {
    return new ApplyAsInterviewerRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApplyAsInterviewerRequest | PlainMessage<ApplyAsInterviewerRequest> | undefined,
    b: ApplyAsInterviewerRequest | PlainMessage<ApplyAsInterviewerRequest> | undefined
  ): boolean {
    return proto3.util.equals(ApplyAsInterviewerRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ApplyAsInterviewerResponse
 */
export class ApplyAsInterviewerResponse extends Message<ApplyAsInterviewerResponse> {
  constructor(data?: PartialMessage<ApplyAsInterviewerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApplyAsInterviewerResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApplyAsInterviewerResponse {
    return new ApplyAsInterviewerResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApplyAsInterviewerResponse {
    return new ApplyAsInterviewerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApplyAsInterviewerResponse {
    return new ApplyAsInterviewerResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApplyAsInterviewerResponse | PlainMessage<ApplyAsInterviewerResponse> | undefined,
    b: ApplyAsInterviewerResponse | PlainMessage<ApplyAsInterviewerResponse> | undefined
  ): boolean {
    return proto3.util.equals(ApplyAsInterviewerResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ApproveInterviewerRequest
 */
export class ApproveInterviewerRequest extends Message<ApproveInterviewerRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID supply_id = 1;
   */
  supplyId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 2;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<ApproveInterviewerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApproveInterviewerRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'message', T: UUID },
    { no: 2, name: 'interview_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApproveInterviewerRequest {
    return new ApproveInterviewerRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApproveInterviewerRequest {
    return new ApproveInterviewerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApproveInterviewerRequest {
    return new ApproveInterviewerRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApproveInterviewerRequest | PlainMessage<ApproveInterviewerRequest> | undefined,
    b: ApproveInterviewerRequest | PlainMessage<ApproveInterviewerRequest> | undefined
  ): boolean {
    return proto3.util.equals(ApproveInterviewerRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ApproveInterviewerResponse
 */
export class ApproveInterviewerResponse extends Message<ApproveInterviewerResponse> {
  constructor(data?: PartialMessage<ApproveInterviewerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApproveInterviewerResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApproveInterviewerResponse {
    return new ApproveInterviewerResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApproveInterviewerResponse {
    return new ApproveInterviewerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApproveInterviewerResponse {
    return new ApproveInterviewerResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApproveInterviewerResponse | PlainMessage<ApproveInterviewerResponse> | undefined,
    b: ApproveInterviewerResponse | PlainMessage<ApproveInterviewerResponse> | undefined
  ): boolean {
    return proto3.util.equals(ApproveInterviewerResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RejectInterviewerRequest
 */
export class RejectInterviewerRequest extends Message<RejectInterviewerRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID supply_id = 1;
   */
  supplyId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 2;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<RejectInterviewerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RejectInterviewerRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'message', T: UUID },
    { no: 2, name: 'interview_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RejectInterviewerRequest {
    return new RejectInterviewerRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RejectInterviewerRequest {
    return new RejectInterviewerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RejectInterviewerRequest {
    return new RejectInterviewerRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RejectInterviewerRequest | PlainMessage<RejectInterviewerRequest> | undefined,
    b: RejectInterviewerRequest | PlainMessage<RejectInterviewerRequest> | undefined
  ): boolean {
    return proto3.util.equals(RejectInterviewerRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RejectInterviewerResponse
 */
export class RejectInterviewerResponse extends Message<RejectInterviewerResponse> {
  constructor(data?: PartialMessage<RejectInterviewerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RejectInterviewerResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RejectInterviewerResponse {
    return new RejectInterviewerResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RejectInterviewerResponse {
    return new RejectInterviewerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RejectInterviewerResponse {
    return new RejectInterviewerResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RejectInterviewerResponse | PlainMessage<RejectInterviewerResponse> | undefined,
    b: RejectInterviewerResponse | PlainMessage<RejectInterviewerResponse> | undefined
  ): boolean {
    return proto3.util.equals(RejectInterviewerResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RevokeInterviewerApplyRequest
 */
export class RevokeInterviewerApplyRequest extends Message<RevokeInterviewerApplyRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID supply_id = 1;
   */
  supplyId?: UUID;

  constructor(data?: PartialMessage<RevokeInterviewerApplyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RevokeInterviewerApplyRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RevokeInterviewerApplyRequest {
    return new RevokeInterviewerApplyRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RevokeInterviewerApplyRequest {
    return new RevokeInterviewerApplyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RevokeInterviewerApplyRequest {
    return new RevokeInterviewerApplyRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RevokeInterviewerApplyRequest | PlainMessage<RevokeInterviewerApplyRequest> | undefined,
    b: RevokeInterviewerApplyRequest | PlainMessage<RevokeInterviewerApplyRequest> | undefined
  ): boolean {
    return proto3.util.equals(RevokeInterviewerApplyRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RevokeInterviewerApplyResponse
 */
export class RevokeInterviewerApplyResponse extends Message<RevokeInterviewerApplyResponse> {
  constructor(data?: PartialMessage<RevokeInterviewerApplyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RevokeInterviewerApplyResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RevokeInterviewerApplyResponse {
    return new RevokeInterviewerApplyResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RevokeInterviewerApplyResponse {
    return new RevokeInterviewerApplyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RevokeInterviewerApplyResponse {
    return new RevokeInterviewerApplyResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RevokeInterviewerApplyResponse | PlainMessage<RevokeInterviewerApplyResponse> | undefined,
    b: RevokeInterviewerApplyResponse | PlainMessage<RevokeInterviewerApplyResponse> | undefined
  ): boolean {
    return proto3.util.equals(RevokeInterviewerApplyResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AssignInterviewerToEvaluationRequest
 */
export class AssignInterviewerToEvaluationRequest extends Message<AssignInterviewerToEvaluationRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID interview_id = 1;
   */
  interviewId?: UUID;

  constructor(data?: PartialMessage<AssignInterviewerToEvaluationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AssignInterviewerToEvaluationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AssignInterviewerToEvaluationRequest {
    return new AssignInterviewerToEvaluationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AssignInterviewerToEvaluationRequest {
    return new AssignInterviewerToEvaluationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AssignInterviewerToEvaluationRequest {
    return new AssignInterviewerToEvaluationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AssignInterviewerToEvaluationRequest
      | PlainMessage<AssignInterviewerToEvaluationRequest>
      | undefined,
    b:
      | AssignInterviewerToEvaluationRequest
      | PlainMessage<AssignInterviewerToEvaluationRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(AssignInterviewerToEvaluationRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AssignInterviewerToEvaluationResponse
 */
export class AssignInterviewerToEvaluationResponse extends Message<AssignInterviewerToEvaluationResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Evaluation evaluation = 1;
   */
  evaluation?: Evaluation;

  /**
   * @generated from field: string requirements = 2;
   */
  requirements = '';

  constructor(data?: PartialMessage<AssignInterviewerToEvaluationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AssignInterviewerToEvaluationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation', kind: 'message', T: Evaluation },
    { no: 2, name: 'requirements', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AssignInterviewerToEvaluationResponse {
    return new AssignInterviewerToEvaluationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AssignInterviewerToEvaluationResponse {
    return new AssignInterviewerToEvaluationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AssignInterviewerToEvaluationResponse {
    return new AssignInterviewerToEvaluationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AssignInterviewerToEvaluationResponse
      | PlainMessage<AssignInterviewerToEvaluationResponse>
      | undefined,
    b:
      | AssignInterviewerToEvaluationResponse
      | PlainMessage<AssignInterviewerToEvaluationResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(AssignInterviewerToEvaluationResponse, a, b);
  }
}
