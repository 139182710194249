import { SignOutRequest } from '@proto/api/iam/v1/auth_pb';
import { Device } from '@proto/iam/auth/v1/auth_pb';

export const handleSignOut = (fingerPrint: any, signOut: (data: SignOutRequest) => void) => {
  const data = new SignOutRequest({
    devices: [
      new Device({
        os: fingerPrint.os,
        fingerprint: fingerPrint?.visitorId,
      }),
    ],
  });
  return signOut(data);
};
