import { useEffect, useState } from 'react';

export function useQueryParams() {
  const [params, setParams] = useState<Record<string, string>>({});

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObject: Record<string, string> = {};

    queryParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    setParams(paramsObject);
  }, []);

  return params;
}
