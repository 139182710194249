import React, { useMemo } from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { useMarketplace } from '@store/marketplace';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import Card from '@components/Card/Card';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { getDemandId } from '@pages/Marketplace/Feed/config';
import Tags from '@components/Tags/Tags';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import PinUnpinButton from '@components/PinUnpinButton/PinUnpinButton';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { useListAppliedDemands, useListFeedItems } from '@services/queries/MarketplaceQueries';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';

const typesDemand = {
  contract: Entity_Type.CONTRACT,
  interview: Entity_Type.INTERVIEW,
};

interface Props {
  demand: Demand;
}

const DemandCard = React.memo(
  (props: Props) => {
    const { demand } = props;
    const { data } = useListFeedItems();
    const { setDemandId } = useMarketplace();
    const { data: appliedDemands } = useListAppliedDemands();
    const demandId = getDemandId(demand);
    const matches =
      (demandId &&
        Object?.values(
          data?.recomendations?.recommendations[demandId?.value as string]?.recommendations || {}
        ).map((recommendation) => recommendation?.suppliers.map((supplier) => supplier))) ||
      [];

    const isDemandPresent = useMemo(() => {
      const interviewsApplied = appliedDemands?.demands?.applied?.interviews || {};
      const interviewsApproved = appliedDemands?.demands?.approved?.interviews || {};

      return [...Object.values(interviewsApplied), ...Object.values(interviewsApproved)].some(
        (interview) => (interview.entity.value as Interview)?.interviewId?.value === demandId?.value
      );
    }, [appliedDemands, demandId]);

    const toggleDemand = () => {
      setDemandId({ demandId: demandId?.value || '', demandType: demand?.entity?.case || '' });
    };

    const handleCheckboxClick = (e: React.MouseEvent) => {
      e.stopPropagation();
    };

    return (
      <Box
        onClick={toggleDemand}
        sx={{ cursor: 'pointer' }}
        width="80%"
        key={demandId?.value}
        py={1.5}
        className="transition-all hover:scale-105"
      >
        <Card>
          <Box display="flex" px={1} gap={0.5} flexDirection="column" justifyContent="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={2}>
                <Box display="flex" alignItems="center">
                  <Typography
                    fontSize="13px"
                    fontWeight="bold"
                    className={`${matches.length ? 'text-[#7986cb]' : 'text-[white]'}`}
                  >
                    {demand?.entity?.case?.toUpperCase()}
                  </Typography>
                </Box>
                {demand.entity.value?.tags?.tags
                  .filter((tag) => tag.tagType === TagType.ROLE)
                  .map((tag) => {
                    return (
                      <Chip
                        color={`${matches.length ? 'primary' : 'default'}`}
                        key={tag.tagId}
                        variant="outlined"
                        label={tag.value}
                      />
                    );
                  })}
              </Box>
              {isDemandPresent ? 'You have been alredy applied!' : ''}
              {demand?.entity?.case && demandId && (
                <Box onClick={handleCheckboxClick}>
                  <PinUnpinButton type={typesDemand[demand?.entity?.case]} id={demandId?.value} />
                </Box>
              )}
            </Box>
            <Divider />
            <Box display="flex" gap={1} justifyContent="space-between">
              <Box display="flex" flexDirection="column" gap={1}>
                <DemandTraits
                  matches={matches.length && matches[0][0]?.traits}
                  flexDirection="row"
                  fontSize="13px"
                  demand={demand.entity.value}
                />
                {demand.entity.value?.tags?.tags && (
                  <Tags
                    matchedTags={matches.length && matches[0][0]?.matchedTags?.tags}
                    showType
                    tags={demand.entity.value?.tags?.tags}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      (prevProps.demand.entity.value as Interview)?.interviewId?.value ===
      (nextProps.demand.entity.value as Interview)?.interviewId?.value
    );
  }
);

export default DemandCard;
