import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Section, TDemandRecords } from '@store/marketplace/helpers';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';
import { SupplyList } from '@proto/marketplace/demand/v1/supply_pb';

export interface SortedDemandsBySupply {
  all: TDemandRecords;
  applied: TDemandRecords;
  approved: TDemandRecords;
  released: TDemandRecords;
  rejected: TDemandRecords;
}

export const transformFeedItems = (feedItems: Demand[]): TDemandRecords => {
  return feedItems.reduce(
    (acc: TDemandRecords, item: Demand) => {
      if (item.entity.case === 'contract' && item.entity.value) {
        const contract = item.entity.value as Contract;
        acc.contracts[contract?.contractId?.value as string] = item;
      }
      if (item.entity.case === 'interview' && item.entity.value) {
        const interview = item.entity.value as Interview;
        acc.interviews[interview?.interviewId?.value as string] = item;
      }
      return acc;
    },
    { contracts: {}, interviews: {} }
  );
};

export function sortDemandsBySupply(data: TDemandRecords): SortedDemandsBySupply {
  const categories: SortedDemandsBySupply = {
    all: { contracts: {}, interviews: {} },
    applied: { contracts: {}, interviews: {} },
    approved: { contracts: {}, interviews: {} },
    released: { contracts: {}, interviews: {} },
    rejected: { contracts: {}, interviews: {} },
  };

  const addToCategory = (
    category: keyof SortedDemandsBySupply,
    type: keyof TDemandRecords,
    id: string,
    entry: any
  ): void => {
    if (!categories[category][type][id]) {
      categories[category][type][id] = { ...entry, supply: { list: { list: [] } } };
    }
    (categories[category][type][id].supply?.view?.value as SupplyList)?.list.push(
      entry?.supply?.view?.value?.list[0]
    );
  };

  if (!Object.keys(data).length) return {} as SortedDemandsBySupply;

  Object.entries(data)?.forEach(([sectionType, sectionContent]: [string, Section]) => {
    Object.entries(sectionContent).forEach(([id, details]: [string, any]) => {
      details?.supply?.view?.value?.list.forEach((entity: any) => {
        addToCategory('all', sectionType as keyof TDemandRecords, id, {
          ...details,
          supply: { list: { list: [entity] } },
        });
        if (isInvalidTimestamp(`${entity?.releasedAt?.toJsonString()}`)) {
          addToCategory('released', sectionType as keyof TDemandRecords, id, {
            ...details,
            supply: { list: { list: [entity] } },
          });
        } else if (isInvalidTimestamp(`${entity?.rejectedAt?.toJsonString()}`)) {
          addToCategory('rejected', sectionType as keyof TDemandRecords, id, {
            ...details,
            supply: { list: { list: [entity] } },
          });
        } else if (isInvalidTimestamp(`${entity?.approvedAt?.toJsonString()}`)) {
          addToCategory('approved', sectionType as keyof TDemandRecords, id, {
            ...details,
            supply: { list: { list: [entity] } },
          });
        } else if (isInvalidTimestamp(`${entity?.appliedAt?.toJsonString()}`)) {
          addToCategory('applied', sectionType as keyof TDemandRecords, id, {
            ...details,
            supply: { list: { list: [entity] } },
          });
        }
      });
    });
  });

  return categories;
}
