import { Suspense } from 'react';
import { useDialog } from '@components/hooks/useDialog';
import SendEvaluationFeedBackForm from '@pages/Evaluation/SendEvaluationFeedBackForm';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  useGetEvaluation,
  useListEvaluations,
  useSendEvaluationFeedback,
} from '@services/queries/MarketplaceQueries';
import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { useParams } from 'react-router-dom';
import CalendarComponentForCandidate from '@pages/Evaluation/EvaluationSchedulesCalendar/CalendarComponentForCandidate';
import { Timestamp } from '@bufbuild/protobuf';
import { createOccupedEvents } from '@pages/Evaluation/EvaluationSchedulesCalendar/config';

const EvaluationPage = () => {
  const { evaluationId } = useParams();
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { mutate: sendEvaluationFeedback } = useSendEvaluationFeedback();
  const { data: evaluationResponse } = useGetEvaluation({ evaluationId: evaluationId as string });
  const evaluation = evaluationResponse?.evaluation;
  const { data } = useListEvaluations();
  console.log({ data });
  const isInterviewer = evaluation?.participants.case === 'interviewer';
  const isCandidate = evaluation?.participants.case === 'candidate';

  return (
    <Box px={10} py={6}>
      {evaluation ? (
        <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
          {isInterviewer && (
            <Button variant="contained" onClick={handleOpen}>
              {evaluation.feedback ? 'Update feedback' : 'Send feedback'}
            </Button>
          )}

          {evaluation.feedback && (
            <Box>
              {HTMLReactParser(stateToHTML(convertFromRaw(JSON.parse(evaluation.feedback))))}
            </Box>
          )}

          {evaluation.feedback && (
            <Button
              variant="contained"
              onClick={() =>
                sendEvaluationFeedback({ evaluationId: evaluation.evaluationId?.value as string })
              }
            >
              Send Evaluation
            </Button>
          )}

          {isCandidate && (
            <CalendarComponentForCandidate
              scheduledAt={evaluation.scheduledAt as Timestamp}
              evaluationId={evaluationId as string}
              occupedSlots={createOccupedEvents(data?.evaluations || [])}
            />
          )}

          <Suspense fallback={null}>
            <SendEvaluationFeedBackForm
              evaluation={evaluation}
              evaluationId={evaluation?.evaluationId?.value as string}
              isOpen={isOpen}
              handleClose={handleClose}
            />
          </Suspense>
        </Box>
      ) : null}
    </Box>
  );
};
export default EvaluationPage;
