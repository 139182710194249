import React, { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import { ROUTES } from '@common/routes';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '@store/auth';
import { handleSignOut } from '@utils/handleSignOut';
import LogoutIcon from '@mui/icons-material/Logout';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import CustomAvatar from '@components/Avatar/Avatar';
import { fingerprint } from '../../helpers/fingerprint';

const InfoPopover: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [fingerPrint, setFingerPrint] = useState({ visitorId: '', os: '' });
  const { data: userProfile } = useGetMyProfile();

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line promise/catch-or-return
    fingerprint().then((result) => setFingerPrint(result));
  }, []);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
        <CustomAvatar
          alt={userProfile?.profile?.name?.nickName || 'User'}
          avatarId={userProfile?.profile?.avatar}
          profileId={userProfile?.profile?.profileId?.value}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          py={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width={300}
          gap={2}
          position="relative"
        >
          <Box display="flex" gap={1}>
            <Link
              onClick={handleClose}
              to={ROUTES.PROFILE_SETTING}
              className="NavLink HoverUnderlineAnimation FlexLink"
            >
              <Typography variant="subtitle2" mr={1}>
                Account Settings
              </Typography>
              <SettingsIcon />
            </Link>
          </Box>
          <Box
            className="HoverUnderlineAnimation"
            onClick={() => {
              handleSignOut(fingerPrint, signOut);
              navigate('/');
            }}
            display="flex"
            gap={1}
          >
            <Typography>Sign Out</Typography>
            <LogoutIcon />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default InfoPopover;
