import { useDialog } from '@components/hooks/useDialog';
import Box from '@mui/material/Box';
import React from 'react';
import CreateGroupingDialog from '@components/CreateGroupingDialog/CreateGroupingDialog';
import { createOrgResolverSchema } from '@components/CreateGroupingForm/config';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import { useCreateOrg } from '@services/queries/OrgsQueries';

const orgsLabels = {
  title: 'Create Your Organization',
  name: 'Enter the name of your organization',
  about: "Provide a brief overview of your organization's mission and values",
  description:
    'Welcome to our Platform! Here, you can establish your organization and unlock the full potential of our platform. With the ability to create and manage dashboards, as well as sign contracts with demands, your organization will thrive in a collaborative and efficient environment',
  button: 'Create',
  footer:
    'Create your organization now and start leveraging the power of our platform to achieve your business goals!',
};

const CreateOrgButton = () => {
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { mutate: handleCreate } = useCreateOrg();

  return (
    <Box justifyContent="space-between" display="flex" alignItems="center">
      <Box onClick={handleOpen} display="flex" gap={1}>
        <GroupsIcon />
        <Typography>New organization</Typography>
      </Box>

      <CreateGroupingDialog
        isOpen={isOpen}
        handleClose={handleClose}
        labels={orgsLabels}
        handleCreate={handleCreate}
        org
        resolverSchema={createOrgResolverSchema}
      />
    </Box>
  );
};

export default CreateOrgButton;
