import { TagTypeEnum, TTag } from '@services/api/profiler/tags';
import { Tag, TagType } from '@proto/profiler/tags/v1/tags_pb';

export const transformTagsToTagsByType = (tags: TTag[]): Array<[TagTypeEnum, TTag[]]> => {
  const tagMap = new Map<TagTypeEnum, TTag[]>([
    [TagTypeEnum.ROLE, []],
    [TagTypeEnum.COUNTRY, []],
    [TagTypeEnum.LANGUAGE, []],
    [TagTypeEnum.INDUSTRY_DOMAIN, []],
    [TagTypeEnum.PROGRAMMING_LANGUAGE, []],
  ]);

  tags.forEach((tag) => {
    const tagIds = tagMap.get(tag.tagType as any) || [];

    tagMap.set(tag.tagType as any, [...tagIds, tag]);
  });

  return [...tagMap];
};

export const TagTypeLabels: { [key in TagType]: string } = {
  [TagType.UNSPECIFIED]: 'Unspecified',
  [TagType.LANGUAGE]: 'Language',
  [TagType.ROLE]: 'Role',
  [TagType.INDUSTRY_DOMAIN]: 'Industry Domain',
  [TagType.PROGRAMMING_LANGUAGE]: 'Programming Language',
  [TagType.COUNTRY]: 'Country',
};

export function groupTagsByType(tags: Tag[]): { [key: number]: Tag[] } {
  return tags?.reduce((acc: { [key: number]: Tag[] }, tag: Tag) => {
    if (!acc[tag.tagType]) {
      acc[tag.tagType] = [];
    }
    acc[tag.tagType].push(tag);
    return acc;
  }, {});
}
