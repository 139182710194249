import Stack from '@mui/material/Stack';
import { InputController } from '@components/Form/controllers/InputController';
import AutocompleteController from '@components/Form/controllers/AutocompleteController/AutocompleteController';
import Grid from '@mui/material/Grid';
import { FieldNames } from '@pages/Marketplace/ManageDemands/settings';
import { useTags } from '@store/tags';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useState } from 'react';
import { techSpec } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { TTag } from '@services/api/profiler/tags';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';

const CreateInterviewInputs = () => {
  const {
    getLanguages,
    getProgrammingLanguages,
    getCountries,
    getIndustryDomains,
    getRoles,
    countries,
    industryDomains,
    languages,
    programmingLanguages,
    roles,
  } = useTags();
  const [isTechRole, setIsTechRole] = useState(false);

  return (
    <Stack spacing={2} direction="column" mb={2}>
      <Stack spacing={2}>
        <AutocompleteController
          getItems={getRoles}
          multiple
          name={`${FieldNames.ROLES}`}
          label="role"
          options={roles}
          changeHandler={(data: any) =>
            setIsTechRole(data.some((el: TTag) => techSpec.includes(el.value)))
          }
        />
        {isTechRole && (
          <AutocompleteController
            getItems={getProgrammingLanguages}
            multiple
            name={`${FieldNames.PROGRAMMING_LANGUAGES}`}
            label="programming language"
            options={programmingLanguages}
          />
        )}
        <AutocompleteController
          getItems={getCountries}
          multiple
          name={`${FieldNames.COUNTRIES}`}
          label="countries"
          options={countries}
        />
        <AutocompleteController
          getItems={getLanguages}
          multiple
          name={`${FieldNames.LANGUAGES}`}
          label="language"
          options={languages}
        />
        <AutocompleteController
          getItems={getIndustryDomains}
          multiple
          name={`${FieldNames.INDUSTRY_DOMAIN}`}
          label="industryDomains"
          options={industryDomains}
        />

        <Grid item xs={12}>
          <InputController
            fullWidth
            type="number"
            name={`${FieldNames.PRICE}`}
            label="Define the price for the interview process: $"
          />
        </Grid>
        <Grid item xs={12}>
          <InputController
            fullWidth
            type="number"
            name={`${FieldNames.EXPERIENCE}`}
            label="Detail the required experience for the role"
          />
        </Grid>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography fontWeight="bold">
            Outline any specific requirements for the position:
          </Typography>
          <WysiwygController name={FieldNames.REQUIREMENTS} />
        </Box>
      </Stack>
    </Stack>
  );
};

export default CreateInterviewInputs;
