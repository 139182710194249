// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file api/profiler/v1/profile.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message api.profiler.v1.GetTermsOfUseRequest
 */
export class GetTermsOfUseRequest extends Message<GetTermsOfUseRequest> {
  constructor(data?: PartialMessage<GetTermsOfUseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.profiler.v1.GetTermsOfUseRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetTermsOfUseRequest | PlainMessage<GetTermsOfUseRequest> | undefined,
    b: GetTermsOfUseRequest | PlainMessage<GetTermsOfUseRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetTermsOfUseRequest, a, b);
  }
}
