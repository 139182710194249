import Joi from 'joi';

export enum FieldNames {
  FEEDBACK = 'feedback',
  EVALUATION_ID = 'evaluationId',
}

export const FIELD_LABELS = {
  [FieldNames.FEEDBACK]: 'Evaluation feedback',
};

export const sendEvaluationFeedbackFormSchema = {
  [FieldNames.FEEDBACK]: Joi.string().required().min(3).max(20).messages({
    'string.empty': 'fill in the first name',
    'string.min': 'first name must be at least 3 characters long',
    'string.max': 'first name must be less than or equal to 30 characters long',
  }),
  [FieldNames.EVALUATION_ID]: Joi.string().allow(),
};
