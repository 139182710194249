export const CookieService = {
  setCookie: (isCookiesAllowed: boolean): void => {
    localStorage.setItem('cookiesAllowed', String(isCookiesAllowed));
  },

  getCookie: (): boolean => {
    const isCookiesAllowed = localStorage.getItem('cookiesAllowed');

    if (!isCookiesAllowed) {
      return false;
    }

    return JSON.parse(isCookiesAllowed);
  },
};
