import Box from '@mui/material/Box';
import { DemandStatus_Status } from '@proto/marketplace/demand/v1/demand_pb';
import KanbanSection from '@pages/Marketplace/Dashboards/KanbanSection';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import CreateDemandButton from '@pages/Marketplace/ManageDemands/CreateDemandButton';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDashboard } from '@services/queries/MarketplaceQueries';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';
import Fade from '@mui/material/Fade';

export const DemandsList = (): JSX.Element => {
  const { dashboardId } = useParams();
  const { data, isLoading } = useGetDashboard(dashboardId as string);

  if (isLoading) return <LoaderWithDelay />;

  return (
    <Fade in timeout={500}>
      <Box display="flex" flexDirection="column" gap={2} mb={2}>
        {data?.demands ? (
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
            {Object.values(DemandStatus_Status)
              .filter((value) => typeof value === 'number' && value !== 0)
              .map((status) => (
                <KanbanSection status={Number(status)} demands={data?.demands} />
              ))}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" justifyContent="center" gap={0.5}>
              <InfoIcon />
              <Typography variant="subtitle2">You not have demands</Typography>
              <CreateDemandButton />
            </Box>
          </Box>
        )}
      </Box>
    </Fade>
  );
};
