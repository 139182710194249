// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file api/marketplace/v1/contract.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateContractRequest,
  CreateContractResponse,
  DeleteContractRequest,
  DeleteContractResponse,
  UpdateContractDescriptionRequest,
  UpdateContractDescriptionResponse,
  UpdateContractTraitsRequest,
  UpdateContractTraitsResponse,
} from '../../../marketplace/demand/v1/contract_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.marketplace.v1.ContractAPI
 */
export const ContractAPI = {
  typeName: 'api.marketplace.v1.ContractAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.ContractAPI.CreateContract
     */
    createContract: {
      name: 'CreateContract',
      I: CreateContractRequest,
      O: CreateContractResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ContractAPI.UpdateContractTraits
     */
    updateContractTraits: {
      name: 'UpdateContractTraits',
      I: UpdateContractTraitsRequest,
      O: UpdateContractTraitsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ContractAPI.UpdateContractDescription
     */
    updateContractDescription: {
      name: 'UpdateContractDescription',
      I: UpdateContractDescriptionRequest,
      O: UpdateContractDescriptionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ContractAPI.DeleteContract
     */
    deleteContract: {
      name: 'DeleteContract',
      I: DeleteContractRequest,
      O: DeleteContractResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
