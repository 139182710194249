import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const OrgsListPage = () => {
  return (
    <Container>
      <Box px={4}>
        <Outlet />
      </Box>
    </Container>
  );
};

export default OrgsListPage;
