import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';

export const LoaderWithDelay = () => {
  return (
    <Fade
      in
      style={{
        transitionDelay: '0ms',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        left: 0,
      }}
    >
      <LinearProgress color="primary" />
    </Fade>
  );
};
