import { createRoot } from 'react-dom/client';
import { enableAllPlugins } from 'immer';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { App } from '@components/App';
import { ErrorBoundary } from '@components/ErrorBoundary/ErrorBoundary';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { theme } from './theme';
import { reportWebVitals } from './reportWebVitals';

import './assets/scss/index.scss';

const root = createRoot(document.getElementById('app')!);

root.render(
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </ErrorBoundary>
    </QueryParamProvider>
  </BrowserRouter>
);
enableAllPlugins();
// If you want to start measuring performance in your app, pass a function to log results (for
// example: reportWebVitals(console.log)) or send to an analytics endpoint.
reportWebVitals();
