import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { profileClient, toolTipsClient } from '@services/api/profiler/profile';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import {
  ChangeProfileStatusRequest,
  ChangeProfileStatusResponse,
  GetMyProfileRequest,
  GetMyProfileResponse,
  GetProfileRequest,
  GetProfileResponse,
  ListProfilesRequest,
  ListProfilesResponse,
  PinItemRequest,
  PinItemResponse,
  PinnedItem,
  Profile,
  UnpinItemRequest,
  UnpinItemResponse,
  UpdateProfilePersonalInfoRequest,
  UpdateProfilePersonalInfoResponse,
  UpdateProfileTraitsRequest,
  UpdateProfileTraitsResponse,
} from '@proto/profiler/user/v1/profile_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';
import {
  FinishToolTipsRequest,
  FinishToolTipsResponse,
  ToolTips,
} from '@proto/profiler/user/v1/tool_tips_pb';
import { inviteClient } from '@services/api/profiler/biz';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import {
  InviteAction,
  InviteActionRequest,
  InviteActionResponse,
  ListInvitesRequest,
  ListInvitesResponse,
  SendInvitesRequest,
  SendInvitesResponse,
} from '@proto/profiler/invite/v1/invite_pb';
import { statusConverterToNumber } from '../../helpers/statusConverter';

export const useGetMyProfile = () => {
  const getMyProfile = async (): Promise<GetMyProfileResponse> => {
    return profileClient.getMyProfile(new GetMyProfileRequest());
  };

  return useQuery({
    queryKey: ['myProfile'],
    queryFn: getMyProfile,
  });
};

export const useListProfiles = (payload: UUIDS) => {
  const getSquad = async (): Promise<ListProfilesResponse> => {
    return profileClient.listProfiles(
      new ListProfilesRequest({
        profiles: payload,
      })
    );
  };

  return useQuery({
    queryKey: ['listProfiles', payload],
    queryFn: getSquad,
    select: (data: ListProfilesResponse) => {
      const profilesById = data.profiles?.profiles.reduce((acc, profile) => {
        if (profile.profileId) {
          acc[profile.profileId.value] = profile;
        }
        return acc;
      }, {} as { [key: string]: Profile });

      return profilesById;
    },
  });
};

export const useGetProfile = (payload: UUID) => {
  const getProfile = async (): Promise<GetProfileResponse> => {
    return profileClient.getProfile(
      new GetProfileRequest({
        by: {
          case: 'profileId',
          value: payload,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['getProfile'],
    queryFn: getProfile,
  });
};

export const useUpdateProfileName = () => {
  const dispatch = useAppDispatch();

  const updateProfileName = async (payload: {
    names: {
      firstName: string;
      lastName: string;
      nickName: string;
    };
    birthDate: Date | null;
  }): Promise<UpdateProfilePersonalInfoResponse> => {
    return profileClient.updateProfilePersonalInfo(
      new UpdateProfilePersonalInfoRequest({
        names: payload.names,
        birthDate: Timestamp.fromDate(
          // @ts-ignore
          payload.birthDate !== '' ? new Date(payload.birthDate) : new Date()
        ),
      })
    );
  };
  return useMutation({
    mutationKey: ['updateProfileName'],
    mutationFn: updateProfileName,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'update names',
          message: 'info updated',
          severity: 'success',
        })
      );
    },
  });
};

const useChangeProfileStatus = async () => {
  const dispatch = useAppDispatch();

  const changeProfileStatus = async (payload: {
    status: boolean;
  }): Promise<ChangeProfileStatusResponse> => {
    return profileClient.changeProfileStatus(
      new ChangeProfileStatusRequest({
        active: payload.status,
      })
    );
  };

  return useMutation({
    mutationKey: ['changeProfileStatus'],
    mutationFn: changeProfileStatus,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'change profile status',
          message: 'status updated',
          severity: 'success',
        })
      );
    },
  });
};

export const useGetListProfiles = (payload: string[]) => {
  const getListProfiles = async (): Promise<ListProfilesResponse> => {
    return profileClient.listProfiles(
      new ListProfilesRequest({
        profiles: new UUIDS({ values: payload }),
      })
    );
  };

  return useQuery({
    queryKey: ['listProfiles'],
    queryFn: getListProfiles,
  });
};

export const useUupdateProfileTraits = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const updateProfileTraits = async (
    payload: UpdateProfileTraitsRequest
  ): Promise<UpdateProfileTraitsResponse> => {
    return profileClient.updateProfileTraits(
      new UpdateProfileTraitsRequest({
        traits: {
          rate: payload.traits?.rate,
          capacity: payload.traits?.capacity,
          experience: payload.traits?.experience,
          interviewer: statusConverterToNumber(payload.traits?.interviewer),
          squader: statusConverterToNumber(payload.traits?.squader),
        },
      })
    );
  };

  return useMutation({
    mutationKey: ['updateProfileTraits'],
    mutationFn: updateProfileTraits,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'update traits',
          message: 'traits updated',
          severity: 'success',
        })
      );
      queryClient.invalidateQueries({ queryKey: ['myProfile'] });
      queryClient.invalidateQueries({ queryKey: ['listFeedItems'] });
    },
    onError: () => {
      dispatch(
        showSnackbar({
          id: 'update traits',
          message: 'traits not updated',
          severity: 'error',
        })
      );
    },
  });
};

export const useFinishToolTips = () => {
  const dispatch = useAppDispatch();
  const finishToolTips = async (payload: ToolTips): Promise<FinishToolTipsResponse> => {
    return toolTipsClient.finishToolTips(
      new FinishToolTipsRequest({
        toolTips: payload,
      })
    );
  };

  return useMutation({
    mutationKey: ['finishToolTips'],
    mutationFn: finishToolTips,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'finish tool tips',
          message: 'tool tips finished',
          severity: 'success',
        })
      );
    },
  });
};

export const useSendInvite = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const sendInvite = async (payload: {
    id: string;
    type: Entity_Type;
    profileIds: string[];
  }): Promise<SendInvitesResponse> => {
    return inviteClient.sendInvites(
      new SendInvitesRequest({
        entity: {
          id: new UUID({ value: payload.id }),
          type: payload.type,
        },
        recipients: {
          case: 'profiles',
          value: new UUIDS({
            values: payload.profileIds,
          }),
        },
      })
    );
  };

  return useMutation({
    mutationKey: ['sendInvite'],
    mutationFn: sendInvite,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'send invite',
          message: 'invite sent',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({
          id: 'send invite',
          message: error.message,
          severity: 'error',
        })
      );
    },
  });
};

export const useInvitesList = (payload: { id: string; type: Entity_Type }) => {
  const getInvitesList = async (): Promise<ListInvitesResponse> => {
    return inviteClient.listInvites(
      new ListInvitesRequest({
        entity: {
          id: new UUID({ value: payload.id }),
          type: payload.type,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['invitesList', payload],
    queryFn: getInvitesList,
  });
};

export const useAcceptUserInvite = () => {
  const dispatch = useAppDispatch();

  const acceptUserInvite = async (payload: { id: string }): Promise<InviteActionResponse> => {
    return inviteClient.inviteAction(
      new InviteActionRequest({
        inviteId: new UUID({ value: payload.id }),
        action: InviteAction.ACCEPT,
      })
    );
  };

  return useMutation({
    mutationKey: ['acceptUserInvite'],
    mutationFn: acceptUserInvite,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'accept invite',
          message: 'invite accepted',
          severity: 'success',
        })
      );
    },
    onError: () => {
      dispatch(
        showSnackbar({
          id: 'accept invite',
          message: 'invite not accepted',
          severity: 'error',
        })
      );
    },
  });
};

export const useRejectUserInvite = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const rejectUserInvite = async (payload: { id: string }): Promise<InviteActionResponse> => {
    return inviteClient.inviteAction(
      new InviteActionRequest({
        inviteId: new UUID({ value: payload.id }),
        action: InviteAction.CANCEL,
      })
    );
  };

  return useMutation({
    mutationKey: ['rejectUserInvite'],
    mutationFn: rejectUserInvite,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invitesList'] });
      dispatch(
        showSnackbar({
          id: 'reject invite',
          message: 'invite rejected',
          severity: 'success',
        })
      );
    },
    onError: () => {
      dispatch(
        showSnackbar({
          id: 'reject invite',
          message: 'invite not rejected',
          severity: 'error',
        })
      );
    },
  });
};

export const usePinEntity = () => {
  const queryClient = useQueryClient();
  const pinEntity = async (payload: {
    entityId: string;
    entityType: Entity_Type;
  }): Promise<PinItemResponse> => {
    return profileClient.pinItem(
      new PinItemRequest({
        pin: new PinnedItem({
          entity: {
            id: new UUID({ value: payload.entityId }),
            type: payload.entityType,
          },
        }),
      })
    );
  };

  return useMutation({
    mutationKey: ['pinEntity'],
    mutationFn: pinEntity,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['myProfile'] });
    },
  });
};

export const useUnpinEntity = () => {
  const queryClient = useQueryClient();
  const unpinEntity = async (payload: {
    entityId: string;
    entityType: Entity_Type;
  }): Promise<UnpinItemResponse> => {
    return profileClient.unpinItem(
      new UnpinItemRequest({
        entity: {
          id: new UUID({ value: payload.entityId }),
          type: payload.entityType,
        },
      })
    );
  };

  return useMutation({
    mutationKey: ['unpinEntity'],
    mutationFn: unpinEntity,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['myProfile'] });
    },
  });
};
