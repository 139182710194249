import { getConnectClient, getConnectClientWithInterceptor } from '@services/api/helpers';
import { ChatAPI } from '@proto/api/notificator/v1/chat_connect';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import {
  ChatMember_Role,
  EditMessageRequest,
  GetMessagesHistoryRequest,
  GroupVisitRequest,
  InitGroupRequest,
  LeaveGroupRequest,
  RemoveMessageRequest,
  SendMessageRequest,
  SetChatMemberRoleRequest,
} from '@proto/notificator/stream/v1/chat_pb';
import { useMutation } from '@tanstack/react-query';
import { EventsAPI } from '@proto/api/notificator/v1/events_connect';
import { DeleteRequest } from '@proto/notificator/stream/v1/events_pb';
import { Interceptor } from '@connectrpc/connect';
import { Timestamp } from '@bufbuild/protobuf';
import { useStreamStore } from '../../zustand/subscribe';

const chatClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ChatAPI
);

const eventClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  EventsAPI
);

export const useInitGroupWithUsers = () => {
  const { addGroup } = useStreamStore();
  const initGroup = async (payload: { value: UUIDS }) => {
    return chatClient.initGroup(
      new InitGroupRequest({ with: { case: 'profileIds', value: payload.value } })
    );
  };

  return useMutation({
    mutationFn: initGroup,
    onSuccess: (data) => {
      console.log('initGroup', data.group);
      // addGroup({group: data.group as Group, messages: []});
    },
  });
};

export const useSetChatMemberRole = () => {
  const setChatMemberRole = async (payload: { profileId: UUID; role: ChatMember_Role }) => {
    return chatClient.setChatMemberRole(
      new SetChatMemberRoleRequest(
        new SetChatMemberRoleRequest({
          profileId: payload.profileId,
          role: payload.role,
        })
      )
    );
  };

  return useMutation({
    mutationFn: setChatMemberRole,
  });
};

export const useLeaveGroup = () => {
  const leaveGroup = async (groupId: UUID) => {
    return chatClient.leaveGroup(new LeaveGroupRequest({ groupId }));
  };

  return useMutation({
    mutationFn: leaveGroup,
  });
};

export const useVisitGroup = () => {
  const visitGroup = async (groupId: UUID) => {
    return chatClient.groupVisit(new GroupVisitRequest({ groupId }));
  };

  return useMutation({
    mutationFn: visitGroup,
  });
};

export const useGetMessagesHistory = () => {
  const { addMessagesToGroup } = useStreamStore();

  const getMessagesHistory = async ({
    groupId,
    token,
    offset,
  }: {
    groupId: UUID;
    token?: string;
    offset: Timestamp;
  }) => {
    const customInterceptor: Interceptor = (next) => async (req) => {
      if (token) {
        req.header.set('x-chat-session', token);
      }
      return next(req);
    };

    const chatClientWithInterceptor = await getConnectClientWithInterceptor(
      `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
      ChatAPI,
      customInterceptor
    );

    return chatClientWithInterceptor.getMessagesHistory(
      new GetMessagesHistoryRequest({
        groupId,
        offset,
      })
    );
  };

  return useMutation({
    mutationFn: getMessagesHistory,
    onSuccess: (data) => {
      addMessagesToGroup(
        data.messages?.messages[0].groupId?.value as string,
        data.messages?.messages || []
      );
    },
  });
};

export const useDeleteEvent = () => {
  const deleteEvent = async (ids: UUIDS) => {
    return eventClient.delete(new DeleteRequest({ ids }));
  };

  return useMutation({
    mutationFn: deleteEvent,
  });
};

export const useSendMessage = () => {
  const sendMessage = async (payload: { text: string; groupId: UUID; token?: string }) => {
    const customInterceptor: Interceptor = (next) => async (req) => {
      if (payload.token) {
        req.header.set('x-chat-session', payload.token);
      }
      return next(req);
    };

    const chatClientWithInterceptor = await getConnectClientWithInterceptor(
      `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
      ChatAPI,
      customInterceptor
    );

    return chatClientWithInterceptor.sendMessage(
      new SendMessageRequest({
        text: payload.text,
        groupId: payload.groupId,
      })
    );
  };

  return useMutation({
    mutationFn: sendMessage,
  });
};

export const useEditMessage = () => {
  const editMessage = async (payload: { text: string; messageId: UUID; token?: string }) => {
    const customInterceptor: Interceptor = (next) => async (req) => {
      if (payload.token) {
        req.header.set('x-chat-session', payload.token);
      }
      return next(req);
    };

    const chatClientWithInterceptor = await getConnectClientWithInterceptor(
      `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
      ChatAPI,
      customInterceptor
    );

    return chatClientWithInterceptor.editMessage(
      new EditMessageRequest({
        text: payload.text,
        messageId: payload.messageId,
      })
    );
  };

  return useMutation({
    mutationFn: editMessage,
  });
};

export const useRemoveMessage = () => {
  const removeMessage = async (payload: { messageId: UUID; token: string }) => {
    const customInterceptor: Interceptor = (next) => async (req) => {
      if (payload.token) {
        req.header.set('x-chat-session', payload.token);
      }
      return next(req);
    };

    const chatClientWithInterceptor = await getConnectClientWithInterceptor(
      `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
      ChatAPI,
      customInterceptor
    );

    return chatClientWithInterceptor.removeMessage(
      new RemoveMessageRequest({
        messageId: payload.messageId,
      })
    );
  };

  return useMutation({
    mutationFn: removeMessage,
  });
};
