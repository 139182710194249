import React, { Suspense } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import { useDialog } from '../hooks/useDialog';
import ChatDialog from './ChatDialog';

const OpenChatButton = () => {
  const { isOpen, handleOpen, handleClose } = useDialog();

  return (
    <div>
      <ChatIcon cursor="pointer" onClick={handleOpen} />
      <Suspense fallback={null}>
        <ChatDialog isOpen={isOpen} handleClose={handleClose} />
      </Suspense>
    </div>
  );
};

export default OpenChatButton;
