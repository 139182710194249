// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file api/marketplace/v1/evaluation.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Evaluations } from '../../../marketplace/demand/v1/evaluation_pb.js';
import { ObjectTags } from '../../../profiler/tags/v1/tags_pb.js';

/**
 * @generated from message api.marketplace.v1.ListEvaluationsResponse
 */
export class ListEvaluationsResponse extends Message<ListEvaluationsResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Evaluations evaluations = 1;
   */
  evaluations?: Evaluations;

  /**
   * @generated from field: repeated profiler.tags.v1.ObjectTags tags = 2;
   */
  tags: ObjectTags[] = [];

  constructor(data?: PartialMessage<ListEvaluationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.marketplace.v1.ListEvaluationsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluations', kind: 'message', T: Evaluations },
    { no: 2, name: 'tags', kind: 'message', T: ObjectTags, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEvaluationsResponse | PlainMessage<ListEvaluationsResponse> | undefined,
    b: ListEvaluationsResponse | PlainMessage<ListEvaluationsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationsResponse, a, b);
  }
}
