import { JoinSection } from '@pages/Home/JoinSection/JoinSection';
import Box from '@mui/material/Box';
import { Logo } from '@components/Logo';
import React from 'react';
import styled from '@mui/material/styles/styled';
import { LayoutContainer } from '@components/LayoutContainer/LayoutContainer';

const StyledHeader = styled(LayoutContainer)`
  background-color: transparent;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 3;
`;

export const Login = () => {
  return (
    <Box py={{ xs: 15, md: 0 }}>
      <StyledHeader
        sx={{ position: 'fixed', px: { xl: '10.75rem', lg: '8.125rem', xs: '1.14rem' } }}
        tag="header"
      >
        <Box
          width="100%"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box py={{ xs: 3, lg: 4, xl: 5 }}>
            <Logo />
          </Box>
        </Box>
      </StyledHeader>
      <JoinSection />
    </Box>
  );
};
