import { FC, lazy, LazyExoticComponent } from 'react';

export const lazyComponent = <T = unknown>(
  name: string,
  importer: Promise<Record<string, FC<T>>>
): LazyExoticComponent<FC<T>> =>
  lazy(async () => {
    const component = await importer;
    return { default: component[name] };
  });
