import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TRootState, useAppDispatch, useAppSelector } from '@store/helpers';
import { useCallback } from 'react';

type Snackbar = {
  id: string;
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  visible: boolean;
};

type SnackbarState = Snackbar[];

const initialState: SnackbarState = [];

const snackbarsSlice = createSlice({
  name: 'snackbars',
  initialState,
  reducers: {
    showSnackbar(
      state,
      action: PayloadAction<{
        id: string;
        message: string;
        severity: 'error' | 'warning' | 'info' | 'success';
      }>
    ) {
      const { id, message, severity } = action.payload;
      const index = state.findIndex((snackbar) => snackbar.id === id);
      if (index !== -1) {
        state[index] = { ...state[index], visible: true, message, severity };
      } else {
        state.push({ id, message, severity, visible: true });
      }
    },
    hideSnackbar(state, action: PayloadAction<{ id: string }>) {
      const index = state.findIndex((snackbar) => snackbar.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], visible: false };
      }
    },
    removeSnackbar(state, action: PayloadAction<{ id: string }>) {
      return state.filter((snackbar) => snackbar.id !== action.payload.id);
    },
  },
});

export const { showSnackbar, hideSnackbar, removeSnackbar } = snackbarsSlice.actions;
export const snackbarsReducer = combineReducers({
  snackbars: snackbarsSlice.reducer,
});

export const useSnackbar = (): {
  snackbars: SnackbarState;

  showSnackbar: (data: Snackbar) => void;
  hideSnackbar: (id: string) => void;
} => {
  const dispatch = useAppDispatch();

  return {
    snackbars: useAppSelector((state: TRootState) => state.snackbarsReducer.snackbars),
    showSnackbar: useCallback((data: Snackbar) => dispatch(showSnackbar(data)), [dispatch]),
    hideSnackbar: useCallback((id: string) => dispatch(hideSnackbar({ id })), [dispatch]),
  };
};
