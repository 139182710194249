import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useMarketplace } from '@store/marketplace';
import Typography from '@mui/material/Typography';
import { TOrg } from '@services/api/profiler/biz';
import { Squad, Squads } from '@proto/profiler/biz/v1/squad_pb';
import { useListUserSquads } from '@services/queries/SquadsQueries';
import { useListUserOrgs } from '@services/queries/OrgsQueries';
import { Orgs } from '@proto/profiler/biz/v1/org_pb';
import { MenuItem } from '@mui/material';
import { useEffect } from 'react';

type ResultType = { entity: string; name: string };

const mergeLists = (squads: Squad[] = [], orgs: TOrg[] = []): ResultType[] => [
  ...squads.map(({ squadId: id, name }) => ({
    entity: JSON.stringify({ id, case: 'squadId' }),
    name,
  })),
  ...orgs.map(({ orgId: id, name }) => ({
    entity: JSON.stringify({ id, case: 'orgId' }),
    name,
  })),
];

const PreAppliedEntitySelect = () => {
  const { currentContractorId, setCurrentContractorId } = useMarketplace();
  const { data: squadsList } = useListUserSquads();
  const { data: orgsList } = useListUserOrgs();

  const mergedList = mergeLists(
    (squadsList?.squads as Squads)?.squads,
    (orgsList?.orgs as Orgs)?.orgs
  );

  const handleChange = (event: SelectChangeEvent) => {
    const entity = JSON.parse(event.target.value as string);
    setCurrentContractorId({
      id: entity.id,
      case: entity.case,
    });
  };

  useEffect(() => {
    if (mergedList && mergedList.length > 0) {
      setCurrentContractorId({
        id: JSON.parse(mergedList[0].entity).id,
        case: JSON.parse(mergedList[0].entity).case,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentContractorId ? (
    <div>
      <Typography variant="subtitle2" mb={1}>
        Select entity for apply contracts:
      </Typography>
      <Select value={JSON.stringify(currentContractorId)} onChange={handleChange} fullWidth>
        {mergedList?.map((entity) => (
          <MenuItem value={entity.entity} key={entity.name}>
            {entity.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : null;
};

export default PreAppliedEntitySelect;
