// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file api/notificator/v1/chat.proto (package api.notificator.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  EditMessageRequest,
  EditMessageResponse,
  GetMessagesHistoryRequest,
  GetMessagesHistoryResponse,
  GroupVisitRequest,
  GroupVisitResponse,
  InitGroupRequest,
  InitGroupResponse,
  LeaveGroupRequest,
  LeaveGroupResponse,
  RemoveMessageRequest,
  RemoveMessageResponse,
  SendMessageRequest,
  SendMessageResponse,
  SetChatMemberRoleRequest,
  SetChatMemberRoleResponse,
} from '../../../notificator/stream/v1/chat_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.notificator.v1.ChatAPI
 */
export const ChatAPI = {
  typeName: 'api.notificator.v1.ChatAPI',
  methods: {
    /**
     * @generated from rpc api.notificator.v1.ChatAPI.SendMessage
     */
    sendMessage: {
      name: 'SendMessage',
      I: SendMessageRequest,
      O: SendMessageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.notificator.v1.ChatAPI.EditMessage
     */
    editMessage: {
      name: 'EditMessage',
      I: EditMessageRequest,
      O: EditMessageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.notificator.v1.ChatAPI.RemoveMessage
     */
    removeMessage: {
      name: 'RemoveMessage',
      I: RemoveMessageRequest,
      O: RemoveMessageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.notificator.v1.ChatAPI.GetMessagesHistory
     */
    getMessagesHistory: {
      name: 'GetMessagesHistory',
      I: GetMessagesHistoryRequest,
      O: GetMessagesHistoryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.notificator.v1.ChatAPI.LeaveGroup
     */
    leaveGroup: {
      name: 'LeaveGroup',
      I: LeaveGroupRequest,
      O: LeaveGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.notificator.v1.ChatAPI.SetChatMemberRole
     */
    setChatMemberRole: {
      name: 'SetChatMemberRole',
      I: SetChatMemberRoleRequest,
      O: SetChatMemberRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.notificator.v1.ChatAPI.InitGroup
     */
    initGroup: {
      name: 'InitGroup',
      I: InitGroupRequest,
      O: InitGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.notificator.v1.ChatAPI.GroupVisit
     */
    groupVisit: {
      name: 'GroupVisit',
      I: GroupVisitRequest,
      O: GroupVisitResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
