import { createServiceSlice } from '@store/helpers';
import { TMarketplaceCaseReducers, TMarketplaceState } from '@store/marketplace/marketplace';

export const marketplaceSlice = createServiceSlice<TMarketplaceState, TMarketplaceCaseReducers>({
  name: 'marketplace',
  initialState: {
    feedOwner: null,
    displayListType: 'all',
    preAppliesContracts: [],
    listEvaluations: {},
    listEvaluationsByManager: {},
    currentContractorId: undefined,
    contractors: undefined,
  },

  reducers: {
    setDisplayListType: (state, action) => {
      state.displayListType = action.payload;
    },
    setFeedOwner(state, action) {
      state.feedOwner = action.payload;
    },
    setCandidatesList(state, action) {
      state.candidatesList = Object.assign(state.candidatesList, action.payload);
    },
    setListEvaluations(state, action) {
      state.listEvaluations = Object.assign(state.listEvaluations, action.payload);
    },
    setListInterviewEvaluations(state, action) {
      state.listInterviewEvaluations = action.payload;
    },
    setCurrentContractorId(state, action) {
      state.currentContractorId = action.payload;
    },
    setContractors(state, action) {
      state.contractors = action.payload;
    },
  },
});
