import Button from '@mui/material/Button';

interface SendInvitesButtonProps {
  handleSubmit: () => void;
}

const SendInvitesButton = (props: SendInvitesButtonProps) => {
  const { handleSubmit } = props;

  return (
    <Button variant="outlined" color="success" fullWidth onClick={() => handleSubmit()}>
      Send invite
    </Button>
  );
};
export default SendInvitesButton;
