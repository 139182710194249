import { getConnectClient, makeRequest, PlainMsg } from '@services/api/helpers';
import {
  AddTagsRequest,
  AddTagsResponse,
  ListTagsRequest,
  ListTagsResponse,
  RemoveTagsRequest,
  RemoveTagsResponse,
  SetObjectTagsStatusRequest,
  SetObjectTagsStatusResponse,
  Tag,
} from '@proto/profiler/tags/v1/tags_pb';
import { TagsAPI } from '@proto/api/profiler/v1/tags_connect';

export type AddTagsServiceResponse = PlainMsg<AddTagsResponse>;
export type AddTagsServiceRequest = PlainMsg<AddTagsRequest>;
export type RemoveTagsServiceResponse = PlainMsg<RemoveTagsResponse>;
export type RemoveTagsServiceRequest = PlainMsg<RemoveTagsRequest>;

export type ListTagsServiceRequest = PlainMsg<ListTagsRequest>;
export type ListTagsServiceResponse = PlainMsg<ListTagsResponse>;

export type SetTagsStatuServiceRequest = PlainMsg<SetObjectTagsStatusRequest>;
export type SetTagsStatuServiceResponse = PlainMsg<SetObjectTagsStatusResponse>;

export enum TagTypeEnum {
  ROLE = 1,
  COUNTRY = 2,
  LANGUAGE = 3,
  INDUSTRY_DOMAIN = 4,
  PROGRAMMING_LANGUAGE = 5,
}

export const tagsLabel = {
  1: 'Role',
  2: 'Country',
  3: 'Language',
  4: 'Industry Domain',
  5: 'Programming Language',
};

export const TagTypeNumberEnum = {
  TAG_TYPE_ROLE: 2,
  TAG_TYPE_COUNTRY: 5,
  TAG_TYPE_LANGUAGE: 1,
  TAG_TYPE_INDUSTRY_DOMAIN: 3,
  TAG_TYPE_PROGRAMMING_LANGUAGE: 4,
};

export type TTag = PlainMsg<Tag>;

const TagsClient = getConnectClient<typeof TagsAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  TagsAPI
);

export const tagsService = {
  addTags(request: AddTagsServiceRequest): Promise<AddTagsServiceResponse> {
    return makeRequest(TagsClient.addTags, request);
  },
  listTags(request: ListTagsServiceRequest): Promise<ListTagsServiceResponse> {
    return makeRequest(TagsClient.listTags, request);
  },
  setTagsStatus(request: SetTagsStatuServiceRequest): Promise<SetTagsStatuServiceResponse> {
    return makeRequest(TagsClient.setObjectTagsStatus, request);
  },
  removeTags(request: RemoveTagsServiceRequest): Promise<RemoveTagsServiceResponse> {
    return makeRequest(TagsClient.removeTags, request);
  },
};
