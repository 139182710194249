import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useSquads } from '@store/squads';
import Fade from '@mui/material/Fade';
import ExploreSquadCard from '@pages/Squads/ExploreSquads/ExploreSquadCard';
import { Timestamp } from '@bufbuild/protobuf';
import { findOldestDateInSquad } from '@utils/findOldestDate';
import InfoIcon from '@mui/icons-material/Info';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { TTag } from '@services/api/profiler/tags';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';

const SquadsFiltersTypes = {
  roles: TagType.ROLE,
  countries: TagType.COUNTRY,
  languages: TagType.LANGUAGE,
  industryDomains: TagType.INDUSTRY_DOMAIN,
  programmingLanguages: TagType.PROGRAMMING_LANGUAGE,
};

const ExploreSquadsList = () => {
  const { listExploreSquads, searchSquads, searchSquadsPagination, squadsFilters } = useSquads();

  useEffect(() => {
    searchSquads(
      Object.entries(squadsFilters).reduce((acc, [key, value]) => {
        if (value.length) {
          JSON.parse(value).forEach((tag: TTag) =>
            acc.push({
              tagId: tag.tagId,
              tagType: SquadsFiltersTypes[key as keyof typeof SquadsFiltersTypes],
              value: tag.value,
            })
          );
        }
        return acc;
      }, [] as any[])
    );
  }, [searchSquads, squadsFilters]);

  return (
    <Page description="Explore squads" title="Explore squads">
      <Container>
        <Fade in timeout={500}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={1} alignItems="center">
                <Diversity3Icon />
                <Typography component="h5">Explore squads</Typography>
              </Box>
            </Stack>

            <Stack gap={2}>
              {listExploreSquads?.map((squad) => (
                <ExploreSquadCard squad={squad} />
              ))}
            </Stack>
            {listExploreSquads && listExploreSquads.length === 0 && (
              <Box sx={{ display: 'flex' }}>
                <InfoIcon />
                <Typography align="center" variant="subtitle1">
                  Squads not found
                </Typography>
              </Box>
            )}
            {listExploreSquads && listExploreSquads.length > 10 && (
              <Button
                variant="outlined"
                onClick={() =>
                  searchSquadsPagination(
                    Timestamp.fromDate(findOldestDateInSquad(listExploreSquads))
                  )
                }
              >
                Show more
              </Button>
            )}
          </Box>
        </Fade>
      </Container>
    </Page>
  );
};

export default ExploreSquadsList;
