import Box from '@mui/material/Box';
import React from 'react';
import AvatarLightbox from '@components/Avatars/AvatarLightbox';

const Avatars = ({ entityId, link }: { entityId: string; link: string }) => {
  return (
    <Box display="flex" alignItems="flex-end" gap={2} mb={2}>
      <AvatarLightbox entityId={entityId} link={link} />
    </Box>
  );
};

export default Avatars;
