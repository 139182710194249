import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import { ProfileNameForm } from '@pages/ProfileSettings/ProfileNameForm/ProfileNameForm';
import Divider from '@mui/material/Divider';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { ProfileTraitsForm } from '@pages/ProfileSettings/ProfileTraitsForm/ProfileTraitsForm';
import Avatars from '@components/Avatars/Avatars';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import MfaButton from '@pages/ProfileSettings/MfaDialog/MfaButton';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="w-full"
      {...other}
    >
      {value === index && (
        <Box className="w-full" sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs = () => {
  const [value, setValue] = React.useState(0);
  const { data: userProfile } = useGetMyProfile();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Page description="Profile" title="Profile">
      <Container>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Tabs
            orientation="vertical"
            className="w-[20%]"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label="Personal info" {...a11yProps(0)} />
            <Tab label="Expertise" {...a11yProps(1)} />
            <Tab label="Work details" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Box className="flex flex-col gap-6">
              <Box className="flex flex-col gap-2 ">
                <Typography fontSize="24px" className="font-bold">
                  Profile
                </Typography>
                <Typography>This is how others will see you on the site.</Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  height: '65vh',
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                <Avatars
                  link={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${userProfile?.profile?.profileId?.value}/${userProfile?.profile?.avatar}`}
                  entityId={userProfile?.profile?.profileId?.value as string}
                />
                <MfaButton />
                <ProfileNameForm />
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Box className="flex flex-col gap-6">
              <Box className="flex flex-col gap-2">
                <Typography fontSize="24px" className="font-bold">
                  Expertise
                </Typography>
                <Typography>
                  Indicate your characteristics. We will show you the demands that suit you.
                </Typography>
              </Box>
              <Divider />
              <ProfileTags />
            </Box>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Box className="flex flex-col gap-6">
              <Box className="flex flex-col gap-2">
                <Typography fontSize="24px" className="font-bold">
                  Work details
                </Typography>
                <Typography>
                  Indicate your working conditions, how much time you spend on work and what your
                  rate of time is.
                </Typography>
              </Box>
              <Divider />
              <ProfileTraitsForm />
            </Box>
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
};

export default VerticalTabs;
