import { createServiceSlice } from '@store/helpers';
import { OrgCaseReducer, OrgState } from './orgs';

export const orgSlices = createServiceSlice<OrgState, OrgCaseReducer>({
  name: 'orgs',
  initialState: {
    listUserOrgs: undefined,
    pinnedOrgs: undefined,
    exploreOrgsList: undefined,
    currentOrg: undefined,
    orgsFilters: undefined,
  },
  reducers: {
    setUserOrgs: (state, action) => {
      const { orgs } = action.payload;

      state.listUserOrgs = orgs;
    },
    setPinnedOrgs: (state, action) => {
      const { orgs } = action.payload;

      state.pinnedOrgs = orgs;
    },
    setOrg: (state, action) => {
      const { org } = action.payload;

      if (state.listUserOrgs === undefined) {
        state.listUserOrgs = [org];
        return;
      }
      state.listUserOrgs.push(org);
    },
    setOrgsFilter: (state, action) => {
      const { orgsFilter } = action.payload;
      state.orgsFilters = orgsFilter;
    },
    setExploreOrgs: (state, action) => {
      const { orgs } = action.payload;

      state.exploreOrgsList = orgs;
    },
    setPaginationOrgs: (state, action) => {
      const { orgs } = action.payload;
      if (Object.keys(orgs).length === 0) return;
      if (state.exploreOrgsList) state.exploreOrgsList = state.exploreOrgsList?.concat(orgs);
    },
    setCurrentOrg: (state, action) => {
      const org = action.payload.orgs[0];

      state.currentOrg = org;
    },
  },
});
