export const transformFiltersFromStringToAutocomplete = (filters: string[]) => {
  if (filters.length === 0) return [];
  return (
    filters
      ?.map((el) => el.split(':'))
      ?.reduce((acc, el) => {
        return [...acc, { value: el[0], tagId: Number(el[1]) }];
      }, [] as { value: string; tagId: number }[]) || []
  );
};
