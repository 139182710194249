import { TSquad } from '@services/api/profiler/biz';
import { Typography } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ROUTES_PREFIXES } from '@common/routes';
import { Squad } from '@proto/profiler/biz/v1/squad_pb';
import { replaceSpacesWithDashes } from '@utils/parseStrings';
import CustomAvatar from '@components/Avatar/Avatar';

type TPinnedSquadCard = {
  squad: TSquad | Squad;
};

const MembershipSquadCard = (props: TPinnedSquadCard) => {
  const { squad } = props;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Link
        className="NavLink"
        to={`${ROUTES_PREFIXES.SQUADS}/${replaceSpacesWithDashes(squad?.name)}`}
      >
        <Box display="flex" alignItems="center" width="100%" gap={1}>
          <CustomAvatar
            alt={squad?.name || 'Squad'}
            avatarId={squad?.avatar}
            profileId={squad?.squadId?.value}
          />

          <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} fontSize="13px">
            {squad?.name}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default MembershipSquadCard;
