import { TagTypeNumberEnum, TTag } from '@services/api/profiler/tags';
import { ObjectTags, RemoveTagsRequest, Tag, Tags } from '@proto/profiler/tags/v1/tags_pb';
import { TAutocompleteItem } from '@components/TagAutocomplete/TagAutocomplete';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';

export const selectHandler = (tag: TTag, data: any, request: any) => {
  const tags = new ObjectTags({
    entity: new Entity({
      id: new UUID({ value: data.entityId.value }),
      type: data.entity,
    }),
    tags: new Tags({
      tags: [
        new Tag({
          tagType: TagTypeNumberEnum[tag.tagType as unknown as keyof typeof TagTypeNumberEnum],
          tagId: tag.tagId,
          value: tag.value,
          active: true,
        }),
      ],
    }),
  });

  return request({ tags });
};
export const statusChangeHandler = (tag: TAutocompleteItem, data: any, request: any) => {
  const tags = {
    objectId: data.entityId,
    tags: new Tags({
      tags: [
        new Tag({
          tagType: TagTypeNumberEnum[tag.tagType as unknown as keyof typeof TagTypeNumberEnum],
          tagId: tag.tagId,
          value: tag.value,
          active: data.status,
        }),
      ],
    }),
  };
  //
  return request({ tags });
};

export const removeHandler = (tag: TTag, data: any, request: any) => {
  const tags = new RemoveTagsRequest({
    entity: new Entity({
      id: new UUID({ value: data.entityId.value }),
      type: data.entity,
    }),
    tagIds: [tag.tagId],
  });

  return request(tags);
};
