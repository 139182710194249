import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from '@store/auth';
import { useDialog } from '@components/hooks/useDialog';
import MfaConfirmDialog from '@pages/Mfa/MfaConfirmDialog';
import { Suspense } from 'react';
import { Page } from '@components/Page/Page';

const Mfa = () => {
  const { generateOtp, otp } = useAuth();
  const { isOpen, handleOpen, handleClose } = useDialog();

  return (
    <Page description="MFA" title="Mfa">
      <Stack>
        <LoadingButton
          onClick={async () => {
            await generateOtp();
            handleOpen();
          }}
          variant="outlined"
        >
          Get MFA
        </LoadingButton>
      </Stack>
      <Suspense fallback={null}>
        <MfaConfirmDialog isOpen={isOpen} handleClose={handleClose} otp={otp} />
      </Suspense>
    </Page>
  );
};

export default Mfa;
