import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { useProfiler } from '@store/profiler';
import DeleteIcon from '@mui/icons-material/Delete';
import { InviteAction } from '@proto/profiler/invite/v1/invite_pb';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import Card from '@components/Card/Card';
import Stack from '@mui/material/Stack';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';

const ManageSquadInvitesList = () => {
  const { listRoles } = useProfiler();
  const { data: userProfile } = useGetMyProfile();

  const { entityId } = useParams();
  const { getEntityInvitesList, invitesLists, closeGroupingInvite } = useProfiler();

  const requiredRoles = [IAMRoleStrings.IAM_ROLE_ADMIN, IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER];

  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[entityId as keyof typeof listRoles]?.find(
      (el) => el.id === (userProfile?.profile?.profileId?.value as string)
    )?.roles.roles
  );

  useEffect(() => {
    if (entityId && hasAccess) getEntityInvitesList({ id: entityId, type: Entity_Type.SQUAD });
  }, [getEntityInvitesList, entityId, hasAccess]);

  return hasAccess ? (
    <Box>
      {invitesLists[entityId as keyof typeof invitesLists] && (
        <Stack gap={2}>
          <Typography variant="subtitle2">Need confirmation</Typography>
          {invitesLists[entityId as keyof typeof invitesLists]?.map((item) => {
            return (
              <Card key={item.inviteId?.value}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex">
                    <Avatar
                      sx={{ width: 50, height: 50, marginRight: '10px' }}
                      src="/"
                      alt={item?.name?.nickName.toUpperCase()}
                    />
                    {item?.name?.nickName}
                  </Box>
                  <Tooltip
                    placement="left"
                    onClick={() => {
                      closeGroupingInvite({
                        inviteId: item?.inviteId?.value as string,
                        action: InviteAction.CANCEL,
                      });
                    }}
                    title="Cancel invite"
                  >
                    <IconButton>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            );
          })}
        </Stack>
      )}
    </Box>
  ) : null;
};
export default ManageSquadInvitesList;
