import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import QRCode from 'react-qr-code';
import Typography from '@mui/material/Typography';
import { Form } from '@components/Form';
import {
  FIELD_LABELS,
  FieldNames,
  getDefaultValuesValidateOtp,
  myOtpValidateFormSchema,
} from '@pages/Mfa/config';
import Stack from '@mui/material/Stack';
import { InputController } from '@components/Form/controllers/InputController';
import LoadingButton from '@mui/lab/LoadingButton';
import { ValidateTotpServiceRequest } from '@services/api/auth';
import { useAuth } from '@store/auth';

type MfaConfirmDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  otp: {
    url: string;
  } | null;
};

const MfaConfirmDialog = (props: MfaConfirmDialogProps) => {
  const { isOpen, handleClose, otp } = props;
  const { validateOtp } = useAuth();
  return (
    <Dialog
      onClose={handleClose}
      sx={{ display: 'flex', justifyContent: 'center', direction: 'column' }}
      open={isOpen}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        py={5}
        px={10}
      >
        <Typography textAlign="center" mb={2} variant="subtitle1">
          Open auth app and scan QR
        </Typography>
        {otp && (
          <QRCode
            size={256}
            style={{ height: '300px', maxWidth: '100%', width: '300px' }}
            value={otp.url}
            viewBox="0 0 256 256"
          />
        )}
        <Form<ValidateTotpServiceRequest>
          width="100%"
          display="flex"
          alignSelf="center"
          flexDirection="column"
          formProps={{ defaultValues: getDefaultValuesValidateOtp() }}
          resolverSchema={myOtpValidateFormSchema}
          submitHandler={(data) => {
            validateOtp(data);
          }}
        >
          <Stack spacing={2} direction="column" mb={2}>
            <InputController name={FieldNames.PASSCODE} label={FIELD_LABELS[FieldNames.PASSCODE]} />
          </Stack>

          <Stack>
            <LoadingButton type="submit" variant="outlined">
              Send passcode
            </LoadingButton>
          </Stack>
        </Form>
      </Box>
    </Dialog>
  );
};

export default MfaConfirmDialog;
