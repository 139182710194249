import { createServiceSlice } from '@store/helpers';
import { SquadsCaseReducer, SquadsState } from './squads';

export const squadsSlices = createServiceSlice<SquadsState, SquadsCaseReducer>({
  name: 'squads',
  initialState: {
    squadsList: undefined,
    pinnedSquads: undefined,
    userSquadsIds: undefined,
    exploreSquadsList: undefined,
    exploreSquad: undefined,
    squadsFilters: undefined,
  },
  reducers: {
    setSquads: (state, action) => {
      const { squads } = action.payload;

      state.squadsList = squads;
    },

    setSquad: (state, action) => {
      const { squad } = action.payload;

      if (state.squadsList === undefined) {
        state.squadsList = [squad];
        return;
      }
      state.squadsList.push(squad);
    },

    setSquadsFilters: (state, action) => {
      const { filters } = action.payload;

      state.squadsFilters = filters;
    },

    setExploreSquads: (state, action) => {
      const { squads } = action.payload;

      state.exploreSquadsList = squads;
    },
    setPaginationSquads: (state, action) => {
      const { squads } = action.payload;

      if (state.exploreSquadsList)
        state.exploreSquadsList = state.exploreSquadsList?.concat(squads);
    },
    setExploreSquad: (state, action) => {
      state.exploreSquad = action.payload;
    },
  },
});
