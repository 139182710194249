import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';

import {
  bizService,
  GetSquadServiceRequest,
  GetSquadServiceResponse,
  ListSquadsServiceRequest,
  ListSquadsServiceResponse,
  SearchSquadsServiceRequest,
  SearchSquadsServiceResponse,
  TSquad,
} from '@services/api/profiler/biz';

import { SearchSquadsRequest } from '@proto/profiler/biz/v1/squad_pb';

import { useCallback } from 'react';
import { userSlice } from '@store/profiler/slices';
import { combineReducers } from '@reduxjs/toolkit';
import { Timestamp } from '@bufbuild/protobuf';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { squadsSlices } from './slices';

const rolesParam = withDefault(StringParam, '');
const langParam = withDefault(StringParam, '');
const countryParam = withDefault(StringParam, '');
const industryParam = withDefault(StringParam, '');
const programmingLangParam = withDefault(StringParam, '');

const {
  actions: { setSquads, setExploreSquads, setExploreSquad, setPaginationSquads },
} = squadsSlices;

const {
  actions: { deleteSelectedProfile },
} = userSlice;

export const squadsReducer = combineReducers({
  squads: squadsSlices.reducer,
});

export const getListUserSquads = createServiceCall<
  ListSquadsServiceResponse,
  ListSquadsServiceRequest
>(
  squadsSlices.name,
  bizService.listSquads,
  ({ response: { squads }, dispatch }) => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(setSquads(squads));
  },
  ({ dispatch }) => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(setSquads({ squads: [] }));
  }
);

const searchSquads = createServiceCall<SearchSquadsServiceResponse, SearchSquadsServiceRequest>(
  squadsSlices.name,
  bizService.searchSquads,
  ({ response: { squads }, dispatch }) => {
    dispatch(setExploreSquads(squads));
  },
  ({ dispatch }) => {
    dispatch(setExploreSquads({ squads: [] }));
  }
);

export const getCurrentSquad = createServiceCall<GetSquadServiceResponse, GetSquadServiceRequest>(
  squadsSlices.name,
  bizService.getSquad,
  ({ response: { squad }, dispatch }) => {
    dispatch(setExploreSquad(squad));
  }
);
const searchSquadsPagination = createServiceCall<
  SearchSquadsServiceResponse,
  SearchSquadsServiceRequest
>(squadsSlices.name, bizService.searchSquads, ({ response: { squads }, dispatch }) => {
  dispatch(setPaginationSquads(squads));
});

export const useSquads = (): {
  listExploreSquads: TSquad[] | undefined;
  squadsFilters: { [key: string]: string };
  searchSquads: (payload: any) => void;
  searchSquadsPagination: (payload: Timestamp) => void;
  setSquadsFilters: (payload: any) => void;
} => {
  const dispatch = useAppDispatch();

  const [query, setQuery] = useQueryParams({
    roles: rolesParam,
    languages: langParam,
    countries: countryParam,
    industryDomains: industryParam,
    programmingLanguages: programmingLangParam,
  });

  return {
    listExploreSquads: useAppSelector((state) => state.squadsReducer.squads.exploreSquadsList),
    squadsFilters: query,
    setSquadsFilters: useCallback(
      (payload) => {
        if (payload) {
          setQuery((prevQuery) => {
            const newQuery = { ...prevQuery, ...payload };

            return newQuery;
          });
        }
      },
      [setQuery]
    ),
    searchSquads: useCallback(
      (payload) => {
        if (!payload.length)
          return dispatch(
            searchSquads(
              new SearchSquadsRequest({
                // tags: transformAttributesByDemand(payload),
              })
            )
          );
        return dispatch(
          searchSquads(
            new SearchSquadsRequest({
              tags: {
                tags: payload,
              },
            })
          )
        );
      },
      [dispatch]
    ),
    searchSquadsPagination: useCallback(
      (payload) => {
        dispatch(
          searchSquadsPagination(
            new SearchSquadsRequest({
              created: payload,
            })
          )
        );
      },
      [dispatch]
    ),
  };
};
