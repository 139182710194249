import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import SearchProfile from '@components/SearchProfiles/SearchProfile';
import React from 'react';
import UserCard from '@components/AddMemberDialog/UserCard';
import { useProfiler } from '@store/profiler';
import SendInvitesButton from '@components/Invites/SendInvites';
import Box from '@mui/material/Box';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';
import LoadingButton from '@mui/lab/LoadingButton';
import Joi from 'joi';
import { Chip } from '@mui/material';
import { useSendInvite } from '@services/queries/ProfilesQueries';

type AddMemberProps = {
  isOpen: boolean;
  handleClose: () => void;
  entityId: string;
  entity: Entity_Type;
  objectName?: string;
};

export const SendInviteByEmailSchema = {
  email: Joi.string().required().min(3).max(100).messages({
    'string.empty': 'fill in the first name',
    'string.min': 'first name must be at least 3 characters long',
    'string.max': 'first name must be less than or equal to 30 characters long',
  }),
};

const AddMemberDialog = (props: AddMemberProps) => {
  const { isOpen, handleClose, entityId, entity, objectName } = props;
  const { sendInvitesByEmails, selectedProfile } = useProfiler();
  const [inviteType, setInviteType] = React.useState<'email' | 'user'>('user');
  const { mutate: sendInvites } = useSendInvite();
  const sendInvite = () => {
    if (selectedProfile && entity && entityId) {
      sendInvites({
        id: entityId,
        type: entity,
        profileIds: [selectedProfile?.profileId?.value as string],
      });
    }
  };

  const handleSubmit = () => {
    sendInvite();
    handleClose();
  };

  return (
    <Box>
      <Dialog onClose={handleClose} open={isOpen}>
        <Box display="flex" flexDirection="column" px={2} py={4} gap={2} sx={{ width: '600px' }}>
          <Typography textAlign="center" variant="subtitle1">
            Add member to {objectName}
          </Typography>
          <Box display="flex" justifyContent="center">
            {inviteType === 'user' ? (
              <Chip
                variant="outlined"
                onClick={() => setInviteType('email')}
                label="Send invitation by e-mail"
              />
            ) : (
              <Chip
                variant="outlined"
                onClick={() => setInviteType('user')}
                label="Find a user on our platform"
              />
            )}
          </Box>

          {inviteType === 'user' ? (
            <Box display="flex" flexDirection="column" gap={1}>
              <SearchProfile />
              {selectedProfile && <UserCard userProfile={selectedProfile} />}
              {selectedProfile ? <SendInvitesButton handleSubmit={handleSubmit} /> : null}
            </Box>
          ) : (
            <Form
              width="100%"
              display="flex"
              alignSelf="center"
              flexDirection="column"
              formProps={{ defaultValues: { email: '' } }}
              submitHandler={(data) => {
                sendInvitesByEmails({
                  id: entityId,
                  type: entity,
                  emails: [data.email],
                });
                handleClose();
              }}
              resolverSchema={SendInviteByEmailSchema}
            >
              <Box display="flex" flexDirection="column" gap={1}>
                <InputController name="email" label="Email" />
                <Box display="flex" justifyContent="center">
                  <LoadingButton type="submit" variant="contained">
                    Send Invite
                  </LoadingButton>
                </Box>
              </Box>
            </Form>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default AddMemberDialog;
