import { Form } from '@components/Form';
import Grid from '@mui/material/Grid';
import { InputController } from '@components/Form/controllers/InputController';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useOrgs } from '@store/orgs';
import SearchIcon from '@mui/icons-material/Search';

const ListOrgsForm = () => {
  const { searchOrgs } = useOrgs();

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: { name: '', industry_domain: [] },
      }}
      submitHandler={(data) => {
        searchOrgs(data);
      }}
    >
      <Grid container display="flex" mb={2}>
        <Grid item xs={10}>
          <Stack>
            <InputController name="name" label="Name" />
          </Stack>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="flex-end">
          <Stack>
            <Button type="submit" variant="outlined">
              <SearchIcon />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};
export default ListOrgsForm;
