// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file grpc/type/v1/entity.proto (package grpc.type.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUID, UUIDS } from './uuid_pb.js';

/**
 * @generated from message grpc.type.v1.Entity
 */
export class Entity extends Message<Entity> {
  /**
   * @generated from field: grpc.type.v1.UUID id = 1;
   */
  id?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity.Type type = 2;
   */
  type = Entity_Type.UNSPECIFIED;

  constructor(data?: PartialMessage<Entity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.Entity';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'message', T: UUID },
    { no: 2, name: 'type', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Entity {
    return new Entity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Entity {
    return new Entity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Entity {
    return new Entity().fromJsonString(jsonString, options);
  }

  static equals(
    a: Entity | PlainMessage<Entity> | undefined,
    b: Entity | PlainMessage<Entity> | undefined
  ): boolean {
    return proto3.util.equals(Entity, a, b);
  }
}

/**
 * @generated from enum grpc.type.v1.Entity.Type
 */
export enum Entity_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_USER = 1;
   */
  USER = 1,

  /**
   * @generated from enum value: TYPE_ORG = 2;
   */
  ORG = 2,

  /**
   * @generated from enum value: TYPE_SQUAD = 3;
   */
  SQUAD = 3,

  /**
   * @generated from enum value: TYPE_DYNAMIC_SQUAD = 4;
   */
  DYNAMIC_SQUAD = 4,

  /**
   * @generated from enum value: TYPE_DEMAND = 5;
   */
  DEMAND = 5,

  /**
   * @generated from enum value: TYPE_CONTRACT = 6;
   */
  CONTRACT = 6,

  /**
   * @generated from enum value: TYPE_INTERVIEW = 7;
   */
  INTERVIEW = 7,

  /**
   * @generated from enum value: TYPE_DASHBOARD = 8;
   */
  DASHBOARD = 8,

  /**
   * @generated from enum value: TYPE_PRODUCT = 9;
   */
  PRODUCT = 9,

  /**
   * @generated from enum value: TYPE_EVALUATION = 10;
   */
  EVALUATION = 10,

  /**
   * @generated from enum value: TYPE_ORDER = 11;
   */
  ORDER = 11,

  /**
   * @generated from enum value: TYPE_SUPPLY = 12;
   */
  SUPPLY = 12,

  /**
   * @generated from enum value: TYPE_EVALUATION_FEEDBACK = 13;
   */
  EVALUATION_FEEDBACK = 13,

  /**
   * @generated from enum value: TYPE_INVITE = 14;
   */
  INVITE = 14,

  /**
   * @generated from enum value: TYPE_IAM_ROLE = 15;
   */
  IAM_ROLE = 15,

  /**
   * @generated from enum value: TYPE_IAM_POLICY = 16;
   */
  IAM_POLICY = 16,

  /**
   * @generated from enum value: TYPE_TREASURY_INVOICE = 17;
   */
  TREASURY_INVOICE = 17,

  /**
   * @generated from enum value: TYPE_TREASURY_WALLET = 18;
   */
  TREASURY_WALLET = 18,

  /**
   * @generated from enum value: TYPE_EVALUATION_SLOT = 19;
   */
  EVALUATION_SLOT = 19,

  /**
   * @generated from enum value: TYPE_CHAT_GROUP = 20;
   */
  CHAT_GROUP = 20,

  /**
   * @generated from enum value: TYPE_AVATAR = 21;
   */
  AVATAR = 21,
}
// Retrieve enum metadata with: proto3.getEnumType(Entity_Type)
proto3.util.setEnumType(Entity_Type, 'grpc.type.v1.Entity.Type', [
  { no: 0, name: 'TYPE_UNSPECIFIED' },
  { no: 1, name: 'TYPE_USER' },
  { no: 2, name: 'TYPE_ORG' },
  { no: 3, name: 'TYPE_SQUAD' },
  { no: 4, name: 'TYPE_DYNAMIC_SQUAD' },
  { no: 5, name: 'TYPE_DEMAND' },
  { no: 6, name: 'TYPE_CONTRACT' },
  { no: 7, name: 'TYPE_INTERVIEW' },
  { no: 8, name: 'TYPE_DASHBOARD' },
  { no: 9, name: 'TYPE_PRODUCT' },
  { no: 10, name: 'TYPE_EVALUATION' },
  { no: 11, name: 'TYPE_ORDER' },
  { no: 12, name: 'TYPE_SUPPLY' },
  { no: 13, name: 'TYPE_EVALUATION_FEEDBACK' },
  { no: 14, name: 'TYPE_INVITE' },
  { no: 15, name: 'TYPE_IAM_ROLE' },
  { no: 16, name: 'TYPE_IAM_POLICY' },
  { no: 17, name: 'TYPE_TREASURY_INVOICE' },
  { no: 18, name: 'TYPE_TREASURY_WALLET' },
  { no: 19, name: 'TYPE_EVALUATION_SLOT' },
  { no: 20, name: 'TYPE_CHAT_GROUP' },
  { no: 21, name: 'TYPE_AVATAR' },
]);

/**
 * @generated from message grpc.type.v1.Entities
 */
export class Entities extends Message<Entities> {
  /**
   * @generated from field: repeated grpc.type.v1.Entity entities = 1;
   */
  entities: Entity[] = [];

  constructor(data?: PartialMessage<Entities>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.Entities';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entities', kind: 'message', T: Entity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Entities {
    return new Entities().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Entities {
    return new Entities().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Entities {
    return new Entities().fromJsonString(jsonString, options);
  }

  static equals(
    a: Entities | PlainMessage<Entities> | undefined,
    b: Entities | PlainMessage<Entities> | undefined
  ): boolean {
    return proto3.util.equals(Entities, a, b);
  }
}

/**
 * @generated from message grpc.type.v1.EntityIds
 */
export class EntityIds extends Message<EntityIds> {
  /**
   * @generated from field: grpc.type.v1.UUIDS ids = 1;
   */
  ids?: UUIDS;

  /**
   * @generated from field: grpc.type.v1.Entity.Type type = 2;
   */
  type = Entity_Type.UNSPECIFIED;

  constructor(data?: PartialMessage<EntityIds>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.EntityIds';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'message', T: UUIDS },
    { no: 2, name: 'type', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityIds {
    return new EntityIds().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityIds {
    return new EntityIds().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityIds {
    return new EntityIds().fromJsonString(jsonString, options);
  }

  static equals(
    a: EntityIds | PlainMessage<EntityIds> | undefined,
    b: EntityIds | PlainMessage<EntityIds> | undefined
  ): boolean {
    return proto3.util.equals(EntityIds, a, b);
  }
}
