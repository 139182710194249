import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from '@components/Form';
import { FIELD_LABELS, FieldNames } from '@pages/Evaluation/config';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';
import { useSaveEvaluationFeedbackDraft } from '@services/queries/MarketplaceQueries';
import { Evaluation } from '@proto/marketplace/demand/v1/evaluation_pb';

type SendEvaluationFeedBackFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  evaluationId: string;
  evaluation: Evaluation;
};

const SendEvaluationFeedBackForm = (props: SendEvaluationFeedBackFormProps) => {
  const { isOpen, handleClose, evaluationId, evaluation } = props;
  const { mutate: saveEvaluationFeedbackDraft } = useSaveEvaluationFeedbackDraft();

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box sx={{ width: { xs: '300px', sm: '400px', md: '600px' } }} py={5} px={{ xs: 3 }}>
        <Form
          width="100%"
          display="flex"
          alignSelf="center"
          flexDirection="column"
          formProps={{ defaultValues: { evaluationId, feedback: evaluation.feedback || '' } }}
          submitHandler={(data) => {
            saveEvaluationFeedbackDraft(data);
            handleClose();
          }}
          // resolverSchema={sendEvaluationFeedbackFormSchema}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography textAlign="center" variant="subtitle1">
              {FIELD_LABELS[FieldNames.FEEDBACK]}
            </Typography>
            <WysiwygController name={FieldNames.FEEDBACK} />
          </Box>

          <Stack>
            <LoadingButton type="submit" variant="contained">
              {evaluation.feedback ? 'Update' : 'Send'}
            </LoadingButton>
          </Stack>
        </Form>
      </Box>
    </Dialog>
  );
};

export default SendEvaluationFeedBackForm;
