import * as React from 'react';
import { Suspense } from 'react';
import { Box, Container } from '@mui/material';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import SidebarProfileSettings from '@pages/Main/SidebarProfileSettings/SidebarProfileSettings';
import FeedList from '@pages/Marketplace/Feed/FeedList';
import FeedByDemandsDialog from '@pages/Marketplace/Feed/FeedByDemand/FeedByDemandsDialog';
import Grid from '@mui/material/Grid';
import FiltersFeedItems from '@pages/Marketplace/Feed/FiltersFeedItems';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useFeed } from '../../../zustand/feed';

export const demandsTypes = {
  contract: Entity_Type.CONTRACT,
  interview: Entity_Type.INTERVIEW,
};

const FeedPage = () => {
  const { setIsPinnedReturn, isPinnedReturn } = useFeed();

  return (
    <Container>
      <Grid container sx={{ position: 'relative' }} spacing={2}>
        <Grid item xs={3} sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}>
          <Box
            sx={{
              position: 'fixed',
              left: 'auto',
              right: 'auto',
              width: '320px',
              height: '100vh',
              overflowY: 'auto',
            }}
          >
            <SidebarProfileSettings />
          </Box>
        </Grid>

        <Grid item xs={12} md={8} className="relative">
          <Box mb={2} className="fixed pt-6 bg-[#04020F] top-10 z-50 w-full ">
            <Box display="flex" mb={1} gap={1}>
              <EventAvailableIcon />
              <Typography variant="subtitle1">Check what’s in demand today</Typography>
            </Box>
            <Box className="flex items-center gap-4 ">
              <FiltersFeedItems />
              <Box className="flex items-center">
                Show pinned
                <Checkbox
                  onChange={() => setIsPinnedReturn(!isPinnedReturn)}
                  value={isPinnedReturn}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: '100%' }} display="flex" gap={1} pt={3}>
            <Box width="800px">
              <FeedList />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Suspense fallback={<div>Loading...</div>}>
        <FeedByDemandsDialog />
      </Suspense>
    </Container>
  );
};

export default FeedPage;
