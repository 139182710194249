/* eslint-disable-next-line eslint-comments/disable-enable-pair */
/* eslint-disable react/jsx-props-no-spreading */
import { PropsWithChildren } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import Joi, { StrictSchemaMap } from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

type ConnectFromProps = {
  children: (props: UseFormReturn) => JSX.Element;
};

const ConnectForm = (props: ConnectFromProps) => {
  const { children } = props;

  const formContext = useFormContext();

  return children({ ...formContext });
};

type TFormProps<FieldValues> = PropsWithChildren<
  {
    formProps?: UseFormProps<FieldValues>;
    resolverSchema?: StrictSchemaMap<FieldValues>;
    submitHandler: SubmitHandler<FieldValues>;
  } & BoxProps
>;

export const Form: <FieldValues>(props: TFormProps<FieldValues>) => JSX.Element = (props) => {
  const { formProps, resolverSchema, submitHandler, children, ...restBoxProps } = props;

  const formContext = useForm({ ...formProps, resolver: joiResolver(Joi.object(resolverSchema)) });
  return (
    <FormProvider {...formContext}>
      <Box
        width="100%"
        {...restBoxProps}
        component="form"
        onSubmit={formContext.handleSubmit(submitHandler)}
      >
        {children}
      </Box>
    </FormProvider>
  );
};
