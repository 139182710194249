import { PlainMsg } from '@services/api/helpers';
import { PinnedItem } from '@proto/profiler/user/v1/profile_pb';

export type TPinnedItem = PlainMsg<PinnedItem>;

export enum PinItemType {
  USER = 'TYPE_OF_ENTITY_USER',
  ORG = 'TYPE_OF_ENTITY_ORG',
  SQUAD = 'TYPE_OF_ENTITY_SQUAD',
  DYNAMIC_SQUAD = 'TYPE_OF_ENTITY_DYNAMIC_SQUAD',
  DEMAND = 'TYPE_OF_ENTITY_DEMAND',
  CONTRACT = 'TYPE_OF_ENTITY_CONTRACT',
  INTERVIEW = 'TYPE_OF_ENTITY_INTERVIEW',
}

export interface TransformedPinnedItems {
  [type: string]: { [id: string]: TPinnedItem };
}

export const transformPinItems = (items: TPinnedItem[]): TransformedPinnedItems => {
  return items.reduce((acc: TransformedPinnedItems, currentItem: TPinnedItem) => {
    const { entity } = currentItem;

    if (entity) {
      if (!(entity.type in acc)) {
        acc[entity.type] = {};
      }

      acc[entity.type][`${entity?.id?.value}`] = currentItem;
      return acc;
    }
    return acc;
  }, {});
};
