import Dialog from '@mui/material/Dialog';
import React from 'react';
import Box from '@mui/material/Box';
import Chat from './Chat';
import { useStreamStore } from '../../zustand/subscribe';

type AddMemberProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const ChatDialog = (props: AddMemberProps) => {
  const { isOpen, handleClose } = props;
  const { groups } = useStreamStore();
  return (
    <Box>
      <Dialog onClose={handleClose} open={isOpen}>
        {groups && <Chat groups={groups} />}
      </Dialog>
    </Box>
  );
};

export default ChatDialog;
