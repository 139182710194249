import { getConnectClient } from '@services/api/helpers';
import { MFAApi } from '@proto/api/iam/v1/mfa_connect';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ActivateTotpResponse } from '@proto/iam/auth/v1/mfa_pb';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';
import { useMfaState } from '../../zustand/mfa';

export const mfaClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  MFAApi
);

export const useGenerateTotp = (enabled: boolean) => {
  const generateTotp = async () => {
    return mfaClient.generateTotp({});
  };

  return useQuery({
    queryKey: ['generateTotp'],
    queryFn: generateTotp,
    enabled,
  });
};

export const useValidateTotp = () => {
  const validateTotp = async (payload: { passCode: string }) => {
    return mfaClient.validateTotp({ passCode: payload.passCode });
  };

  return useMutation({
    mutationFn: validateTotp,
  });
};

export const useRecoverTotp = () => {
  const recoverTotp = async (payload: { recoveryCode: string }) => {
    return mfaClient.recoverTotp({
      recoveryCode: payload.recoveryCode,
    });
  };

  return useMutation({
    mutationFn: recoverTotp,
  });
};

export const useActivateOtp = () => {
  const { addRecoveryCode } = useMfaState();
  const dispatch = useAppDispatch();
  const { setMfaInputError } = useMfaState();

  const activateTotp = async (payload: { passCode: string }): Promise<ActivateTotpResponse> => {
    return mfaClient.activateTotp({ passCode: payload.passCode });
  };

  return useMutation({
    mutationFn: activateTotp,
    onSuccess: (data) => {
      dispatch(
        showSnackbar({
          id: 'activateTotp',
          message: 'OTP activated successfully',
          severity: 'success',
        })
      );
      addRecoveryCode(data.recoveryCode);
    },
    onError: (error) => {
      setMfaInputError(true);
      dispatch(
        showSnackbar({
          id: 'activateTotp',
          message: error.message,
          severity: 'error',
        })
      );
    },
  });
};
