// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file grpc/type/v1/status.proto (package grpc.type.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from '@bufbuild/protobuf';

/**
 * @generated from enum grpc.type.v1.Status
 */
export enum Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATUS_TRUE = 1;
   */
  TRUE = 1,

  /**
   * @generated from enum value: STATUS_FALSE = 2;
   */
  FALSE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Status)
proto3.util.setEnumType(Status, 'grpc.type.v1.Status', [
  { no: 0, name: 'STATUS_UNSPECIFIED' },
  { no: 1, name: 'STATUS_TRUE' },
  { no: 2, name: 'STATUS_FALSE' },
]);
