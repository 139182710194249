import Box from '@mui/material/Box';
import { useDialog } from '@components/hooks/useDialog';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import CreateDemandForm from '@pages/Marketplace/ManageDemands/CreateDemandForm';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';

const CreateDemandButton = () => {
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { dashboardId } = useParams();

  return (
    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
      <Tooltip placement="right-start" title="Create demand">
        <AddIcon cursor="pointer" onClick={handleOpen} />
      </Tooltip>
      <Suspense fallback={null}>
        {dashboardId && (
          <CreateDemandForm dashboardId={dashboardId} handleClose={handleClose} isOpen={isOpen} />
        )}
      </Suspense>
    </Box>
  );
};

export default CreateDemandButton;
