import { TOrg, TSquad } from '@services/api/profiler/biz';

export const findOldestDateInSquad = (objectsArray: TSquad[]) => {
  if (objectsArray.length === 0) {
    return new Date();
  }

  return objectsArray.reduce((oldest, current) => {
    if (current.created === undefined) return oldest;

    const currentDate = new Date(current.created.toString());

    return currentDate < oldest ? currentDate : oldest;
  }, new Date());
};
export const findOldestDateInOrgs = (objectsArray: TOrg[]) => {
  if (objectsArray.length === 0) {
    return new Date();
  }

  return objectsArray.reduce((oldest, current) => {
    if (current.created === undefined) return oldest;
    const currentDate = new Date(current.created.toString());

    return currentDate < oldest ? currentDate : oldest;
  }, new Date());
};
