// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file api/notificator/v1/events.proto (package api.notificator.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteRequest, DeleteResponse } from '../../../notificator/stream/v1/events_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.notificator.v1.EventsAPI
 */
export const EventsAPI = {
  typeName: 'api.notificator.v1.EventsAPI',
  methods: {
    /**
     * @generated from rpc api.notificator.v1.EventsAPI.Delete
     */
    delete: {
      name: 'Delete',
      I: DeleteRequest,
      O: DeleteResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
