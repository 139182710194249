import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Chip, Tooltip } from '@mui/material';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import { useQueryClient } from '@tanstack/react-query';

const SidebarTags = () => {
  const [isEdited, setIsEdited] = useState(true);
  const { data: userProfile } = useGetMyProfile();
  const queryClient = useQueryClient();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" flexDirection="column" justifyContent="flex-end">
        {!isEdited && (
          <LoadingButton
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['myProfile'] });
              setIsEdited(!isEdited);
            }}
          >
            <CheckIcon sx={{ width: '18px', height: '18px' }} />
          </LoadingButton>
        )}
      </Box>
      {isEdited ? (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={1} flexWrap="wrap">
            {userProfile?.profile?.tags?.tags?.map((tag) => {
              return (
                <Chip
                  sx={{ fontSize: '12px' }}
                  variant={tag.active ? 'outlined' : 'filled'}
                  key={tag.value}
                  label={tag.value}
                />
              );
            })}
          </Box>
          <Box>
            <Tooltip title="Edit tags" placement="top-start">
              <LoadingButton onClick={() => setIsEdited(!isEdited)}>
                <EditIcon sx={{ width: '18px', height: '18px' }} />
              </LoadingButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <ProfileTags />
      )}
    </Box>
  );
};

export default SidebarTags;
