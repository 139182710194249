import { PropsWithChildren, ReactPortal, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type TPortalProps = {
  targetNode?: HTMLElement;
} & PropsWithChildren;

export const Portal = (props: TPortalProps): ReactPortal => {
  const { targetNode = document.body, children } = props;

  const element = useRef<HTMLDivElement>(document.createElement('div'));
  element.current.classList.add('Portal');

  useEffect(() => {
    const { current } = element;

    targetNode.appendChild(current);

    return () => {
      targetNode.removeChild(current);
    };
  }, [targetNode]);

  // @ts-ignore
  return createPortal(children, element.current);
};
