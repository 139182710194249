// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file iam/auth/v1/mfa.proto (package iam.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message iam.auth.v1.GenerateTotpRequest
 */
export class GenerateTotpRequest extends Message<GenerateTotpRequest> {
  constructor(data?: PartialMessage<GenerateTotpRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.GenerateTotpRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateTotpRequest {
    return new GenerateTotpRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateTotpRequest {
    return new GenerateTotpRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GenerateTotpRequest {
    return new GenerateTotpRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GenerateTotpRequest | PlainMessage<GenerateTotpRequest> | undefined,
    b: GenerateTotpRequest | PlainMessage<GenerateTotpRequest> | undefined
  ): boolean {
    return proto3.util.equals(GenerateTotpRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.GenerateTotpResponse
 */
export class GenerateTotpResponse extends Message<GenerateTotpResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = '';

  constructor(data?: PartialMessage<GenerateTotpResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.GenerateTotpResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateTotpResponse {
    return new GenerateTotpResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateTotpResponse {
    return new GenerateTotpResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GenerateTotpResponse {
    return new GenerateTotpResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GenerateTotpResponse | PlainMessage<GenerateTotpResponse> | undefined,
    b: GenerateTotpResponse | PlainMessage<GenerateTotpResponse> | undefined
  ): boolean {
    return proto3.util.equals(GenerateTotpResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ValidateTotpRequest
 */
export class ValidateTotpRequest extends Message<ValidateTotpRequest> {
  /**
   * @generated from field: string pass_code = 1;
   */
  passCode = '';

  constructor(data?: PartialMessage<ValidateTotpRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ValidateTotpRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pass_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateTotpRequest {
    return new ValidateTotpRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateTotpRequest {
    return new ValidateTotpRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ValidateTotpRequest {
    return new ValidateTotpRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ValidateTotpRequest | PlainMessage<ValidateTotpRequest> | undefined,
    b: ValidateTotpRequest | PlainMessage<ValidateTotpRequest> | undefined
  ): boolean {
    return proto3.util.equals(ValidateTotpRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ValidateTotpResponse
 */
export class ValidateTotpResponse extends Message<ValidateTotpResponse> {
  constructor(data?: PartialMessage<ValidateTotpResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ValidateTotpResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateTotpResponse {
    return new ValidateTotpResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateTotpResponse {
    return new ValidateTotpResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ValidateTotpResponse {
    return new ValidateTotpResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ValidateTotpResponse | PlainMessage<ValidateTotpResponse> | undefined,
    b: ValidateTotpResponse | PlainMessage<ValidateTotpResponse> | undefined
  ): boolean {
    return proto3.util.equals(ValidateTotpResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RecoverTotpRequest
 */
export class RecoverTotpRequest extends Message<RecoverTotpRequest> {
  /**
   * @generated from field: string recovery_code = 1;
   */
  recoveryCode = '';

  constructor(data?: PartialMessage<RecoverTotpRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RecoverTotpRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'recovery_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecoverTotpRequest {
    return new RecoverTotpRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecoverTotpRequest {
    return new RecoverTotpRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RecoverTotpRequest {
    return new RecoverTotpRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RecoverTotpRequest | PlainMessage<RecoverTotpRequest> | undefined,
    b: RecoverTotpRequest | PlainMessage<RecoverTotpRequest> | undefined
  ): boolean {
    return proto3.util.equals(RecoverTotpRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RecoverTotpResponse
 */
export class RecoverTotpResponse extends Message<RecoverTotpResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = '';

  constructor(data?: PartialMessage<RecoverTotpResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RecoverTotpResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecoverTotpResponse {
    return new RecoverTotpResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecoverTotpResponse {
    return new RecoverTotpResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RecoverTotpResponse {
    return new RecoverTotpResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RecoverTotpResponse | PlainMessage<RecoverTotpResponse> | undefined,
    b: RecoverTotpResponse | PlainMessage<RecoverTotpResponse> | undefined
  ): boolean {
    return proto3.util.equals(RecoverTotpResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ActivateTotpRequest
 */
export class ActivateTotpRequest extends Message<ActivateTotpRequest> {
  /**
   * @generated from field: string pass_code = 1;
   */
  passCode = '';

  constructor(data?: PartialMessage<ActivateTotpRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ActivateTotpRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pass_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivateTotpRequest {
    return new ActivateTotpRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivateTotpRequest {
    return new ActivateTotpRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ActivateTotpRequest {
    return new ActivateTotpRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ActivateTotpRequest | PlainMessage<ActivateTotpRequest> | undefined,
    b: ActivateTotpRequest | PlainMessage<ActivateTotpRequest> | undefined
  ): boolean {
    return proto3.util.equals(ActivateTotpRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ActivateTotpResponse
 */
export class ActivateTotpResponse extends Message<ActivateTotpResponse> {
  /**
   * @generated from field: string recovery_code = 1;
   */
  recoveryCode = '';

  constructor(data?: PartialMessage<ActivateTotpResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ActivateTotpResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'recovery_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivateTotpResponse {
    return new ActivateTotpResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivateTotpResponse {
    return new ActivateTotpResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ActivateTotpResponse {
    return new ActivateTotpResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ActivateTotpResponse | PlainMessage<ActivateTotpResponse> | undefined,
    b: ActivateTotpResponse | PlainMessage<ActivateTotpResponse> | undefined
  ): boolean {
    return proto3.util.equals(ActivateTotpResponse, a, b);
  }
}
