export function createSelectOptions<T>(
  enumObj: T,
  excludeValues: number[] = []
): {
  value: T[keyof T];
  label: string;
}[] {
  return Object.keys(enumObj)
    .filter((key) => {
      const value = Number(enumObj[key as keyof T]);
      return !Number.isNaN(value) && !excludeValues.includes(value);
    })
    .map((key) => ({
      value: enumObj[key as keyof T],
      label: key.replace('_', ' ').toLowerCase(),
    }));
}
