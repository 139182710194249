import React from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardContainer } from '@pages/Main/DashboardContainer';
import Box from '@mui/material/Box';

export const MainDashboard: React.FC = () => {
  return (
    <Box height="100%">
      <DashboardContainer>
        <Outlet />
      </DashboardContainer>
    </Box>
  );
};
