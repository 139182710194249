import Box from '@mui/material/Box';

type CardProps = {
  children: React.ReactNode;
};
const Card = (props: CardProps) => {
  const { children } = props;

  return (
    <Box
      position="relative"
      py={1}
      px={1}
      sx={{ border: '1px solid rgba(255, 255, 255, 0.12)', borderRadius: '5px', width: '100%' }}
    >
      {children}
    </Box>
  );
};
export default Card;
