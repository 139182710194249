import { Box, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Timestamp } from '@bufbuild/protobuf';
import CloseIcon from '@mui/icons-material/Close';
import { ExtendedMessage, useStreamStore } from '../../zustand/subscribe';
import CustomAvatar from '../Avatar/Avatar';
import { UUID } from '../../proto/grpc/type/v1/uuid_pb';
import { useEditMessage, useRemoveMessage } from '../../services/queries/Chat';
import { useGetMyProfile } from '../../services/queries/ProfilesQueries';
import ChatInput from './SendMessageInput';
import { isInvalidTimestamp } from '../../utils/invalidTimestamp';

function getTimeFromTimestamp(timestamp: Timestamp): string {
  if (!timestamp || typeof timestamp.seconds !== 'bigint' || typeof timestamp.nanos !== 'number') {
    return 'Invalid timestamp';
  }
  const date = new Date(Number(timestamp.seconds) * 1000);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

interface MessageProps {
  message: ExtendedMessage;
  listProfiles: any;
  sessionToken: string;
  groupId: string;
}

const Message = ({ message, listProfiles, sessionToken, groupId }: MessageProps) => {
  const { data: userProfile } = useGetMyProfile();
  const { mutate: removeMessage } = useRemoveMessage();
  const { mutate: editMessage } = useEditMessage();
  const { removeMessageFromGroup } = useStreamStore();
  const [editMode, setEditMode] = useState(false);
  const { updateMessageTextInGroup } = useStreamStore();

  const handleSendMessage = (messagePayload: string) => {
    setEditMode(false);
    updateMessageTextInGroup(groupId, message?.messageId?.value as string, messagePayload);
    editMessage({
      text: messagePayload,
      messageId: message?.messageId as UUID,
      token: sessionToken,
    });
  };

  const handleRemoveMessage = () => {
    removeMessage({ messageId: message?.messageId as UUID, token: sessionToken });
    removeMessageFromGroup(groupId, message?.messageId?.value as string);
  };

  return (
    <Box
      className={`relative flex gap-1 w-full text-[white] py-2 transition-all rounded  ${
        userProfile?.profile?.profileId?.value === message.profileId?.value
          ? 'group hover:bg-[#757575]'
          : ''
      }`}
    >
      <Box className="py-2 px-1 rounded w-full">
        <Box className="flex gap-2">
          <CustomAvatar
            height={30}
            width={30}
            alt={listProfiles[message?.profileId?.value as string]?.name?.nickName || 'User'}
            avatarId={listProfiles[message?.profileId?.value as string]?.avatar}
            profileId={message?.profileId?.value}
          />
          <Box className="flex items-center gap-2">
            <Typography className="font-bold">
              {listProfiles[message?.profileId?.value as string]?.name?.nickName}
            </Typography>
            <Typography className="text-[#bbdefb]">
              {message.created && getTimeFromTimestamp(message.created)}
            </Typography>
            <Typography className="text-[#bbdefb]">
              {isInvalidTimestamp(message?.updated?.toJsonString()) && 'Edited'}
            </Typography>
          </Box>
        </Box>

        {!editMode ? (
          <Box className="pl-[38px] ">{message.text}</Box>
        ) : (
          <ChatInput onSendMessage={handleSendMessage} initialMessage={message.text} />
        )}
      </Box>
      <Box className="gap-2 absolute top-[5px] transition-all right-2 hidden group-hover:flex ">
        {!editMode ? (
          <Box className="flex gap-2">
            <Tooltip placement="top" title="Edit">
              <EditIcon
                onClick={() => {
                  setEditMode(!editMode);
                }}
                className="cursor-pointer p-1 rounded bg-[#90caf9] text-[black]"
              />
            </Tooltip>

            <Tooltip placement="top" title="Delete">
              <DeleteIcon
                onClick={handleRemoveMessage}
                className="cursor-pointer p-1 rounded bg-[#90caf9] text-[black]"
              />
            </Tooltip>
          </Box>
        ) : (
          <Box>
            <Tooltip placement="top" title="Cancel editing">
              <CloseIcon
                onClick={() => {
                  setEditMode(!editMode);
                }}
                className="cursor-pointer p-1 rounded bg-[#90caf9] text-[black]"
              />
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Message;
