// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file api/iam/v1/iam.proto (package api.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUID } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message api.iam.v1.CreateRoleRequest
 */
export class CreateRoleRequest extends Message<CreateRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 1;
   */
  domainId?: UUID;

  /**
   * @generated from field: string name = 2;
   */
  name = '';

  constructor(data?: PartialMessage<CreateRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.iam.v1.CreateRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'message', T: UUID },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateRoleRequest | PlainMessage<CreateRoleRequest> | undefined,
    b: CreateRoleRequest | PlainMessage<CreateRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateRoleRequest, a, b);
  }
}

/**
 * @generated from message api.iam.v1.AddUserToRoleRequest
 */
export class AddUserToRoleRequest extends Message<AddUserToRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID role_id = 2;
   */
  roleId?: UUID;

  constructor(data?: PartialMessage<AddUserToRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.iam.v1.AddUserToRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'role_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddUserToRoleRequest {
    return new AddUserToRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddUserToRoleRequest {
    return new AddUserToRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddUserToRoleRequest {
    return new AddUserToRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddUserToRoleRequest | PlainMessage<AddUserToRoleRequest> | undefined,
    b: AddUserToRoleRequest | PlainMessage<AddUserToRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddUserToRoleRequest, a, b);
  }
}
