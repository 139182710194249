export type JWTTokens = {
  accessToken: string;
  refreshToken: string;
};

const JWTStorage = {
  saveJWTTokens: ({ accessToken, refreshToken }: JWTTokens): void => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  },
  getJWTTokens: (): JWTTokens | null => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!accessToken || !refreshToken) {
      return null;
    }
    return { accessToken, refreshToken };
  },
  removeJWTTokens: (): void => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  },
};

export const JWT = JWTStorage;
