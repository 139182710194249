import { useAlert } from '@store/alerts';
import { lazyComponent } from '@common/utils';

import { Portal } from '@components/Portal/Portal';

const AlertComponent = lazyComponent('Alert', import('@components/Alert/Alert'));

export const AlertManager = () => {
  const { alerts, hideAlert } = useAlert();

  return (
    <Portal>
      {/* <Stack spacing={1} width={400}> */}
      {/*  {alerts.map((alert) => ( */}
      {/*    <Suspense fallback={null} key={alert.id}> */}
      {/*      <AlertComponent */}
      {/*        severity={alert.type} */}
      {/*        description={alert.description} */}
      {/*        title={alert.title} */}
      {/*        hideAction={() => hideAlert(alert.id)} */}
      {/*      /> */}
      {/*    </Suspense> */}
      {/*  ))} */}
      {/* </Stack> */}
    </Portal>
  );
};
