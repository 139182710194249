import { getConnectClient, makeRequest, PlainMsg } from '@services/api/helpers';

import {
  CreateSquadRequest,
  CreateSquadResponse,
  GetSquadRequest,
  GetSquadResponse,
  ListSquadsRequest,
  ListSquadsResponse,
  SearchSquadsRequest,
  SearchSquadsResponse,
  SetSquadStatusRequest,
  SetSquadStatusResponse,
  Squad,
  UpdateSquadInfoRequest,
  UpdateSquadInfoResponse,
} from '@proto/profiler/biz/v1/squad_pb';

import {
  CreateOrgRequest,
  CreateOrgResponse,
  ListOrgsRequest,
  ListOrgsResponse,
  Org,
  SearchOrgsRequest,
  SearchOrgsResponse,
  SetOrgStatusRequest,
  SetOrgStatusResponse,
  UpdateOrgInfoRequest,
  UpdateOrgInfoResponse,
} from '@proto/profiler/biz/v1/org_pb';

import {
  InviteActionRequest,
  InviteActionResponse,
  ListInvitesRequest,
  ListInvitesResponse,
  ListUserInvitesRequest,
  ListUserInvitesResponse,
  SendInvitesRequest,
  SendInvitesResponse,
} from '@proto/profiler/invite/v1/invite_pb';
import { TTag } from '@services/api/profiler/tags';
import { SquadAPI } from '@proto/api/profiler/v1/squad_connect';
import { OrgAPI } from '@proto/api/profiler/v1/org_connect';
import { InviteAPI } from '@proto/api/profiler/v1/invite_connect';
import { Profile } from '@proto/profiler/user/v1/profile_pb';

export type GetSquadServiceRequest = PlainMsg<GetSquadRequest>;
export type GetSquadServiceResponse = PlainMsg<GetSquadResponse>;
export type ListSquadsServiceRequest = PlainMsg<ListSquadsRequest>;
export type ListSquadsServiceResponse = PlainMsg<ListSquadsResponse>;
export type CreateSquadsServiceResponse = PlainMsg<CreateSquadResponse>;
export type CreateSquadServiceRequest = PlainMsg<CreateSquadRequest>;
export type SetSquadStatusServiceRequest = PlainMsg<SetSquadStatusRequest>;
export type SetSquadStatusServiceResponse = PlainMsg<SetSquadStatusResponse>;
export type SearchSquadsServiceRequest = PlainMsg<SearchSquadsRequest>;
export type SearchSquadsServiceResponse = PlainMsg<SearchSquadsResponse>;
export type UpdateSquadInfoServiceRequest = PlainMsg<UpdateSquadInfoRequest>;
export type UpdateSquadInfoServiceResponse = PlainMsg<UpdateSquadInfoResponse>;

export type CreateOrgServiceRequest = PlainMsg<CreateOrgRequest>;
export type CreateOrgServiceResponse = PlainMsg<CreateOrgResponse>;
export type SetOrgStatusServiceRequest = PlainMsg<SetOrgStatusRequest>;
export type SetOrgStatusServiceResponse = PlainMsg<SetOrgStatusResponse>;
export type SearchOrgsServiceRequest = PlainMsg<SearchOrgsRequest>;
export type SearchOrgsServiceResponse = PlainMsg<SearchOrgsResponse>;
export type ListOrgsServiceRequest = PlainMsg<ListOrgsRequest>;
export type ListOrgsServiceResponse = PlainMsg<ListOrgsResponse>;
export type UpdateOrgInfoServiceRequest = PlainMsg<UpdateOrgInfoRequest>;
export type UpdateOrgInfoServiceResponse = PlainMsg<UpdateOrgInfoResponse>;

export type ListInvitesServiceRequest = PlainMsg<ListInvitesRequest>;
export type ListInvitesServiceResponse = PlainMsg<ListInvitesResponse>;
export type SendInviteServiceRequest = PlainMsg<SendInvitesRequest>;
export type SendInviteServiceResponse = PlainMsg<SendInvitesResponse>;
export type InviteActionServiceRequest = PlainMsg<InviteActionRequest>;
export type InviteActionServiceResponse = PlainMsg<InviteActionResponse>;
export type ListUserInvitesServiceResponse = PlainMsg<ListUserInvitesResponse>;

export type TSquad = PlainMsg<Squad> & {
  count: number;
  profiles: { profiles: Profile[] };
  title: string;
  tags: {
    tags: TTag[];
  };
};
export type TOrg = PlainMsg<Org>;

const squadClient = getConnectClient<typeof SquadAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  SquadAPI
);
const orgClient = getConnectClient<typeof OrgAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  OrgAPI
);
export const inviteClient = getConnectClient<typeof InviteAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  InviteAPI
);

export const bizService = {
  sendInvites(request: SendInviteServiceRequest): Promise<SendInviteServiceResponse> {
    return makeRequest<SendInvitesRequest, SendInvitesResponse>(inviteClient.sendInvites, request);
  },
  inviteAction(request: InviteActionServiceRequest): Promise<InviteActionServiceResponse> {
    return makeRequest<InviteActionRequest, InviteActionResponse>(
      inviteClient.inviteAction,
      request
    );
  },
  listUserInvites(): Promise<ListUserInvitesServiceResponse> {
    return makeRequest<ListUserInvitesRequest, ListUserInvitesResponse>(
      inviteClient.listUserInvites,
      new ListUserInvitesRequest()
    );
  },

  listInvites(request: ListInvitesServiceRequest): Promise<ListInvitesServiceResponse> {
    return makeRequest<ListInvitesRequest, ListInvitesResponse>(inviteClient.listInvites, request);
  },
  createSquad(request: CreateSquadServiceRequest): Promise<CreateSquadsServiceResponse> {
    return makeRequest<CreateSquadRequest, CreateSquadResponse>(squadClient.createSquad, request);
  },

  searchSquads(request: SearchSquadsServiceRequest): Promise<SearchSquadsServiceResponse> {
    return makeRequest<SearchSquadsRequest, SearchSquadsResponse>(
      squadClient.searchSquads,
      request
    );
  },
  getSquad(request: GetSquadServiceRequest): Promise<GetSquadServiceResponse> {
    return makeRequest<GetSquadRequest, GetSquadResponse>(squadClient.getSquad, request);
  },
  listSquads(request: ListSquadsServiceRequest): Promise<ListSquadsServiceResponse> {
    return makeRequest<ListSquadsRequest, ListSquadsResponse>(squadClient.listSquads, request);
  },
  createOrg(request: CreateOrgServiceRequest): Promise<CreateOrgServiceResponse> {
    return makeRequest<CreateOrgRequest, CreateOrgResponse>(orgClient.createOrg, request);
  },
  setOrgStatus(request: SetOrgStatusServiceRequest): Promise<SetOrgStatusServiceResponse> {
    return makeRequest<SetOrgStatusRequest, SetOrgStatusResponse>(orgClient.setOrgStatus, request);
  },
  listOrgs(request: ListOrgsServiceRequest): Promise<ListOrgsServiceResponse> {
    return makeRequest<ListOrgsRequest, ListOrgsResponse>(orgClient.listOrgs, request);
  },
  searchOrgs(request: SearchOrgsServiceRequest): Promise<SearchOrgsServiceResponse> {
    return makeRequest<SearchOrgsRequest, SearchOrgsResponse>(orgClient.searchOrgs, request);
  },
  updateOrgInfo(request: UpdateOrgInfoServiceRequest): Promise<UpdateOrgInfoServiceResponse> {
    return makeRequest<UpdateOrgInfoRequest, UpdateOrgInfoResponse>(
      orgClient.updateOrgInfo,
      request
    );
  },
  updateSquadInfo(request: UpdateSquadInfoServiceRequest): Promise<UpdateSquadInfoServiceResponse> {
    return makeRequest<UpdateSquadInfoRequest, UpdateSquadInfoResponse>(
      squadClient.updateSquadInfo,
      request
    );
  },
};
