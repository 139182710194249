import { useCookie } from '@components/hooks/useCookie';
import { lazyComponent } from '@common/utils';
import { TCookiesBannerProps } from '@components/CookiesBanner/types';

const LazyCookiesBanner = lazyComponent<TCookiesBannerProps>(
  'LazyCookiesBanner',
  import('./LazyCookiesBanner')
);

export const CookiesBanner = () => {
  const [isCookiesAllowed, setIsCookiesAllowed] = useCookie();

  if (isCookiesAllowed) return null;

  return <LazyCookiesBanner allowCookies={setIsCookiesAllowed} />;
};
