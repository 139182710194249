import React from 'react';
import { FloatingDock } from '@components/ui/floating-dock';
import { ROUTES } from '@common/routes';
import HomeIcon from '@mui/icons-material/Home';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupsIcon from '@mui/icons-material/Groups';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import StoreIcon from '@mui/icons-material/Store';

export const NavBar = () => {
  const links = [
    {
      title: 'Main',
      icon: <HomeIcon className="h-full w-full text-neutral-500 dark:text-neutral-300" />,
      href: ROUTES.HOME,
    },

    {
      title: 'Feed',
      icon: <DynamicFeedIcon className="h-full w-full text-neutral-500 dark:text-neutral-300" />,
      href: ROUTES.FEED_LIST,
    },
    {
      title: 'Organizations',
      icon: <GroupsIcon className="h-full w-full text-neutral-500 dark:text-neutral-300" />,
      href: ROUTES.ORGS_LIST,
    },
    {
      title: 'Squads',
      icon: <Diversity3Icon className="h-full w-full text-neutral-500 dark:text-neutral-300" />,
      href: ROUTES.SQUADS_LIST,
    },
    {
      title: 'Marketplace',
      icon: <StoreIcon className="h-full w-full text-neutral-500 dark:text-neutral-300" />,
      href: ROUTES.MARKETPLACE,
    },
  ];
  return (
    <div className="flex items-center justify-center  w-full">
      <FloatingDock
        mobileClassName="translate-y-20" // only for demo, remove for production
        items={links}
      />
    </div>
  );
};
