import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import { useRemoveMember } from '@services/queries/MembershipsQueries';
import { RemoveMemberRequest } from '@proto/iam/membership/v1/membership_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import DeleteIcon from '@mui/icons-material/Delete';

type CreateGroupingDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  member?: any | undefined;
  entityId: string;
};

const ManageMemberDialog = (props: CreateGroupingDialogProps) => {
  const { isOpen, handleClose, member, entityId } = props;
  const { mutate } = useRemoveMember();
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirm = () => {
    mutate(
      new RemoveMemberRequest({
        entityId: new UUID({ value: entityId }),
        profileId: member.profileId,
      })
    );
    handleConfirmClose();
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box sx={{ width: { xs: '300px', sm: '400px', md: '600px' } }} py={5} px={{ xs: 3 }}>
        <Typography textAlign="center" mb={5} variant="subtitle1">
          Configure
        </Typography>
        <DeleteIcon onClick={handleConfirmOpen} />
        <ConfirmDialog
          isOpen={isConfirmOpen}
          onClose={handleConfirmClose}
          onConfirm={handleConfirm}
          title="Action confirmation"
          message="Are you sure you want to continue?"
        />
      </Box>
    </Dialog>
  );
};

export default ManageMemberDialog;
