// Оптимізована версія компонента ContractorSelect для зменшення кількості ререндерів
import { useMarketplace } from '@store/marketplace';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useMemo, useState } from 'react';
import { MatchedContracts } from '@proto/marketplace/demand/v1/contractor_pb';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { useListUserSquads } from '@services/queries/SquadsQueries';
import { useListUserOrgs } from '@services/queries/OrgsQueries';
import { useListMembers } from '@services/queries/MembershipsQueries';
import {
  ListMembersRequest,
  ListMembersResponse_Membership,
} from '@proto/iam/membership/v1/membership_pb';
import { Profile } from '@proto/profiler/user/v1/profile_pb';
import { MenuItem } from '@mui/material';

const ContractorSelect = ({ contractId }: { contractId?: string }) => {
  const { currentContractorId, setContractors, contractors } = useMarketplace();
  const [userContractorrId, setUserContractorId] = useState<string | undefined>(undefined);
  const [showFirstButton, setShowFirstButton] = useState(true);
  const { data: squadsList } = useListUserSquads();
  const { data: orgsList } = useListUserOrgs();

  const squadsEntityIds = useMemo(
    () => squadsList?.squads?.squads?.map((squad) => squad?.squadId?.value || ''),
    [squadsList]
  );
  const orgsEntityIds = useMemo(
    () => orgsList?.orgs?.orgs?.map((org) => org?.orgId?.value || ''),
    [orgsList]
  );

  const { data: squadMembers } = useListMembers(
    new ListMembersRequest({
      by: {
        case: 'entityIds',
        value: new UUIDS({ values: squadsEntityIds }),
      },
    }),
    !!squadsEntityIds?.length
  );

  const { data: orgMembers } = useListMembers(
    new ListMembersRequest({
      by: {
        case: 'entityIds',
        value: new UUIDS({ values: orgsEntityIds }),
      },
    }),
    !!orgsEntityIds?.length
  );

  function mapMembershipsById(memberships: ListMembersResponse_Membership[] | undefined) {
    if (!memberships) return {};

    return memberships.reduce((result, membership) => {
      const key = membership?.entity?.id?.value as string;
      result[key] = membership.members.map((member) => member.profile.value as Profile);
      return result;
    }, {} as Record<string, Profile[]>);
  }

  const listMembers = useMemo(
    () => ({
      ...mapMembershipsById(squadMembers?.memberships),
      ...mapMembershipsById(orgMembers?.memberships),
    }),
    [squadMembers, orgMembers]
  );

  const handleToggle = () => {
    setShowFirstButton(!showFirstButton);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const newContractorId = event.target.value as string;
    if (userContractorrId !== newContractorId) {
      setContractors([
        new MatchedContracts({
          profileId: new UUID({ value: newContractorId }),
          contractId: new UUID({ value: contractId }),
        }),
        ...(contractors || []),
      ]);
      setUserContractorId(newContractorId);
    }
  };

  return currentContractorId ? (
    <Box className="h-[56px]">
      {showFirstButton ? (
        <Button onClick={handleToggle} variant="contained">
          <Typography variant="subtitle2">Choose contractor</Typography>
        </Button>
      ) : (
        <Box display="flex" gap={1} alignItems="center">
          <Select value={userContractorrId} className="min-w-[300px]" onChange={handleChange}>
            {listMembers[currentContractorId.id.value]?.map((profile) => (
              <MenuItem value={profile.profileId?.value} key={profile.profileId?.value}>
                {profile?.name?.nickName}
              </MenuItem>
            ))}
          </Select>
          <HighlightOffIcon onClick={handleToggle} />
        </Box>
      )}
    </Box>
  ) : null;
};

export default ContractorSelect;
