import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import React, { Suspense } from 'react';
import MfaDialog from '@pages/ProfileSettings/MfaDialog/SendOTPDialog';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import RecoveryCodeInfoDialog from '@pages/ProfileSettings/MfaDialog/RecoveryCodeInfoDialog';
import { useMfaState } from '../../../zustand/mfa';

const MfaButton = () => {
  const { setShowMfaDialog } = useMfaState();

  return (
    <Box className="mb-2">
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowMfaDialog(true)}
        className="flex gap-1 items-center"
      >
        <HealthAndSafetyIcon />
        Enable MFA
      </Button>

      <Suspense fallback={null}>
        <RecoveryCodeInfoDialog />
      </Suspense>

      <Suspense fallback={null}>
        <MfaDialog />
      </Suspense>
    </Box>
  );
};

export default MfaButton;
