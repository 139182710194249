import React from 'react';
import { useDialog } from '@components/hooks/useDialog';
import CreateDashboardDialog from '@pages/Marketplace/Dashboards/CreateDashboardDialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Entity } from '@proto/grpc/type/v1/entity_pb';

type CreateDemandProps = {
  entity?: Entity;
};
const CreateDashboardButton = (props: CreateDemandProps) => {
  const { entity } = props;
  const { isOpen, handleOpen, handleClose } = useDialog();

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Box onClick={handleOpen} display="flex" gap={1}>
        <DashboardIcon />
        <Typography fontSize="13px">Create new dashboard</Typography>
      </Box>

      <CreateDashboardDialog handleClose={handleClose} isOpen={isOpen} entity={entity} />
    </Box>
  );
};

export default CreateDashboardButton;
