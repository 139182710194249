import qs from 'qs';
import GoogleLogo from '@assets/images/icons/google.svg';
import GithubLogo from '@assets/images/icons/github.svg';

export enum OAuthAppTypes {
  google = 'google',
  github = 'github',
}

export const oAuthAppTypeQueryParam = 'oauth_app';

const getRedirectUri = (type: OAuthAppTypes): string => {
  const redirectUriParams = qs.stringify({ [oAuthAppTypeQueryParam]: type });
  return `${window.location.href}?${redirectUriParams}`;
};

export const getButtonText = (type: OAuthAppTypes): string => {
  switch (type) {
    case OAuthAppTypes.google: {
      return 'Continue with Google';
    }
    case OAuthAppTypes.github: {
      return 'Continue with GitHub';
    }
    default: {
      throw Error('Unknown OAuth app type');
    }
  }
};

export const getButtonLogo = (type: OAuthAppTypes): string => {
  switch (type) {
    case OAuthAppTypes.google: {
      return GoogleLogo;
    }
    case OAuthAppTypes.github: {
      return GithubLogo;
    }
    default: {
      throw Error('Unknown OAuth app type');
    }
  }
};
