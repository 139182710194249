import Box from '@mui/material/Box';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

type DemandTraitsProps = {
  demand: any;
  fontSize?: string;

  matches?: any;
  flexDirection?: 'row' | 'column';
};

const DemandTraits = (props: DemandTraitsProps) => {
  const { demand, fontSize, matches, flexDirection } = props;
  console.log('DemandTraits', demand);
  return (
    <Box
      display="flex"
      flexDirection={flexDirection || 'column'}
      gap={flexDirection ? 2 : undefined}
    >
      {demand?.traits?.experience && (
        <Tooltip placement="left" title="years">
          <Box
            display="flex"
            gap={0.5}
            alignItems="center"
            color={matches?.experience > 0 ? '#7986cb' : 'white'}
          >
            <InfoIcon sx={{ width: '14px', height: '14px' }} />
            <Typography fontSize={fontSize}>Experience: {demand.traits.experience}+ </Typography>
          </Box>
        </Tooltip>
      )}
      {demand?.traits?.capacity && (
        <Tooltip placement="left" title="hours per week">
          <Box
            display="flex"
            gap={0.5}
            alignItems="center"
            color={matches?.capacity > 0 ? '#7986cb' : 'white'}
          >
            <InfoIcon sx={{ width: '14px', height: '14px' }} />
            <Typography fontSize={fontSize}>Capacity: {demand.traits.capacity} </Typography>
          </Box>
        </Tooltip>
      )}
      {demand?.traits?.rate && (
        <Tooltip placement="left" title="per hours">
          <Box
            display="flex"
            gap={0.5}
            alignItems="center"
            color={matches?.rate > 0 ? '#7986cb' : 'white'}
          >
            <InfoIcon sx={{ width: '14px', height: '14px' }} />
            <Typography fontSize={fontSize}>Rate: ${demand.traits.rate}</Typography>
          </Box>
        </Tooltip>
      )}
      {demand?.traits?.price && (
        <Tooltip placement="left" title="per hours">
          <Box
            display="flex"
            gap={0.5}
            alignItems="center"
            color={matches?.price > 0 ? '#6FF9BF' : 'white'}
          >
            <InfoIcon sx={{ width: '14px', height: '14px' }} />
            <Typography fontSize={fontSize}>Price: ${demand.traits.price} </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
export default DemandTraits;
