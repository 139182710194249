import { Form } from '@components/Form';
import { CreateOrgServiceRequest, CreateSquadServiceRequest } from '@services/api/profiler/biz';
import { PropsWithChildren } from 'react';
import { CreateSquadRequest } from '@proto/profiler/biz/v1/squad_pb';
import { CreateOrgRequest } from '@proto/profiler/biz/v1/org_pb';
import { getDefaultValues } from './config';

export type TCreateGroupingFormPayload =
  | CreateSquadServiceRequest
  | CreateOrgServiceRequest
  | CreateSquadRequest
  | CreateOrgRequest;

interface ICreateGroupingFormProps extends PropsWithChildren {
  onSubmit: (data: TCreateGroupingFormPayload) => void;
  resolverSchema?: any;
}

export const CreateGroupingForm = ({
  onSubmit,
  resolverSchema,
  children,
}: ICreateGroupingFormProps) => {
  return (
    <Form<TCreateGroupingFormPayload>
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues() }}
      submitHandler={onSubmit}
      resolverSchema={resolverSchema}
    >
      {children}
    </Form>
  );
};
