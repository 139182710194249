import { useCallback, useState } from 'react';

const confirmModal = () => {
  // Припустимо, що це псевдокод для відкриття модального вікна з підтвердженням
  return new Promise((resolve, reject) => {
    // Ваша логіка для відображення модального вікна та обробки вибору користувача
    // Наприклад:
    if (window.confirm('Ви впевнені, що хочете продовжити?')) {
      resolve(true);
    } else {
      reject();
    }
  });
};

export const useDialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, handleOpen, handleClose };
};
