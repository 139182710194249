// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file profiler/tags/v1/tags.proto (package profiler.tags.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Entity, Entity_Type } from '../../../grpc/type/v1/entity_pb.js';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from enum profiler.tags.v1.TagType
 */
export enum TagType {
  /**
   * @generated from enum value: TAG_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TAG_TYPE_LANGUAGE = 1;
   */
  LANGUAGE = 1,

  /**
   * @generated from enum value: TAG_TYPE_ROLE = 2;
   */
  ROLE = 2,

  /**
   * @generated from enum value: TAG_TYPE_INDUSTRY_DOMAIN = 3;
   */
  INDUSTRY_DOMAIN = 3,

  /**
   * @generated from enum value: TAG_TYPE_PROGRAMMING_LANGUAGE = 4;
   */
  PROGRAMMING_LANGUAGE = 4,

  /**
   * @generated from enum value: TAG_TYPE_COUNTRY = 5;
   */
  COUNTRY = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(TagType)
proto3.util.setEnumType(TagType, 'profiler.tags.v1.TagType', [
  { no: 0, name: 'TAG_TYPE_UNSPECIFIED' },
  { no: 1, name: 'TAG_TYPE_LANGUAGE' },
  { no: 2, name: 'TAG_TYPE_ROLE' },
  { no: 3, name: 'TAG_TYPE_INDUSTRY_DOMAIN' },
  { no: 4, name: 'TAG_TYPE_PROGRAMMING_LANGUAGE' },
  { no: 5, name: 'TAG_TYPE_COUNTRY' },
]);

/**
 * @generated from message profiler.tags.v1.ObjectTags
 */
export class ObjectTags extends Message<ObjectTags> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 2;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<ObjectTags>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.ObjectTags';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    { no: 2, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ObjectTags {
    return new ObjectTags().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ObjectTags {
    return new ObjectTags().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ObjectTags {
    return new ObjectTags().fromJsonString(jsonString, options);
  }

  static equals(
    a: ObjectTags | PlainMessage<ObjectTags> | undefined,
    b: ObjectTags | PlainMessage<ObjectTags> | undefined
  ): boolean {
    return proto3.util.equals(ObjectTags, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.ListObjectsTagsRequest
 */
export class ListObjectsTagsRequest extends Message<ListObjectsTagsRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS object_ids = 1;
   */
  objectIds?: UUIDS;

  /**
   * @generated from field: repeated int32 tag_ids = 2;
   */
  tagIds: number[] = [];

  /**
   * @generated from field: repeated bool statuses = 3;
   */
  statuses: boolean[] = [];

  constructor(data?: PartialMessage<ListObjectsTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.ListObjectsTagsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'object_ids', kind: 'message', T: UUIDS },
    { no: 2, name: 'tag_ids', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: 'statuses', kind: 'scalar', T: 8 /* ScalarType.BOOL */, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListObjectsTagsRequest {
    return new ListObjectsTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListObjectsTagsRequest {
    return new ListObjectsTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListObjectsTagsRequest {
    return new ListObjectsTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListObjectsTagsRequest | PlainMessage<ListObjectsTagsRequest> | undefined,
    b: ListObjectsTagsRequest | PlainMessage<ListObjectsTagsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListObjectsTagsRequest, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.ListObjectsTagsResponse
 */
export class ListObjectsTagsResponse extends Message<ListObjectsTagsResponse> {
  /**
   * @generated from field: repeated profiler.tags.v1.ObjectTags tags = 1;
   */
  tags: ObjectTags[] = [];

  constructor(data?: PartialMessage<ListObjectsTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.ListObjectsTagsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tags', kind: 'message', T: ObjectTags, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListObjectsTagsResponse {
    return new ListObjectsTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListObjectsTagsResponse {
    return new ListObjectsTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListObjectsTagsResponse {
    return new ListObjectsTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListObjectsTagsResponse | PlainMessage<ListObjectsTagsResponse> | undefined,
    b: ListObjectsTagsResponse | PlainMessage<ListObjectsTagsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListObjectsTagsResponse, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.Tag
 */
export class Tag extends Message<Tag> {
  /**
   * @generated from field: int32 tag_id = 1;
   */
  tagId = 0;

  /**
   * @generated from field: string value = 2;
   */
  value = '';

  /**
   * @generated from field: optional string alias = 3;
   */
  alias?: string;

  /**
   * @generated from field: profiler.tags.v1.TagType tag_type = 4;
   */
  tagType = TagType.UNSPECIFIED;

  /**
   * @generated from field: bool active = 5;
   */
  active = false;

  constructor(data?: PartialMessage<Tag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.Tag';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tag_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'alias', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: 'tag_type', kind: 'enum', T: proto3.getEnumType(TagType) },
    { no: 5, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tag {
    return new Tag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tag {
    return new Tag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tag {
    return new Tag().fromJsonString(jsonString, options);
  }

  static equals(
    a: Tag | PlainMessage<Tag> | undefined,
    b: Tag | PlainMessage<Tag> | undefined
  ): boolean {
    return proto3.util.equals(Tag, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.Tags
 */
export class Tags extends Message<Tags> {
  /**
   * @generated from field: repeated profiler.tags.v1.Tag tags = 1;
   */
  tags: Tag[] = [];

  constructor(data?: PartialMessage<Tags>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.Tags';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tags', kind: 'message', T: Tag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tags {
    return new Tags().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tags {
    return new Tags().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tags {
    return new Tags().fromJsonString(jsonString, options);
  }

  static equals(
    a: Tags | PlainMessage<Tags> | undefined,
    b: Tags | PlainMessage<Tags> | undefined
  ): boolean {
    return proto3.util.equals(Tags, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.AddTagsRequest
 */
export class AddTagsRequest extends Message<AddTagsRequest> {
  /**
   * @generated from field: profiler.tags.v1.ObjectTags tags = 1;
   */
  tags?: ObjectTags;

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  constructor(data?: PartialMessage<AddTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.AddTagsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tags', kind: 'message', T: ObjectTags },
    { no: 2, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddTagsRequest | PlainMessage<AddTagsRequest> | undefined,
    b: AddTagsRequest | PlainMessage<AddTagsRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddTagsRequest, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.AddTagsResponse
 */
export class AddTagsResponse extends Message<AddTagsResponse> {
  constructor(data?: PartialMessage<AddTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.AddTagsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTagsResponse {
    return new AddTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTagsResponse {
    return new AddTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTagsResponse {
    return new AddTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddTagsResponse | PlainMessage<AddTagsResponse> | undefined,
    b: AddTagsResponse | PlainMessage<AddTagsResponse> | undefined
  ): boolean {
    return proto3.util.equals(AddTagsResponse, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.RemoveTagsRequest
 */
export class RemoveTagsRequest extends Message<RemoveTagsRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from field: repeated int32 tag_ids = 2;
   */
  tagIds: number[] = [];

  constructor(data?: PartialMessage<RemoveTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.RemoveTagsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    { no: 2, name: 'tag_ids', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveTagsRequest | PlainMessage<RemoveTagsRequest> | undefined,
    b: RemoveTagsRequest | PlainMessage<RemoveTagsRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveTagsRequest, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.RemoveTagsResponse
 */
export class RemoveTagsResponse extends Message<RemoveTagsResponse> {
  constructor(data?: PartialMessage<RemoveTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.RemoveTagsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveTagsResponse {
    return new RemoveTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveTagsResponse {
    return new RemoveTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveTagsResponse {
    return new RemoveTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveTagsResponse | PlainMessage<RemoveTagsResponse> | undefined,
    b: RemoveTagsResponse | PlainMessage<RemoveTagsResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveTagsResponse, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.SetObjectTagsStatusRequest
 */
export class SetObjectTagsStatusRequest extends Message<SetObjectTagsStatusRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID object_id = 1;
   */
  objectId?: UUID;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 2;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<SetObjectTagsStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.SetObjectTagsStatusRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'object_id', kind: 'message', T: UUID },
    { no: 2, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetObjectTagsStatusRequest {
    return new SetObjectTagsStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetObjectTagsStatusRequest {
    return new SetObjectTagsStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetObjectTagsStatusRequest {
    return new SetObjectTagsStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetObjectTagsStatusRequest | PlainMessage<SetObjectTagsStatusRequest> | undefined,
    b: SetObjectTagsStatusRequest | PlainMessage<SetObjectTagsStatusRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetObjectTagsStatusRequest, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.SetObjectTagsStatusResponse
 */
export class SetObjectTagsStatusResponse extends Message<SetObjectTagsStatusResponse> {
  constructor(data?: PartialMessage<SetObjectTagsStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.SetObjectTagsStatusResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetObjectTagsStatusResponse {
    return new SetObjectTagsStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetObjectTagsStatusResponse {
    return new SetObjectTagsStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetObjectTagsStatusResponse {
    return new SetObjectTagsStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetObjectTagsStatusResponse | PlainMessage<SetObjectTagsStatusResponse> | undefined,
    b: SetObjectTagsStatusResponse | PlainMessage<SetObjectTagsStatusResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetObjectTagsStatusResponse, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.ObjectTagsStatus
 */
export class ObjectTagsStatus extends Message<ObjectTagsStatus> {
  /**
   * @generated from field: string object_id = 1;
   */
  objectId = '';

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  constructor(data?: PartialMessage<ObjectTagsStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.ObjectTagsStatus';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'object_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ObjectTagsStatus {
    return new ObjectTagsStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ObjectTagsStatus {
    return new ObjectTagsStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ObjectTagsStatus {
    return new ObjectTagsStatus().fromJsonString(jsonString, options);
  }

  static equals(
    a: ObjectTagsStatus | PlainMessage<ObjectTagsStatus> | undefined,
    b: ObjectTagsStatus | PlainMessage<ObjectTagsStatus> | undefined
  ): boolean {
    return proto3.util.equals(ObjectTagsStatus, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.SetObjectsTagsStatusRequest
 */
export class SetObjectsTagsStatusRequest extends Message<SetObjectsTagsStatusRequest> {
  /**
   * @generated from field: repeated profiler.tags.v1.ObjectTagsStatus objects_tags_status = 1;
   */
  objectsTagsStatus: ObjectTagsStatus[] = [];

  constructor(data?: PartialMessage<SetObjectsTagsStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.SetObjectsTagsStatusRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'objects_tags_status', kind: 'message', T: ObjectTagsStatus, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetObjectsTagsStatusRequest {
    return new SetObjectsTagsStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetObjectsTagsStatusRequest {
    return new SetObjectsTagsStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetObjectsTagsStatusRequest {
    return new SetObjectsTagsStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetObjectsTagsStatusRequest | PlainMessage<SetObjectsTagsStatusRequest> | undefined,
    b: SetObjectsTagsStatusRequest | PlainMessage<SetObjectsTagsStatusRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetObjectsTagsStatusRequest, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.SetObjectsTagsStatusResponse
 */
export class SetObjectsTagsStatusResponse extends Message<SetObjectsTagsStatusResponse> {
  constructor(data?: PartialMessage<SetObjectsTagsStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.SetObjectsTagsStatusResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetObjectsTagsStatusResponse {
    return new SetObjectsTagsStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetObjectsTagsStatusResponse {
    return new SetObjectsTagsStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetObjectsTagsStatusResponse {
    return new SetObjectsTagsStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetObjectsTagsStatusResponse | PlainMessage<SetObjectsTagsStatusResponse> | undefined,
    b: SetObjectsTagsStatusResponse | PlainMessage<SetObjectsTagsStatusResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetObjectsTagsStatusResponse, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.GetTagRequest
 */
export class GetTagRequest extends Message<GetTagRequest> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: profiler.tags.v1.TagType tag_type = 2;
   */
  tagType = TagType.UNSPECIFIED;

  constructor(data?: PartialMessage<GetTagRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.GetTagRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'tag_type', kind: 'enum', T: proto3.getEnumType(TagType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagRequest {
    return new GetTagRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagRequest {
    return new GetTagRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagRequest {
    return new GetTagRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetTagRequest | PlainMessage<GetTagRequest> | undefined,
    b: GetTagRequest | PlainMessage<GetTagRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetTagRequest, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.GetTagResponse
 */
export class GetTagResponse extends Message<GetTagResponse> {
  /**
   * @generated from field: profiler.tags.v1.Tag tag = 1;
   */
  tag?: Tag;

  constructor(data?: PartialMessage<GetTagResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.GetTagResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tag', kind: 'message', T: Tag },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagResponse {
    return new GetTagResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagResponse {
    return new GetTagResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagResponse {
    return new GetTagResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetTagResponse | PlainMessage<GetTagResponse> | undefined,
    b: GetTagResponse | PlainMessage<GetTagResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetTagResponse, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.ListTagsRequest
 */
export class ListTagsRequest extends Message<ListTagsRequest> {
  /**
   * @generated from field: profiler.tags.v1.TagType tag_type = 1;
   */
  tagType = TagType.UNSPECIFIED;

  constructor(data?: PartialMessage<ListTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.ListTagsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tag_type', kind: 'enum', T: proto3.getEnumType(TagType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTagsRequest {
    return new ListTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTagsRequest {
    return new ListTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTagsRequest {
    return new ListTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListTagsRequest | PlainMessage<ListTagsRequest> | undefined,
    b: ListTagsRequest | PlainMessage<ListTagsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListTagsRequest, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.ListTagsResponse
 */
export class ListTagsResponse extends Message<ListTagsResponse> {
  /**
   * @generated from field: profiler.tags.v1.Tags tags = 1;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<ListTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.ListTagsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTagsResponse {
    return new ListTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTagsResponse {
    return new ListTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTagsResponse {
    return new ListTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListTagsResponse | PlainMessage<ListTagsResponse> | undefined,
    b: ListTagsResponse | PlainMessage<ListTagsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListTagsResponse, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.MatchedTags
 */
export class MatchedTags extends Message<MatchedTags> {
  /**
   * @generated from field: profiler.tags.v1.TagType tag_type = 1;
   */
  tagType = TagType.UNSPECIFIED;

  /**
   * @generated from field: repeated int32 tag_ids = 2;
   */
  tagIds: number[] = [];

  constructor(data?: PartialMessage<MatchedTags>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.MatchedTags';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tag_type', kind: 'enum', T: proto3.getEnumType(TagType) },
    { no: 2, name: 'tag_ids', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MatchedTags {
    return new MatchedTags().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MatchedTags {
    return new MatchedTags().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MatchedTags {
    return new MatchedTags().fromJsonString(jsonString, options);
  }

  static equals(
    a: MatchedTags | PlainMessage<MatchedTags> | undefined,
    b: MatchedTags | PlainMessage<MatchedTags> | undefined
  ): boolean {
    return proto3.util.equals(MatchedTags, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.SearchEntitiesRequest
 */
export class SearchEntitiesRequest extends Message<SearchEntitiesRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity.Type toe = 1;
   */
  toe = Entity_Type.UNSPECIFIED;

  /**
   * @generated from field: repeated profiler.tags.v1.MatchedTags tags = 2;
   */
  tags: MatchedTags[] = [];

  constructor(data?: PartialMessage<SearchEntitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.SearchEntitiesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'toe', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
    { no: 2, name: 'tags', kind: 'message', T: MatchedTags, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchEntitiesRequest {
    return new SearchEntitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchEntitiesRequest {
    return new SearchEntitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchEntitiesRequest {
    return new SearchEntitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchEntitiesRequest | PlainMessage<SearchEntitiesRequest> | undefined,
    b: SearchEntitiesRequest | PlainMessage<SearchEntitiesRequest> | undefined
  ): boolean {
    return proto3.util.equals(SearchEntitiesRequest, a, b);
  }
}

/**
 * @generated from message profiler.tags.v1.SearchEntitiesResponse
 */
export class SearchEntitiesResponse extends Message<SearchEntitiesResponse> {
  /**
   * @generated from field: repeated profiler.tags.v1.ObjectTags tags = 1;
   */
  tags: ObjectTags[] = [];

  constructor(data?: PartialMessage<SearchEntitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.tags.v1.SearchEntitiesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tags', kind: 'message', T: ObjectTags, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchEntitiesResponse {
    return new SearchEntitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SearchEntitiesResponse {
    return new SearchEntitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchEntitiesResponse {
    return new SearchEntitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchEntitiesResponse | PlainMessage<SearchEntitiesResponse> | undefined,
    b: SearchEntitiesResponse | PlainMessage<SearchEntitiesResponse> | undefined
  ): boolean {
    return proto3.util.equals(SearchEntitiesResponse, a, b);
  }
}
