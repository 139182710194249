import Stack from '@mui/material/Stack';
import { InputController } from '@components/Form/controllers/InputController';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from '@components/Form';
import Joi from 'joi';
import * as React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import Typography from '@mui/material/Typography';
import { CreateDashboardRequest } from '@proto/marketplace/dashboard/v1/dashboard_pb';
import { useCreateDashboard, useListDashboards } from '@services/queries/MarketplaceQueries';
import Divider from '@mui/material/Divider';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';

export enum FieldNames {
  TITLE = 'title',
  ORG = 'orgId',
  OWNER = 'owner',
}

export const createDemandResolverSchema = {
  [FieldNames.TITLE]: Joi.string().required().min(5).max(50).messages({
    'string.empty': 'fill title',
    'string.min': 'title must be at least 5 characters long',
    'string.max': 'first name must be less than or equal to 50 characters long',
  }),
  [FieldNames.ORG]: Joi.string(),
  [FieldNames.OWNER]: Joi.object().allow(null),
};

type CreateDashboardFormProps = {
  handleCloseModal: () => void;
  owner?: Entity;
};

const CreateDashboardForm = (props: CreateDashboardFormProps) => {
  const { handleCloseModal, owner } = props;
  const { data } = useListDashboards();
  const { mutate, error } = useCreateDashboard();
  const [chosenOwner, setChosenOwner] = React.useState('');
  const { data: userProfile } = useGetMyProfile();

  const userIsOwner =
    Array.isArray(data?.dashboards?.dashboards) &&
    data?.dashboards?.dashboards.some(
      (dashboard) => dashboard?.owner?.id === userProfile?.profile?.profileId
    );

  const handleChange = (event: SelectChangeEvent) => {
    setChosenOwner(event.target.value);
  };

  return userIsOwner ? (
    <Box textAlign="center">
      <Typography fontWeight="bold">
        You already have one kanban board, you can create the next ones from the organization
      </Typography>
    </Box>
  ) : (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      resolverSchema={createDemandResolverSchema}
      formProps={{
        defaultValues: {
          title: '',
          owner,
        },
      }}
      submitHandler={(_data) => {
        mutate(
          new CreateDashboardRequest({
            title: _data.title,
            owner: owner || JSON.parse(chosenOwner),
          })
        );
        handleCloseModal();
      }}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        {!owner && (
          <FormControl fullWidth>
            <InputLabel>Specify the owner of this dashboard</InputLabel>
            <Select
              value={chosenOwner}
              label="Specify the owner of this dashboard."
              onChange={handleChange}
            >
              {!userIsOwner && (
                <MenuItem
                  value={JSON.stringify({
                    id: userProfile?.profile?.profileId,
                    type: Entity_Type.USER,
                  })}
                >
                  {userProfile?.profile?.name?.nickName}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        <Stack spacing={2} direction="column">
          <InputController
            name={FieldNames.TITLE}
            label="Provide a brief description to easily identify your dashboard and its purpose"
          />
        </Stack>
        <Divider />

        <Box display="flex" justifyContent="flex-end">
          <LoadingButton type="submit" variant="outlined" color="success">
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Form>
  );
};

export default CreateDashboardForm;
