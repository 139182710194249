import React, { SyntheticEvent } from 'react';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useGetMyProfile, usePinEntity, useUnpinEntity } from '@services/queries/ProfilesQueries';

interface IPinUnpinButtonProps {
  id: string;
  type: Entity_Type;
}

const PinUnpinButton: React.FC<IPinUnpinButtonProps> = ({ id, type }) => {
  const { mutate: pinEntity } = usePinEntity();
  const { mutate: unpinEntity } = useUnpinEntity();
  const { data: userProfile } = useGetMyProfile();

  const isPinned = userProfile?.profile?.pinnedItems?.some(
    (entity) => entity?.entity?.id?.value === id
  );

  const handleChange = (e: SyntheticEvent) => {
    const action = isPinned ? unpinEntity : pinEntity;
    action({ entityId: id, entityType: type });
  };

  return (
    <Stack>
      <Tooltip title={isPinned ? 'Unpin' : 'Pin'} placement="left">
        <Checkbox
          checked={isPinned}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Tooltip>
    </Stack>
  );
};

export default PinUnpinButton;
