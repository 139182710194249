import { TextFieldProps } from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useController, useFormContext } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';

type TWysiwygControllerProps = Omit<TextFieldProps, 'name'> & {
  name: string;
};

const WysiwygController = (props: TWysiwygControllerProps) => {
  const { name } = props;
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  const [editorState, setEditorState] = useState(() =>
    value
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
      : EditorState.createEmpty()
  );

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    onChange(JSON.stringify(convertToRaw(contentState)));
  }, [editorState, onChange]);

  return (
    <Editor
      toolbar={{
        options: ['inline', 'fontSize', 'list'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
        blockType: {
          options: ['Normal', 'Blockquote', 'Code'],
        },
        list: {
          options: ['unordered', 'ordered'],
        },
      }}
      editorState={editorState}
      onEditorStateChange={setEditorState}
    />
  );
};
export default WysiwygController;
