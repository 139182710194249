import { combineReducers } from '@reduxjs/toolkit';
import { marketplaceSlice } from '@store/marketplace/slices';
import { useCallback } from 'react';
import { MatchedContracts } from '@proto/marketplace/demand/v1/contractor_pb';
import { ObjectParam, useQueryParam } from 'use-query-params';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { useAppDispatch, useAppSelector } from '../helpers';

const { setDisplayListType, setCurrentContractorId, setContractors } = marketplaceSlice.actions;

export const marketplaceReducer = combineReducers({
  marketplace: marketplaceSlice.reducer,
});

export const useMarketplace = (): {
  contractors: MatchedContracts[] | undefined;
  displayListType: string;
  demandIdFromUrl: { [key: string]: string | undefined } | null | undefined;
  preAppliesContracts: string[];
  currentContractorId: { id: UUID; case: 'orgId' | 'squadId' } | undefined;
  setDisplayListType: (payload: string) => void;
  setCurrentContractorId: (payload: { id: string; case: 'org' | 'squad' }) => void;
  setContractors: (payload: MatchedContracts[]) => void;
  setDemandId: (value: { [key: string]: string | undefined } | null | undefined) => void;
} => {
  const [demandIdFromUrl, setDemandId] = useQueryParam('demandId', ObjectParam);
  const dispatch = useAppDispatch();

  return {
    contractors: useAppSelector((state) => state.marketplaceReducer.marketplace.contractors),

    demandIdFromUrl,
    displayListType: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.displayListType
    ),
    currentContractorId: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.currentContractorId
    ),
    preAppliesContracts: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.preAppliesContracts
    ),

    setContractors: useCallback(
      (payload) => {
        dispatch(setContractors(payload));
      },
      [dispatch]
    ),

    setDisplayListType: useCallback(
      (payload) => {
        dispatch(setDisplayListType(payload));
      },
      [dispatch]
    ),

    setCurrentContractorId: useCallback(
      (payload) => {
        dispatch(setCurrentContractorId(payload));
      },
      [dispatch]
    ),

    setDemandId: useCallback(
      (payload) => {
        setDemandId(payload);
      },
      [setDemandId]
    ),
  };
};

export {};
