import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { UserInvite } from '@proto/profiler/invite/v1/invite_pb';
import { PlainMsg } from '@services/api/helpers';

export type TUserInvite = PlainMsg<UserInvite>;

interface InviteMap {
  [key: string]: TUserInvite;
}

export const getTagType = {
  countries: TagType.COUNTRY,
  languages: TagType.LANGUAGE,
  industryDomain: TagType.INDUSTRY_DOMAIN,
  roles: TagType.ROLE,
  programmingLanguages: TagType.PROGRAMMING_LANGUAGE,
};

export const transformSearchProfiles = (profiles: any) => {
  if (profiles === undefined) return [];

  const data = Object.entries(profiles).map((el, i) => {
    return {
      id: i + 1,
      objectId: el[0],
      // @ts-ignore
      name: el[1].nickName,
    };
  });
  return data;
};

export const transformInvitesArrayToMap = (invites: TUserInvite[]): InviteMap => {
  return invites.reduce((acc: InviteMap, invite: TUserInvite) => {
    if (invite.entity && invite.entity.id) {
      acc[invite.entity.id.value] = invite;
    }
    return acc;
  }, {});
};
