export enum IAMRoleStrings {
  IAM_ROLE_UNSPECIFIED = 'IAM_ROLE_UNSPECIFIED',
  IAM_ROLE_ADMIN = 'IAM_ROLE_ADMIN',
  IAM_ROLE_MANAGER = 'IAM_ROLE_MANAGER',
  IAM_ROLE_MEMBER = 'IAM_ROLE_MEMBER',
  IAM_ROLE_BILLING_MANAGER = 'IAM_ROLE_BILLING_MANAGER',
  IAM_ROLE_PEOPLE_MANAGER = 'IAM_ROLE_PEOPLE_MANAGER',
}

export function hasCommonRoles(
  requiredRoles: IAMRoleStrings[],
  userRoles: IAMRoleStrings[] | undefined
): boolean {
  if (userRoles === undefined) return false;

  return requiredRoles.some((role) => userRoles.includes(role));
}
