import { useCallback } from 'react';
import { nanoid } from 'nanoid';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TRootState, useAppDispatch, useAppSelector } from '@store/helpers';
import { registerSlice } from '@store/index';

type Alert = {
  type: 'error' | 'info' | 'warning' | 'success';
  title: string;
  id: string;
  description: string;
};

type AlertsState = Alert[];

const alertsSliceName = 'alerts';

type ShowAlertAction = PayloadAction<Pick<Alert, 'type' | 'title' | 'description'>>;
type HideAlertAction = PayloadAction<Pick<Alert, 'id'>>;

type AlertsCaseReducers = {
  showAlert: (state: AlertsState, action: ShowAlertAction) => void;
  hideAlert: (state: AlertsState, action: HideAlertAction) => void;
};

const alertsSlice = createSlice<AlertsState, AlertsCaseReducers>({
  name: alertsSliceName,
  initialState: [],
  reducers: {
    showAlert: (state, action) => {
      const { type, title, description } = action.payload;

      state.push({ type, title, description, id: nanoid() });
    },
    hideAlert: (state, action) => {
      const { id } = action.payload;

      return state.filter((alert) => alert.id !== id);
    },
  },
});
registerSlice(alertsSlice);

export const { showAlert, hideAlert } = alertsSlice.actions;

export const useAlert = (): {
  alerts: AlertsState;

  showAlert: (data: Alert) => void;
  hideAlert: (id: string) => void;
} => {
  const dispatch = useAppDispatch();

  return {
    alerts: useAppSelector((state: TRootState) => state.alerts),
    showAlert: useCallback((data: Alert) => dispatch(showAlert(data)), [dispatch]),
    hideAlert: useCallback((id: string) => dispatch(hideAlert({ id })), [dispatch]),
  };
};
