import { Evaluation } from '@proto/marketplace/demand/v1/evaluation_pb';

export function sortEvaluationsByDate(items: Evaluation[] | undefined) {
  return items?.sort((a, b) => {
    const dateA = new Date(a?.createdAt?.toDate() as Date);
    const dateB = new Date(b?.createdAt?.toDate() as Date);

    return dateB.getTime() - dateA.getTime();
  });
}

export const listTypes = [
  { name: 'all', label: 'All' },
  { name: 'notScheduled', label: 'Not Scheduled' },
  { name: 'scheduled', label: 'Scheduled' },
  { name: 'completed', label: 'Completed' },
];
