import {
  Conversation,
  ConversationHeader,
  ConversationList,
  MainContainer,
  Search,
  Sidebar,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/themes/default/main.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Timestamp } from '@bufbuild/protobuf';
import { format } from 'date-fns';
import CustomAvatar from '../Avatar/Avatar';
import { UUID, UUIDS } from '../../proto/grpc/type/v1/uuid_pb';
import { ChatGroupsMap } from '../../zustand/subscribe';
import { ChatMember } from '../../proto/notificator/stream/v1/chat_pb';
import { useGetMyProfile, useListProfiles } from '../../services/queries/ProfilesQueries';
import { Profile } from '../../proto/profiler/user/v1/profile_pb';
import { useGetMessagesHistory, useSendMessage, useVisitGroup } from '../../services/queries/Chat';
import Message from './Message';
import SendMessageInput from './SendMessageInput';

function getUniqueProfileIds(chatGroupsMap: ChatGroupsMap): Set<string> {
  const uniqueProfileIds = new Set<string>();

  Object.values(chatGroupsMap).forEach((chatGroup) => {
    chatGroup.group.members.forEach((member: ChatMember) => {
      if (member.profileId && member.profileId.value) {
        uniqueProfileIds.add(member.profileId.value);
      }
    });
  });

  return uniqueProfileIds;
}

const Chat = (props: { groups: ChatGroupsMap }) => {
  const { groups } = props;
  const groupKeys = Object.keys(groups);
  const { mutate: sendMessage } = useSendMessage();
  const { mutate: visitGroup } = useVisitGroup();
  const { mutate: getHistoryMessage } = useGetMessagesHistory();
  const { data: userProfile } = useGetMyProfile();
  const [sessionToken, setSessionToken] = useState(groups[groupKeys[0]]?.group.sessionId);
  const [groupId, setGroupId] = useState(groupKeys[0] as string);

  const { data: listProfiles } = useListProfiles(
    new UUIDS({
      values: [...getUniqueProfileIds(groups)],
    })
  );
  const messages = groups[groupId]?.messages;

  // Ref for the last message element
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  const handleSendMessage = (message: string) => {
    sendMessage({
      text: message,
      groupId: new UUID({ value: groupId }),
      token: sessionToken,
    });
  };

  useEffect(() => {
    if (groupId && sessionToken) {
      getHistoryMessage({
        groupId: new UUID({ value: groupId }),
        token: sessionToken,
        offset: Timestamp.now(),
      });
    }
  }, [groupId, sessionToken, getHistoryMessage]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (lastMessageRef.current) {
      const lastMessageElement = lastMessageRef.current;
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (messages && messages.length > 0) {
                getHistoryMessage({
                  groupId: new UUID({ value: groupId }),
                  token: sessionToken,
                  offset: messages[messages.length - 1]?.created as Timestamp,
                });
              }
            }
          });
        },
        { threshold: 1.0 }
      );

      observer.observe(lastMessageElement);

      return () => {
        observer.unobserve(lastMessageElement);
      };
    }
  }, [messages, getHistoryMessage, groupId, sessionToken]);

  return (
    <div
      className="flex"
      style={{
        position: 'relative',
        height: '800px',
        width: '100vw',
        maxWidth: '1320px',
        minWidth: '80%',
      }}
    >
      <MainContainer
        responsive
        style={{
          backgroundColor: 'inherit',
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: '5px',
          width: '100%',
        }}
      >
        <Sidebar position="left" style={{ backgroundColor: 'inherit' }}>
          <Search placeholder="Search..." />
          <ConversationList>
            {Object.values(groups).map((chat) => {
              return (
                <Conversation
                  name={groups[chat.group.groupId?.value as string]?.group.members
                    ?.map(
                      (member: ChatMember) =>
                        listProfiles && listProfiles[member.profileId?.value as string]
                    )
                    .filter(
                      (profile: Profile | undefined) =>
                        profile?.profileId?.value !== userProfile?.profile?.profileId?.value
                    )
                    ?.map((profile: Profile | undefined) => profile?.name?.nickName || '')
                    .join(', ')}
                  style={{
                    backgroundColor: chat.group.groupId?.value === groupId ? '#00838f' : 'inherit',
                  }}
                  onClick={() => {
                    setSessionToken(chat.group.sessionId);
                    setGroupId(chat.group.groupId?.value as string);
                    visitGroup(chat.group.groupId as UUID);
                  }}
                >
                  <CustomAvatar alt="alt" profileId="123" avatarId="123" />
                </Conversation>
              );
            })}
          </ConversationList>
        </Sidebar>
        <Box className="relative flex flex-col justify-end w-full ">
          <ConversationHeader className="absolute p-0 z-50 top-0 w-full">
            <ConversationHeader.Content
              className="bg-[#495358] p-4"
              userName={groups[groupId]?.group.members
                ?.map(
                  (member: ChatMember) =>
                    listProfiles && listProfiles[member.profileId?.value as string]
                )
                .filter(
                  (profile: Profile | undefined) =>
                    profile?.profileId?.value !== userProfile?.profile?.profileId?.value
                )
                ?.map((profile: Profile | undefined) => profile?.name?.nickName || '')
                .join(', ')}
            />
          </ConversationHeader>

          <Box
            sx={{
              height: 'auto',
              overflow: 'auto',
              width: '100%',
            }}
            className="flex flex-col-reverse pt-16 px-2"
          >
            {listProfiles &&
              messages &&
              messages.map((message, index) => {
                const messageDate = format(message?.created?.toDate() as Date, 'yyyy-MM-dd');
                const previousMessageDate =
                  index < messages.length - 1
                    ? format(messages[index + 1]?.created?.toDate() as Date, 'yyyy-MM-dd')
                    : null;

                return (
                  <React.Fragment key={message.messageId?.value as string}>
                    {messageDate !== previousMessageDate && (
                      <Box className="text-center text-gray-500 my-2">
                        {format(message?.created?.toDate() as Date, 'MMMM d, yyyy')}
                      </Box>
                    )}

                    <Box
                      ref={index === messages.length - 1 ? lastMessageRef : null}
                      className="relative flex items-center gap-1"
                    >
                      <Message
                        message={message}
                        listProfiles={listProfiles}
                        groupId={groupId}
                        sessionToken={sessionToken}
                      />
                    </Box>
                  </React.Fragment>
                );
              })}
          </Box>
          <SendMessageInput onSendMessage={handleSendMessage} />
        </Box>
      </MainContainer>
    </div>
  );
};

export default Chat;
