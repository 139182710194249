import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useController, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';

export type TAutocompleteItem = {
  tagId: number;
  tagType: string;
  objectId?: string;
  value: string;
};

type TAutocompleteControllerProps = Omit<TextFieldProps, 'name'> & {
  name: string;
  label: string;
  multiple?: boolean;
  defaultValues?: TAutocompleteItem[];
  options: TAutocompleteItem[];
  changeHandler?: (control: any) => void;
  onInputChange?: (e: any, data: any, reason: any) => void;
  getItems: () => void;
};

const AutocompleteController = (props: TAutocompleteControllerProps): JSX.Element => {
  const { name, label, defaultValues, options, getItems, onInputChange, multiple, changeHandler } =
    props;
  const { control } = useFormContext();
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ control, name });

  const handleOpen = () => {
    if (options.length === 0) {
      getItems();
    }
  };

  return (
    <Autocomplete
      multiple={multiple}
      onOpen={handleOpen}
      options={options}
      onInputChange={() => onInputChange}
      getOptionLabel={(option) => option.value}
      value={defaultValues}
      onChange={(e, data) => {
        return changeHandler !== undefined
          ? (() => {
              onChange(data);
              changeHandler(data);
            })()
          : onChange(data);
      }}
      isOptionEqualToValue={(option, _value) => option.tagId === _value.tagId}
      renderInput={(params) => (
        <TextField error={!!error} helperText={error?.message} {...params} label={label} />
      )}
    />
  );
};
export default AutocompleteController;
