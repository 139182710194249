import Joi from 'joi';

export enum FieldNames {
  NAME = 'name',
  ABOUT = 'about',
  COUNTRIES = 'countries',
  LANGUAGES = 'languages',
  INDUSTRY_DOMAIN = 'industryDomain',
}

export const getDefaultValues = () => ({
  [FieldNames.NAME]: '',
  [FieldNames.ABOUT]: '',
  [FieldNames.COUNTRIES]: [],
  [FieldNames.LANGUAGES]: [],
  [FieldNames.INDUSTRY_DOMAIN]: [],
});

export const createOrgResolverSchema = {
  [FieldNames.NAME]: Joi.string().required().min(2).max(30).messages({
    'string.empty': 'enter the name of the organization',
    'string.min': 'organization name must be at least 2 characters long',
    'string.max': 'organization name must be less than or equal to 30 characters long',
  }),
  [FieldNames.ABOUT]: Joi.string().required().min(30).max(250).messages({
    'string.empty': 'enter your organization description',
    'string.min': 'organization description must be at least 30 characters long',
    'string.max': 'organization description must be less than or equal to 250 characters long',
  }),
  [FieldNames.COUNTRIES]: Joi.array().min(1).required().messages({
    'array.min': 'add at least 1 country',
  }),
  [FieldNames.LANGUAGES]: Joi.array().min(1).required().messages({
    'array.min': 'add at least 1 language',
  }),
  [FieldNames.INDUSTRY_DOMAIN]: Joi.array().min(1).required().messages({
    'array.min': 'add at least 1 industry domain',
  }),
};
export const createSquadResolverSchema = {
  [FieldNames.NAME]: Joi.string().required().min(2).max(30).messages({
    'string.empty': 'enter the name of the squad',
    'string.min': 'squad name must be at least 2 characters long',
    'string.max': 'squad name must be less than or equal to 30 characters long',
  }),
  [FieldNames.ABOUT]: Joi.string().required().min(30).max(2500).messages({
    'string.empty': 'enter your squad description',
    'string.min': 'squad description must be at least 30 characters long',
    'string.max': 'squad description must be less than or equal to 250 characters long',
  }),
  [FieldNames.COUNTRIES]: Joi.array().allow(null),
  [FieldNames.LANGUAGES]: Joi.array().allow(null),
  [FieldNames.INDUSTRY_DOMAIN]: Joi.array().allow(null),
};
