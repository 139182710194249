import { SupplyList, SupplyResponse } from '@proto/marketplace/demand/v1/supply_pb';
import Box from '@mui/material/Box';
import { useListProfiles } from '@services/queries/ProfilesQueries';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import UserSupplierCard from '@pages/Marketplace/Dashboards/UserSupplierCard';

const ListSuppliers = ({ supply, demandType }: { supply: SupplyResponse; demandType: string }) => {
  const { data: profiles, error } = useListProfiles(
    new UUIDS({
      values: [supply.view.value as SupplyList][0].list.map((sup) => `${sup.supplier?.id?.value}`),
    })
  );

  return (
    <Box>
      {profiles &&
        [supply.view.value as SupplyList][0].list.map((sup) => {
          return (
            <Box>
              <UserSupplierCard
                demandType={demandType}
                supply={sup}
                supplier={profiles[sup?.supplier?.id?.value as string]}
              />
            </Box>
          );
        })}
    </Box>
  );
};
export default ListSuppliers;
