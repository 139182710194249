import React from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { hideSnackbar, useSnackbar } from '@store/snackbars';
import Box from '@mui/material/Box';

const SnackbarManager = () => {
  const dispatch = useDispatch();
  const { snackbars } = useSnackbar();

  const handleClose = (id: string) => {
    dispatch(hideSnackbar({ id }));
  };

  return (
    <Box>
      {snackbars?.map((snackbar) => (
        <Snackbar
          key={snackbar?.id}
          open={snackbar?.visible}
          autoHideDuration={6000}
          onClose={() => handleClose(snackbar?.id)}
        >
          <Alert
            onClose={() => handleClose(snackbar.id)}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      ))}
    </Box>
  );
};

export default SnackbarManager;
