import { create } from 'zustand/react';

interface MfaState {
  recoveryCode: string | null;
  addRecoveryCode: (recoveryCode: string | null) => void;

  showMfaDialog: boolean;
  setShowMfaDialog: (showMfaDialog: boolean) => void;
  inputError: boolean;

  setMfaInputError: (inputError: boolean) => void;
}

export const useMfaState = create<MfaState>((set, get) => ({
  recoveryCode: null,
  showMfaDialog: false,
  inputError: false,
  addRecoveryCode: (recoveryCode: string | null) => set({ recoveryCode }),
  setShowMfaDialog: (showMfaDialog: boolean) => set({ showMfaDialog }),
  setMfaInputError: (inputError: boolean) => set({ inputError }),
}));
