import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Slice } from '@reduxjs/toolkit/src/createSlice';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers';
import { store } from './index';

export type TAppDispatch = typeof store.dispatch;
export type TRootState = ReturnType<typeof store.getState>;

export const useAppDispatch = (): TAppDispatch => useDispatch<TAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;

type TSuccessCbRes<ST, RT> = {
  response: ST;
  payload: RT;
  dispatch: TAppDispatch;
  state: TRootState;
};
type TErrorCbRes<RT> = {
  errorMessage: string;
  payload: RT;
  dispatch: TAppDispatch;
  state: TRootState;
};
export type TCreateServiceCall = <ST, RT = void>(
  sliceName: string,
  serviceCallCb: (arg: RT) => Promise<ST>,
  onSuccessCb?: (res: TSuccessCbRes<ST, RT>) => void,
  onErrorCb?: (res: TErrorCbRes<RT>) => void
) => AsyncThunk<ST, RT, any>;

export const createServiceCall: TCreateServiceCall = (
  sliceName,
  serviceCallCb,
  onSuccessCb,
  onErrorCb
) => {
  const typePrefix = `${sliceName}/${serviceCallCb.name}`;

  return createAsyncThunk(typePrefix, async (payload, thunkAPI) => {
    // eslint-disable-next-line no-console
    console.log(`Thunk ${typePrefix} started with payload: `, payload);
    try {
      const response = await serviceCallCb(payload);
      // eslint-disable-next-line no-console
      console.log(`Thunk ${typePrefix} success: `, response);
      if (onSuccessCb) {
        onSuccessCb({
          payload,
          response,
          dispatch: thunkAPI.dispatch as TAppDispatch,
          state: thunkAPI.getState() as TRootState,
        });
      }

      return response;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : String(err);
      // eslint-disable-next-line no-console
      console.log(`Thunk ${typePrefix} error: `, err);
      if (onErrorCb) {
        onErrorCb({
          payload,
          errorMessage,
          dispatch: thunkAPI.dispatch as TAppDispatch,
          state: thunkAPI.getState() as TRootState,
        });
      }

      return thunkAPI.rejectWithValue({ error: errorMessage });
    }
  });
};

export interface IDefaultState {
  isLoading: boolean;
  error: string | null;
}

export const initialDefaultState: IDefaultState = {
  error: null,
  isLoading: false,
};

type TCreateServiceSlice = <State, CaseReducers>(
  arg: {
    name: string;
    initialState: State;
    reducers: CaseReducers;
    extraReducersCb?: (builder: ActionReducerMapBuilder<NoInfer<State>>) => void;
  }
  // @ts-ignore
) => Slice<State & IDefaultState, CaseReducers>;

// @ts-ignore
export const createServiceSlice: TCreateServiceSlice = ({
  name,
  initialState,
  reducers,
  extraReducersCb,
}) => {
  return createSlice({
    name,
    initialState: {
      ...initialState,
      ...initialDefaultState,
    },
    // @ts-ignore
    reducers,
    extraReducers: (builder) => {
      if (extraReducersCb) extraReducersCb(builder);

      builder.addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.isLoading = true;
        }
      );
      builder.addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          const { error } = action.payload;

          state.error = error;
          state.isLoading = false;
        }
      );
      builder.addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        (state) => {
          state.isLoading = false;
        }
      );
    },
  });
};

export type CustomTag = {
  id: number;
  name: string;
};
