// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file api/iam/v1/membership.proto (package api.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  LeaveMembershipRequest,
  LeaveMembershipResponse,
  ListMembersRequest,
  ListMembersResponse,
  ListProfilesMembershipsRequest,
  ListProfilesMembershipsResponse,
  RemoveMemberRequest,
  RemoveMemberResponse,
} from '../../../iam/membership/v1/membership_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.iam.v1.MembershipAPI
 */
export const MembershipAPI = {
  typeName: 'api.iam.v1.MembershipAPI',
  methods: {
    /**
     * @generated from rpc api.iam.v1.MembershipAPI.ListProfilesMemberships
     */
    listProfilesMemberships: {
      name: 'ListProfilesMemberships',
      I: ListProfilesMembershipsRequest,
      O: ListProfilesMembershipsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.MembershipAPI.ListMembers
     */
    listMembers: {
      name: 'ListMembers',
      I: ListMembersRequest,
      O: ListMembersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.MembershipAPI.RemoveMember
     */
    removeMember: {
      name: 'RemoveMember',
      I: RemoveMemberRequest,
      O: RemoveMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.MembershipAPI.LeaveMembership
     */
    leaveMembership: {
      name: 'LeaveMembership',
      I: LeaveMembershipRequest,
      O: LeaveMembershipResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
