import { getConnectClient } from '@services/api/helpers';
import { useMutation } from '@tanstack/react-query';
import { PreSignRequest, PreSignResponse } from '@proto/iam/auth/v1/auth_pb';
import qs from 'qs';
import { AuthAPI } from '@proto/api/iam/v1/auth_connect';

const authClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_AUTH_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AuthAPI
);

export const usePreSignIn = () => {
  const oAuthAppTypeQueryParam = 'oauth_app';

  const getRedirectUri = (): string => {
    const redirectUriParams = qs.stringify({ [oAuthAppTypeQueryParam]: 'google' });
    return `${window.location.href}?${redirectUriParams}`;
  };

  const getOAuthCodeRequestUrl = (state: string): string => {
    const redirectUri = getRedirectUri();
    const params = qs.stringify({
      scope: 'https://www.googleapis.com/auth/userinfo.email',
      response_type: 'code',
      redirect_uri: redirectUri,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      state,
    });
    return `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
  };

  const getOrg = async (): Promise<PreSignResponse> => {
    return authClient.preSign(new PreSignRequest());
  };

  return useMutation({
    mutationFn: getOrg,
    onSuccess: (response) => {
      window.open(getOAuthCodeRequestUrl(response.state), '_self');
    },
  });
};
