import { Chip, Tooltip, Typography } from '@mui/material';
import React, { Suspense } from 'react';
import Box from '@mui/material/Box';
import { MemberProps } from '@components/MembesrList/config';
import ManageMemberDialog from '@components/MembesrList/ManageMemberDialog';
import { useDialog } from '@components/hooks/useDialog';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Card from '@components/Card/Card';
import { TTag } from '@services/api/profiler/tags';
import { Link } from 'react-router-dom';
import CustomAvatar from '@components/Avatar/Avatar';

export const ManageMember = (props: MemberProps) => {
  const { member, entityId, currentEntity, rwx } = props;
  const { isOpen, handleOpen, handleClose } = useDialog();

  return currentEntity ? (
    <Card>
      <Box display="flex" justifyContent="space-between" position="relative">
        <Link className="NavLink" to={`/user/${member?.profileId.value}`}>
          <Box display="flex" gap={1} position="relative" sx={{ cursor: 'pointer' }}>
            <CustomAvatar
              alt={member?.name?.nickName || 'User'}
              avatarId={member?.avatar}
              profileId={member?.profileId?.value}
            />

            <Box display="flex" flexDirection="column">
              <Typography fontWeight="bold" fontSize="13px">
                {member?.name?.firstName} {member?.name?.lastName}
              </Typography>
              <Typography color="#bdbdbd" lineHeight="13px" fontSize="13px" mb={1}>
                @{member?.name?.nickName}
              </Typography>
              <Box display="flex" gap={2} mb={1}>
                {member?.traits?.experience && (
                  <Tooltip placement="right" title="years">
                    <Typography fontSize="13px">Experience: {member.traits.experience}+</Typography>
                  </Tooltip>
                )}
                {member?.traits?.capacity && (
                  <Tooltip placement="right" title="hours per week">
                    <Typography fontSize="13px">Capacity: {member.traits.capacity} </Typography>
                  </Tooltip>
                )}
                {member?.traits?.rate && (
                  <Typography fontSize="13px">Rate: ${member.traits.rate}</Typography>
                )}
              </Box>
              <Box display="flex" gap={1} flexWrap="wrap">
                {member?.tags?.tags?.map((tag: TTag) => {
                  return (
                    <Chip
                      sx={{ fontSize: '12px' }}
                      key={tag.value}
                      label={tag.value}
                      variant="outlined"
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Link>
        <Box>
          {rwx?.w && <SettingsApplicationsIcon sx={{ cursor: 'pointer' }} onClick={handleOpen} />}
        </Box>
      </Box>
      <Suspense fallback={null}>
        {entityId && (
          <ManageMemberDialog
            entityId={entityId}
            isOpen={isOpen}
            handleClose={handleClose}
            member={member}
          />
        )}
      </Suspense>
    </Card>
  ) : null;
};
