import { useController, useFormContext } from 'react-hook-form';

import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';

type TCheckboxControllerProps = Omit<CheckboxProps, 'name'> & {
  name: string;
  label: string;
  labelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
};

export const CheckboxController = (props: TCheckboxControllerProps): JSX.Element => {
  const { name, label, labelProps, ...checkboxProps } = props;

  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return (
    <FormControlLabel
      {...labelProps}
      label={label}
      control={<Checkbox onChange={onChange} checked={value} {...checkboxProps} />}
    />
  );
};
