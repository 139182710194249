import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const fingerprint = async () => {
  const fpPromise = FingerprintJS.load();

  try {
    // Отримуємо fp об'єкт, якщо він ще не був отриманий раніше.
    const fp = await fpPromise;

    // Отримуємо visitorId.
    const result = await fp.get();

    // Повертаємо visitorId.
    return {
      visitorId: result.visitorId,
      os: result.components.platform.value,
    };
  } catch (error) {
    // Якщо сталася помилка, повертаємо порожній рядок або можемо обробити її за потреби.
    return '';
  }
};
