import Avatar from '@mui/material/Avatar';

const CustomAvatar = (props: {
  alt: string;
  profileId: string | undefined;
  avatarId: string | undefined;
  width?: number;
  height?: number;
}) => {
  const { profileId, avatarId, alt, height, width } = props;

  return (
    <Avatar
      sx={{
        width: width || 50,
        height: height || 50,
        backgroundColor: 'rgb(255, 255, 255, 0.2)',
        color: 'white',
      }}
      alt={alt.toUpperCase()}
      src={avatarId ? `${process.env.REACT_APP_AVATARS_ENDPOINT}/${profileId}/${avatarId}` : '/'}
    />
  );
};

export default CustomAvatar;
