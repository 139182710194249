import { ROUTES } from '@common/routes';
import Box from '@mui/material/Box';
import ListOrgsForm from '@pages/Orgs/ListOrgsForm';
import OrgsAutocomplete from '@pages/Orgs/OrgsAutocomplete';
import React from 'react';
import { useLocation } from 'react-router-dom';

const OrgsSideBarContent = () => {
  const location = useLocation();

  return location.pathname === ROUTES.ORGS_LIST ? (
    <Box>
      <Box>
        <ListOrgsForm />
        <OrgsAutocomplete />
      </Box>
    </Box>
  ) : null;
};

export default OrgsSideBarContent;
