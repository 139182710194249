import { create } from 'zustand/react';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';

interface FeedState {
  currentEntityType: Entity_Type;
  isPinnedReturn: boolean;
  setCurrentEntityType: (type: Entity_Type) => void;
  setIsPinnedReturn: (isPinnedReturn: boolean) => void;
}

export const useFeed = create<FeedState>((set, get) => ({
  currentEntityType: Entity_Type.UNSPECIFIED,
  isPinnedReturn: false,
  setCurrentEntityType: (type: Entity_Type) => set({ currentEntityType: type }),
  setIsPinnedReturn: (isPinnedReturn: boolean) => set({ isPinnedReturn }),
}));
