import { createServiceSlice } from '@store/helpers';
import { TagsCaseReducer, TagsState } from '@store/tags/tags';

export const tagsSlice = createServiceSlice<TagsState, TagsCaseReducer>({
  name: 'tags',
  initialState: {
    countries: [],
    industryDomains: [],
    languages: [],
    roles: [],
    programmingLanguages: [],
  },
  reducers: {
    setCountries(state, action) {
      const { tags } = action.payload;

      state.countries = tags.tags;
    },
    setProgrammingLanguage(state, action) {
      const { tags } = action.payload;

      state.programmingLanguages = tags.tags;
    },

    setRoles(state, action) {
      const { tags } = action.payload;

      state.roles = tags.tags;
    },

    setLanguage(state, action) {
      const { tags } = action.payload;

      state.languages = tags.tags;
    },

    setIndustryDomains(state, action) {
      const { tags } = action.payload;

      state.industryDomains = tags.tags;
    },
  },
});
