import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { SortedEvaluations } from '@services/queries/MarketplaceQueries';
import { Chip, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES_PREFIXES } from '@common/routes';
import { Tag, TagType } from '@proto/profiler/tags/v1/tags_pb';
import ScrollableContainer from '@components/ScrollableContainerІ';
import { Evaluation } from '@proto/marketplace/demand/v1/evaluation_pb';
import { listTypes, sortEvaluationsByDate } from '@pages/Main/MainPage/Evaluations/config';

const SortedEvaluationsList = (props: {
  evaluations: SortedEvaluations;
  tags?: { [key: string]: Tag[] };
}) => {
  const { evaluations, tags } = props;

  const [displayListType, setDisplayListType] = useState<string>('all');
  const notScheduled = Object.values(evaluations?.notScheduled as Evaluation[]);
  const scheduled = Object.values(evaluations?.scheduled as Evaluation[]);
  const completed = Object.values(evaluations?.completed as Evaluation[]);

  const combinedList = [...notScheduled, ...scheduled, ...completed];

  let displayList;

  if (displayListType === 'all') displayList = sortEvaluationsByDate(combinedList);
  if (displayListType === 'notScheduled') displayList = sortEvaluationsByDate(notScheduled);
  if (displayListType === 'scheduled') displayList = sortEvaluationsByDate(scheduled);
  if (displayListType === 'completed') displayList = sortEvaluationsByDate(completed);

  return (
    <Fade in timeout={500}>
      <Box flexDirection="column" display="flex" gap={1} position="relative" px={1}>
        <Box display="flex" gap={2}>
          {listTypes.map((type) => {
            return (
              <Chip
                onClick={() => setDisplayListType(type.name)}
                sx={{ cursor: 'pointer' }}
                variant={displayListType === type.name ? 'filled' : 'outlined'}
                label={type.label}
              />
            );
          })}
        </Box>

        <ScrollableContainer maxHeight="75vh">
          <List>
            {displayList && displayList.length
              ? displayList?.map((ev, index) => {
                  return (
                    <Box>
                      <Link
                        className="NavLink"
                        to={`${ROUTES_PREFIXES.EVALUATION}/${ev.evaluationId?.value}`}
                      >
                        <ListItemButton>
                          <Box>
                            <Box display="flex" gap={2}>
                              <Box display="flex" alignItems="center">
                                <Typography fontSize="13px" fontWeight="bold">
                                  Interview
                                </Typography>
                              </Box>

                              {tags &&
                                tags[ev.interviewId?.value as string]
                                  .filter((tag) => tag.tagType === TagType.ROLE)
                                  .map((tag) => {
                                    return <Chip variant="outlined" label={tag.value} />;
                                  })}
                            </Box>
                            <ListItemText primary={`Evaluation № ${index + 1}`} />
                          </Box>
                        </ListItemButton>
                      </Link>
                    </Box>
                  );
                })
              : 'Empty list'}
          </List>
        </ScrollableContainer>
      </Box>
    </Fade>
  );
};

export default SortedEvaluationsList;
