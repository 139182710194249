import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { AuthForm } from '@components/AuthForm';

const StyledBr = styled('br')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

// @ts-ignore
export const JoinSection = ({ customRef }: { customRef?: any }) => {
  return (
    <Stack
      component="section"
      justifyContent="center"
      alignItems="center"
      minHeight={{ md: '100vh', xs: 'auto' }}
      mb={{ xs: 10, md: 0 }}
      ref={customRef}
      sx={{ px: { xl: '10.75rem', lg: '8.125rem', xs: '1.14rem' } }}
    >
      <Typography mb={{ xl: 11, lg: 8, xs: 7 }} px={{ lg: 0, xs: 5 }} align="center" variant="h2">
        We want you <StyledBr /> for Squady
      </Typography>
      <AuthForm />
    </Stack>
  );
};
