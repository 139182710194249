import { useTags } from '@store/tags';
import { useOrgs } from '@store/orgs';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { transformFiltersFromStringToAutocomplete } from '@pages/Orgs/ManageOrgs/config';
import { useEffect } from 'react';

const OrgsAutocomplete = () => {
  const { getIndustryDomains, industryDomains } = useTags();
  const { setOrgsFilters, queryParams } = useOrgs();
  useEffect(() => {
    getIndustryDomains();
  }, [getIndustryDomains]);
  return (
    <Box>
      <Autocomplete
        multiple
        options={industryDomains}
        getOptionLabel={(option) => option.value}
        value={transformFiltersFromStringToAutocomplete(queryParams.orgsFilters)}
        onChange={(e, data) => {
          setOrgsFilters(data.map((item) => `${item.value}:${item.tagId}`));
        }}
        isOptionEqualToValue={(option, _value) => option.tagId === _value.tagId}
        renderInput={(params) => <TextField {...params} label="Industry domain" />}
      />
    </Box>
  );
};
export default OrgsAutocomplete;
