import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';
import MobileDrawer from '@pages/Main/MobileDrawer';
import NotificationPopover from '@components/NotificationPopover/NotificationPopover';
import UserProfileDrawer from '@pages/Main/ProfilePopover';
import { useLocation } from 'react-router-dom';
import CreateEntityMenu from '@pages/Main/CreateEntityMenu';
import React, { useEffect } from 'react';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import OpenChatButton from '@components/Chat/OpenChatButton';
import { NavBar } from '@components/Header/NavBar';
import { Logo } from '../Logo';
import { useStreamStore } from '../../zustand/subscribe';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Header = (): JSX.Element => {
  const location = useLocation();
  const { subscribe, cancelStream } = useStreamStore();

  const getLinkStyle = (paths: string[]) => {
    return paths.some((path) => location.pathname === path)
      ? { color: '#0087ca' }
      : { color: 'white' };
  };

  useEffect(() => {
    subscribe();

    return () => {
      cancelStream();
    };
  }, [subscribe, cancelStream]);

  return (
    <Box>
      <AppBar sx={{ width: '100%', display: { xs: 'block', md: 'none' } }} position="fixed" open>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <MobileDrawer />
          <Box display="flex" alignItems="center" gap={2}>
            <CreateEntityMenu />
            <NotificationPopover />
            <UserProfileDrawer />
          </Box>
        </Toolbar>
      </AppBar>

      <AppBar
        className=" bg-inherit"
        sx={{ display: { xs: 'none', md: 'block' } }}
        position="fixed"
        open
      >
        <Toolbar className="bg-inherit py-4">
          <Box display="flex" flexGrow={1} alignItems="center" gap={5}>
            <Logo />
            <Box display="flex" className="items-center" gap={2}>
              {/* <NavLink */}
              {/*    className="NavLink " */}
              {/*    to={ROUTES.HOME} */}
              {/*    style={() => getLinkStyle([ROUTES.HOME])} */}
              {/* > */}
              {/*    <Typography fontWeight="bold" sx={{transition: 'all 0.3s linear'}}> */}
              {/*        Main */}
              {/*    </Typography> */}
              {/* </NavLink> */}
              {/* <NavLink */}
              {/*    className="NavLink " */}
              {/*    to={ROUTES.FEED_LIST} */}
              {/*    style={() => getLinkStyle([ROUTES.FEED_LIST])} */}
              {/* > */}
              {/*    <Typography fontWeight="bold" sx={{transition: 'all 0.3s linear'}}> */}
              {/*        Feed */}
              {/*    </Typography> */}
              {/* </NavLink> */}
              {/* <NavLink */}
              {/*    className="NavLink " */}
              {/*    to={ROUTES.ORGS_LIST} */}
              {/*    style={() => getLinkStyle([ROUTES.ORGS_LIST, ROUTES.EXPLORE_ORGS])} */}
              {/* > */}
              {/*    <Typography fontWeight="bold" sx={{transition: 'all 0.3s linear'}}> */}
              {/*        Organizations */}
              {/*    </Typography> */}
              {/* </NavLink> */}
              {/* <NavLink */}
              {/*    className="NavLink " */}
              {/*    to={ROUTES.SQUADS_LIST} */}
              {/*    style={() => getLinkStyle([ROUTES.SQUADS_LIST, ROUTES.EXPLORE_SQUADS])} */}
              {/* > */}
              {/*    <Typography fontWeight="bold" sx={{transition: 'all 0.3s linear'}}> */}
              {/*        Squads */}
              {/*    </Typography> */}
              {/* </NavLink> */}
              {/* <NavLink */}
              {/*    className="NavLink " */}
              {/*    to={ROUTES.MARKETPLACE} */}
              {/*    style={() => getLinkStyle([ROUTES.MARKETPLACE])} */}
              {/* > */}
              {/*    <Typography fontWeight="bold" sx={{transition: 'all 0.3s linear'}}> */}
              {/*        Marketplace */}
              {/*    </Typography> */}
              {/* </NavLink> */}
              <NavBar />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <OpenChatButton />
            <CreateEntityMenu />
            <NotificationPopover />
            <UserProfileDrawer />

            {/* <Button onClick={() => { */}
            {/*    notificatorService.cancelStream(); */}
            {/* }}>sas</Button> */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
