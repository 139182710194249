import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useController, useFormContext } from 'react-hook-form';

type TInputControllerProps = Omit<TextFieldProps, 'name'> & {
  name: string;
  type?: string;
  rows?: number;
  multiline?: boolean;
};

export const InputController = (props: TInputControllerProps): JSX.Element => {
  const { name, type, rows, multiline, ...restInputProps } = props;
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <TextField
      error={!!error}
      InputProps={{
        inputProps: {
          onWheel: (event) => {
            const target = event.target as HTMLInputElement;
            target.blur();
          },
        },
      }}
      helperText={error?.message}
      onChange={(data) => {
        const inputValue = data.target.value;
        if (type === 'number') {
          onChange(inputValue === '' ? '' : Number(inputValue));
          return;
        }
        onChange(inputValue);
      }}
      value={value}
      type={type}
      multiline={multiline}
      rows={rows}
      {...restInputProps}
    />
  );
};
