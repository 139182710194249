import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CookieService } from '@services/cookie';

export const useCookie = (): [
  isCookiesAllowed: boolean,
  setIsCookiesAllowed: Dispatch<SetStateAction<boolean>>
] => {
  const [isCookiesAllowed, setIsCookiesAllowed] = useState(() => {
    // Використовуємо функцію-ініціалізатор для отримання значення з localStorage
    const storedValue = CookieService.getCookie();
    return storedValue || false;
  });

  useEffect(() => {
    setIsCookiesAllowed(CookieService.getCookie());
  }, []);

  useEffect(() => {
    CookieService.setCookie(isCookiesAllowed);
  }, [isCookiesAllowed]);

  return [isCookiesAllowed, setIsCookiesAllowed];
};
