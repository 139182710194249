import { Box, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { FieldNames } from '@pages/Marketplace/ManageDemands/settings';
import { Form } from '@components/Form';
import * as React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';
import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { useUpdateInterviewRequirements } from '@services/queries/MarketplaceQueries';

type UpdateRequirementsProps = {
  demand: Demand;
};

const UpdateInterviewRequirements = (props: UpdateRequirementsProps) => {
  const { demand } = props;
  const [isEdited, setIsEdited] = useState(true);
  const requirements = (demand?.entity?.value as Interview)?.requirements;
  const { mutate: updateInterviewRequirements } = useUpdateInterviewRequirements();
  const handleSubmit = (data: any) => {
    if (isEdited) {
      setIsEdited(!isEdited);
    }
    setIsEdited(!isEdited);

    if (requirements === data.requirements) return;

    updateInterviewRequirements({
      interviewId: demand?.entity.value?.interviewId?.value as string,
      requirements: data.requirements,
    });
  };

  return demand ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: {
          interviewId: demand?.entity?.value?.interviewId,
          requirements,
        },
      }}
      submitHandler={handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Requirements</Typography>
            <Box display="flex" flexDirection="column">
              <Tooltip placement="left" title="Update requirements">
                <LoadingButton type="submit">
                  {isEdited ? <EditIcon /> : <CheckIcon />}
                </LoadingButton>
              </Tooltip>
            </Box>
          </Box>

          {isEdited ? (
            <Box display="flex" flexDirection="column">
              {requirements &&
                HTMLReactParser(stateToHTML(convertFromRaw(JSON.parse(requirements))))}
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" width="100%">
              <Typography>Description:</Typography>
              <WysiwygController name={`${FieldNames.REQUIREMENTS}`} />
            </Box>
          )}
        </Box>
      </Box>
    </Form>
  ) : null;
};

export default UpdateInterviewRequirements;
