import { Policy, RWX } from '@proto/iam/auth/v1/iam_pb';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';

export function findRWXByEntityType(
  policies: Policy[] | undefined,
  entityType: Entity_Type
): RWX | undefined {
  if (!policies) return undefined;

  const policy = policies.find((item) => item.toe === entityType);
  return policy?.rwx;
}
