import { useProfiler } from '@store/profiler';
import { Stack, Typography } from '@mui/material';
import UserInviteCard from '@components/Invites/UserInviteCard';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';

const UserInvitesList = () => {
  const { listUserInvites, getlistUserInvites } = useProfiler();

  useEffect(() => {
    getlistUserInvites();
  }, [getlistUserInvites]);

  return Object.values(listUserInvites)?.length !== 0 ? (
    <Box>
      <Fade in timeout={500}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={1}>
              <GroupAddIcon />
              <Typography variant="subtitle1">You have new invite</Typography>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="center" flexDirection="column" gap={1}>
            {Object.values(listUserInvites)?.map((invite) => {
              return <UserInviteCard key={invite?.inviteId?.value} invite={invite} />;
            })}
          </Box>
          <Divider />
        </Box>
      </Fade>
    </Box>
  ) : null;
};
export default UserInvitesList;
