import Box from '@mui/material/Box';
import React from 'react';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TUserInvite } from '@store/profiler/helpers';
import { ROUTES_PREFIXES } from '@common/routes';
import { Link } from 'react-router-dom';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import EntityInviteCard from '@components/EntityInviteCard/EntityInviteCard';

type TUserInviteCard = {
  invite: TUserInvite;
};

const TypeByLinks = {
  TYPE_INTERVIEW: `${ROUTES_PREFIXES.INTERWIEW}`,
  TYPE_SQUAD: `${ROUTES_PREFIXES.SQUADS}`,
  TYPE_ORG: `${ROUTES_PREFIXES.ORGS}`,
};

const UserInviteCard = (props: TUserInviteCard) => {
  const { invite } = props;

  return (
    <Paper elevation={0}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link
            className="NavLink HoverUnderlineAnimation"
            to={`${TypeByLinks[invite.entity?.type?.toString() as keyof typeof TypeByLinks]}/${
              invite.entity?.id?.value
            }`}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography textAlign="center" variant="subtitle2">
                Click to view
              </Typography>
              <AddReactionIcon />
            </Box>
          </Link>
          <EntityInviteCard entityId={invite?.entity?.id?.value} entity={invite.entity} />
        </Box>
      </Box>
    </Paper>
  );
};

export default UserInviteCard;
