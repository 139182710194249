// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file grpc/type/v1/uuid.proto (package grpc.type.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message grpc.type.v1.UUIDS
 */
export class UUIDS extends Message<UUIDS> {
  /**
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<UUIDS>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.UUIDS';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'values', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UUIDS {
    return new UUIDS().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UUIDS {
    return new UUIDS().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UUIDS {
    return new UUIDS().fromJsonString(jsonString, options);
  }

  static equals(
    a: UUIDS | PlainMessage<UUIDS> | undefined,
    b: UUIDS | PlainMessage<UUIDS> | undefined
  ): boolean {
    return proto3.util.equals(UUIDS, a, b);
  }
}

/**
 * @generated from message grpc.type.v1.UUID
 */
export class UUID extends Message<UUID> {
  /**
   * @generated from field: string value = 1;
   */
  value = '';

  constructor(data?: PartialMessage<UUID>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.UUID';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UUID {
    return new UUID().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UUID {
    return new UUID().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UUID {
    return new UUID().fromJsonString(jsonString, options);
  }

  static equals(
    a: UUID | PlainMessage<UUID> | undefined,
    b: UUID | PlainMessage<UUID> | undefined
  ): boolean {
    return proto3.util.equals(UUID, a, b);
  }
}
