import Joi from 'joi';

export enum FieldNames {
  EMAIL = 'email',
  PASSCODE = 'passCode',
}

export const FIELD_LABELS = {
  [FieldNames.EMAIL]: 'Enter email',
  [FieldNames.PASSCODE]: 'Enter passcode',
};

export const getDefaultValuesGenerateOtp = () => ({
  [FieldNames.EMAIL]: '',
});
export const getDefaultValuesValidateOtp = () => ({
  [FieldNames.PASSCODE]: '',
});
export const myOtpGenerateFormSchema = {
  [FieldNames.EMAIL]: Joi.string().required(),
};
export const myOtpValidateFormSchema = {
  [FieldNames.PASSCODE]: Joi.string().required(),
};
